<script>
  import './styles.scss';
  import { Router, Link, Route } from 'svelte-routing';
  import { NavBar, PageNotFound, Redirect, Carousel, Banner, Gtag } from './components';
  import { layoutOption, queryStr, mode } from './store/inapp-store' 
  import { getDevice } from './store/openpoint' 
  import { Home, OPFunctionTest, Information, Mirror } from './home';
  import { StarbucksHome, StarbucksReturn, StarbucksBarcode, StarbucksMirror, StarbucksLight, StarbucksCamera, StarbucksDemo } from './starbucks';
  import { Store, StoreEmap, StoreFullMap } from './location';
  import { RegisterInfo, Register, OPAuthorize, Result, OPOnlinePayment, OPCheckWallet } from './register';
  import { Redeem, RedeemViaPOS, RedeemViaScanner, Cancel, CancelResult, MMKBarcode, RedeemScanPincode, RedeemScanCup, RedeemScanSuccess  } from './redeem';
  import { Return, ReturnViaMachine, ReturnViaPOS, ReturnViaStarbucksPOS, ReturnViaScanner, ReturnScanCup, ReturnScanPincode, ReturnScanSuccess, ReturnBarcode } from './return';
  import { RefundBarcode } from './refund';
  import { Order } from './order';

  export let url = '';

  // $:console.log('href', location.href)
  
  let isHome = location.href.indexOf('home') > 0 
  let isStarbucksApp = location.href.indexOf('starbucks') > 0

  var device = getDevice()

  if(device === 'Android') {
    // 檢查 Android 是否回上一頁（或下一頁）
    if (!!window.performance && window.performance.navigation.type === 2) {
          console.log('Reloading');
          redirectBack()
    }
  } else {
    // 禁止返回前一頁
    window.history.forward(1);
  }

  function redirectHome() {
    location.href = `/home${$queryStr}`
  }

  function redirectBack() {
    if($layoutOption.navBackUrl === '') { 
      if($mode === 'app') {
        closeWebview()
      } else {
        redirectHome() 
      }
    } 
    location.href = $layoutOption.navBackUrl 
  }

</script>
<style>
  .wrap-home {
    min-height:0px
  }
</style>

<Router {url}>
  <NavBar />
  <section class="wrap {isHome ? 'wrap-home' : ''} {isStarbucksApp? 'wrap-starbucks': ''}">
    
      <Carousel/>
      <Banner/>

      <Route path="/">
        <Redirect path="/home" />
      </Route>
      <Route path="/home" component={Home} />
      <Route path="/store" component={Store} />
      <Route path="/store-emap" component={StoreEmap} />
      <Route path="/store-map" component={StoreFullMap} />
      <Route path="/info" component={Information} />
      <Route path="/op-test" component={OPFunctionTest} />
      <Route path="/mirror" component={Mirror} />
      <Route path="/register-info" component={RegisterInfo} />
      <Route path="/register" component={Register} />
      <Route path="/register-result" component={Result} />
      <Route path="/op-auth" component={OPAuthorize} />
      <Route path="/op-check-wallet" component={OPCheckWallet} />
      <Route path="/op-payment" component={OPOnlinePayment} />
      <Route path="/redeem" component={Redeem} />
      <Route path="/redeem-via-pos" component={RedeemViaPOS} />
      <Route path="/redeem-via-scanner" component={RedeemViaScanner} />
      <Route path="/redeem-barcode" component={MMKBarcode} />
      <Route path="/redeem-scan-pincode" component={RedeemScanPincode} />
      <Route path="/redeem-scan-cup" component={RedeemScanCup} />
      <Route path="/redeem-scan-success" component={RedeemScanSuccess} />
      <Route path="/cancel" component={Cancel} />
      <Route path="/cancel-result" component={CancelResult} />
      <Route path="/return-cup" component={Return} />
      <Route path="/return-via-machine" component={ReturnViaMachine} />
      <Route path="/return-via-pos" component={ReturnViaPOS} />
      <Route path="/return-via-sbk-pos" component={ReturnViaStarbucksPOS} />
      <Route path="/return-via-scanner" component={ReturnViaScanner} />
      <Route path="/return-scan-cup" component={ReturnScanCup} />
      <Route path="/return-scan-pincode" component={ReturnScanPincode} />
      <Route path="/return-scan-success" component={ReturnScanSuccess} />
      <Route path="/return-barcode" component={ReturnBarcode} />
      <Route path="/refund-barcode" component={RefundBarcode} />
      <Route path="/order" component={Order} />
      <Route path="/starbucks" component={StarbucksHome} />
      <Route path="/starbucks-store" component={Store} />
      <Route path="/starbucks-emap" component={StoreEmap} />
      <Route path="/starbucks-return" component={StarbucksReturn} />
      <Route path="/starbucks-barcode" component={StarbucksBarcode} />
      <Route path="/starbucks-mirror" component={StarbucksMirror} />
      <Route path="/starbucks-light" component={StarbucksLight} />
      <Route path="/starbucks-camera" component={StarbucksCamera} />
      <Route path="/starbucks-demo" component={StarbucksDemo} />
      <Route path="**" component={PageNotFound} />
    
  </section>

</Router>

<Gtag></Gtag>