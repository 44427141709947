<script>
    import RedeemSummary from './RedeemSummary.svelte'
    import FilterGroup from './FilterGroup.svelte'
    import { layoutOption, apiConfig, appSetting, memberInfo, queryStr, checkFeature } from '../store/inapp-store' 
    import { screenLight } from '../store/openpoint' 
    import RedeemSection from './RedeemSection.svelte' 
    import { Modal, AlertTimer } from '../components'
    import { alertMsg } from '../store/debug-alert'

    let enableBUReturn = checkFeature($appSetting.enableBUReturn, $memberInfo.gid, $appSetting.guestList) 

    $layoutOption.navTitle = '兌換取杯'
    $layoutOption.navBackUrl = enableBUReturn ? '/redeem' : `/home${$queryStr}`
    $layoutOption.showCarousel = false
    $layoutOption.showCancel = false
    $layoutOption.showHome = true

    let title = '跨品牌通路借杯'
    let filter = 'all'
    let orderby = 'expiredtime'
    let showRefresh = true

    let modal = { 
        title : '注意事項',
        message : '取杯請掃描門市QRCode<br>此兌換券須以門市實際庫存數量進行兌換',
        type: 'alert'
    }
    
    let isOpen = false

    let alertModal = { message :'取得兌換取杯資訊失敗，請稍後進行重試，或洽詢客服專線 0809-090-711', title: '兌換取杯', timeout: 5 };
    let enabled = true

    let memberOrders = []
    let availableRedeemCount = 0;
    let displayOrders = []

    let summaryTitle = '可兌換杯數'
    let mode = 'scanner'

    // 取得OP會員循環杯訂單資訊
    fetch(`${$apiConfig.apiUrl}/order?code=${$apiConfig.apiKey}&gid=${$memberInfo.gid}&filter=unredeem`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(d => {
                console.log(d)
                if(d.returnCode === '0000') {
                    memberOrders = d.info
                    displayOrders = memberOrders
                    console.log('memberOrders=', memberOrders)
                    sumRedeemCount() 
                } 
                enabled = false
        })
        .catch(e => {
            console.log(e)
        });

    function sumRedeemCount() {
        if(memberOrders.length == 0) { return false }
        var arr = memberOrders.map(el => el.totalCount - el.cancelCount - el.redeemCount )
        availableRedeemCount = arr.reduce((a,b)=>a+b)
        isOpen = availableRedeemCount > 0
        
        var arr2 = memberOrders.filter(el => el.payType !== 3 ).map(el => el.totalCount - el.cancelCount - el.redeemCount )
        let availableCancelCount = arr2.length > 0 ? arr2.reduce((a,b)=>a+b) : 0

        $layoutOption.showCancel = availableCancelCount > 0
    }

    function refreshMemberOrders(newOrderby){
        displayOrders = newOrderby.indexOf('-desc') > 0 ? memberOrders.slice().reverse() : memberOrders
        displayOrders = displayOrders 
    }

    try{
        screenLight(false)
    }catch(e){
        console.log(e)
    }

</script>

<div>
    <FilterGroup {filter} {orderby} {showRefresh} {title} on:select={e => refreshMemberOrders(e.detail)}></FilterGroup>
    <RedeemSummary {summaryTitle} {memberOrders} {mode}></RedeemSummary>
    <div class="chg-court-p">
        <p>請按下兌換條碼供門市人員掃碼進行取杯<br>(依門市實際庫存為主)</p>
    </div>

    {#each displayOrders as order (order)}
        <RedeemSection {order} {mode} {title}></RedeemSection>
    {/each}

</div>

{#if (isOpen)}
<Modal {modal} {isOpen} on:closeModal={e => isOpen = false}></Modal>
{/if}

<AlertTimer {alertModal} {enabled} on:closeModal={e => enabled = false}></AlertTimer>