<script>
    import { layoutOption, mode , queryStr } from '../store/inapp-store' 
    import { Modal, Loader } from '../components';
    import { getWalletStatus } from '../store/openpoint' 
    import { onMount } from 'svelte';

    $layoutOption.showBanner = false
    $layoutOption.showCarousel = false

    let loader = { content : '錢包狀態確認中...' };
    let isBlock = true;

    let walletResult = {
        status: '',
        opw: '',
        icashpay: ''
    } 
    let modal = { 
        title : '登記借杯',
        message : '取得錢包狀態失敗，請稍候再試',
        type: 'alert'
    }
    
    let isOpen = false

    onMount(() =>{
        try {
            getWalletStatus()
        }catch(e) {
            if($mode === 'app') {
                modal.message  = e.msg
                isBlock = false
                isOpen = true
            }
        }

        let timer ;
        timer = setInterval(function(){
            var walletStatus = document.getElementById('walletStatus').value;
            console.log('walletStatus', walletStatus)
            if(walletStatus !== '') {
                walletResult.status = walletStatus
                clearInterval(timer)

                // 錢包狀態回傳結果
                // Y:有任一支付工具 N:無任何支付工具 F:取得錢包失敗
                if(walletResult.status === 'Y') { 
                        nextStep()
                        return false
                } else {
                    if(walletResult.status === 'N')  {
                        modal.message  = '請先至OP錢包設定支付工具'
                    } 
                    isBlock = false
                    isOpen = true
                }
            }
        },1000) 

        if($mode !== 'app')  {
            setTimeout(function(){
                document.getElementById('walletStatus').value = 'Y'
            },2000)
        }
    })
    
    function nextStep() {
        location.href = '/op-payment'
    }

</script>

<input type="hidden" id="walletStatus" >

<Loader {loader} {isBlock}></Loader>
<Modal {modal} {isOpen} on:closeModal={e => location.href = `/home${$queryStr}`}></Modal>