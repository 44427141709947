{#if (appConfigJS !== '')}
<LibLoader url={appConfigJS} on:loaded="{onLoaded}" />
{/if}
<script>
  import API from '../store/config';
  import { memberInfo, layoutOption, queryStr, apiConfig, appVer, mode, skipInfo, appSetting } from '../store/inapp-store' 
  import { LibLoader } from '../components';
 
  let appConfigJS = '' //-- 取得系統開放設定JS所在位置
  let validate = false
  
  $layoutOption = {
    navTitle: '',
    navBackUrl: '',
    showCarousel: validate,
    showBanner: false,
    showHome: false,
    showCancel: false,
  };

  let warning = { message : []   , small: [] }
  
  const queryString = window.location.search
  $queryStr = queryString

  const urlParams = new URLSearchParams(queryString)
  const cipherText = urlParams.get('ct')
  const appVersion = urlParams.get('v')
  $appVer = appVersion

  const appMode = urlParams.get('mode');
  $mode = appMode !== 'dev' && appMode !== 'demo' ? 'app' : appMode;
  $skipInfo = appMode === 'dev' ? false : $skipInfo;

  $apiConfig.storageEndpoint = $mode === 'dev' ? 'https://recyclecupteststorage.blob.core.windows.net' : ''

  $:console.log('### $mode', $mode)

  if ($mode !== 'dev') {
    $apiConfig.storageEndpoint = ''
    fetchAPIConfig();
  }

  $:console.log('### $apiConfig', $apiConfig)

  if ($apiConfig.storageEndpoint) {
    appConfigJS = `${$apiConfig.storageEndpoint}/script-container/app-config.js?d=${new Date().getTime()}`;
  }

  $:console.log('### appConfigJS',appConfigJS)
  $:console.log('### API', API)

  function fetchAPIConfig() {
    fetch(`${API}/apiUrl`)
      .then(response => response.json())
      .then(d => {
        console.log(d);
        $apiConfig = d;
        appConfigJS = `${$apiConfig.storageEndpoint}/script-container/app-config.js?d=${new Date().getTime()}`;
      });
  }

  /**
   * 載入系統開放設定後會觸發此事件
   */ 
  function onLoaded() {
    if (!appConfig) return false;

    console.log('onLoaded', appConfig);
    $appSetting = appConfig;
    prepareMemberMID(); // 取得會員GID
    render();           // 渲染服務入口頁面
  }

  /**
   * 取得會員MID
   */
  function prepareMemberMID() {
    fetch(`${$apiConfig.apiUrl}/decrypt?code=${$apiConfig.apiKey}`, {
    method: 'POST',
    body:JSON.stringify({cipherText : cipherText, appVersion : appVersion })
    })
    .then(response => response.json())
    .then(d => { 
        console.log(d) 
        $memberInfo.mid = d.mid
    });
  }

  $:console.log('### mid', $memberInfo.mid)

  /**
   * 渲染星巴克服務入口頁面
   */
  function render() {
    var isGuest = includedInGuestList()
    console.log('is guest', isGuest)

     // 判斷目前是否開放服務 & 會員GID是否為白名單
    validate = appConfig.enableStarbucksSevenReturn === true || ( $memberInfo.mid !== '' && isGuest === true)

    // 對外顯示服務未開放
    if(validate === false) {
      warning = { message : ['系統準備中', '功能即將上線']   , small: [] }
      return false
    }

    // 判斷目前是否處於服務暫停期間
    if(appConfig.enableStarbucksSevenReturn === true && isGuest === false) {
      var pause = appConfig.starbucksPauseConfig
      var inPause = new Date(pause.beginTime) < new Date() && new Date(pause.endTime) > new Date()

      console.log('inPause=', inPause)

      if(inPause) {
        warning = { 
          message : pause.messages,
          small: []
        }

        validate = false
      }
    } 
  }

  /**
   * 檢查會員GID是否存在於白名單
  */
  function includedInGuestList() {
    let guestLis = []
    guestLis = appConfig.guestList
    let matchGID = guestLis.find(el=> el === $memberInfo.mid) 
    validate = matchGID !== undefined
    return validate
  }
</script>

<style>
  .text-pause {
    font-size: 1.4rem;
  }
  .btn-hidden {
    visibility: hidden;
  }
</style>
<div class="main index">
  <h1 class="text-A-C">星 巴 克 循 環 杯 服 務</h1>
  <h3 class="text-A-C">Starbucks Borrow A Cup Service</h3>
  <img src="img/1.gif" alt="">

  {#if validate === true}
  <h2 class="text-A-C">
      OPEN iECO 循 環 杯 通 路 選 擇
  </h2>

  <div class="store">
      <div style="display: flex; place-content: stretch space-between;flex-wrap: wrap;">
          <div class="btn-box btn-L">
              <button class="flexbox-flex-Frn-Psf-Ac" on:click={()=> location.href='/starbucks-return' }>
                  <span style="">統一超商 7-ELEVEN 還杯</span>                        
              </button>
          </div>
          <div class="btn-box btn-R">
              <button class="flexbox-flex-Frn-Psf-Ac" on:click={()=> location.href='/starbucks-store' }>
                  <img  src="img/icon-location.svg" alt="">
                  <span style="">據點查詢</span>                        
              </button>
          </div>
          <div class="btn-box btn-L btn-hidden">
              <button class="flexbox-flex-Frn-Psf-Ac">
                  <span style="">21世紀風味館 還杯</span>                        
              </button>
          </div>
          <div class="btn-box btn-R btn-hidden">
              <button class="flexbox-flex-Frn-Psf-Ac">
                  <img  src="img/icon-location.svg" alt="">
                  <span style="">據點查詢</span>                        
              </button>
          </div>
          <div class="btn-box btn-L btn-hidden">
              <button class="flexbox-flex-Frn-Psf-Ac">
                  <span style="">聖娜多堡 還杯</span>                        
              </button>
          </div>
          <div class="btn-box btn-R btn-hidden">
              <button class="flexbox-flex-Frn-Psf-Ac">
                  <img  src="img/icon-location.svg" alt="">
                  <span style="">據點查詢</span>                        
              </button>
          </div>
      </div>
  </div>

  <p>
      目前並非所有通路門市皆有循環杯服務，可點上方查詢目前循環杯服務門市據點。
  </p>
  {:else}
  <div class="store">
    <div class="btn-box">
      <button class="flexbox-flex-Frn-Psf-Ac" style="visibility: hidden;"> </button>
      {#each warning.message as message(message)}
        <h1 class="text-A-C text-pause" >{message}</h1>
      {/each} 
      <button class="flexbox-flex-Frn-Psf-Ac" style="visibility: hidden;"></button> 
      <button class="flexbox-flex-Frn-Psf-Ac" style="visibility: hidden;"></button> 
    </div>
  </div>
  {/if}
  <address  class="text-A-C">
    {#if validate}
      OPEN iECO 循 環 杯 客 服 專 線
      <br>
      0 8 0 9 - 0 9 0 - 7 1 1
    {/if}
  <address>
</div>