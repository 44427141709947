<script>
  import { memberInfo, layoutOption, queryStr, apiConfig, appVer, mode, skipInfo, appSetting } from '../store/inapp-store' 
  import { Modal, Loader, Warning, LibLoader } from '../components';
  import { Html5QrcodeScanner } from 'html5-qrcode'; 
 
  let validate = false

  $layoutOption.navTitle = ''
  $layoutOption.navBackUrl = ''
  $layoutOption.showCarousel = validate
  $layoutOption.showBanner = false
  $layoutOption.showHome = false
  $layoutOption.showCancel = false

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const action = urlParams.get('action')


  function onScanSuccess(decodedText, decodedResult) {
    // Handle on success condition with the decoded text or result.
      console.log(`Scan result: ${decodedText}`, decodedResult);
  }

  setTimeout(function(){
    var html5QrcodeScanner = new Html5QrcodeScanner(
    "reader", { fps: 10, qrbox: 250 });

    html5QrcodeScanner.render(onScanSuccess);

  },1000)

 
</script>

<div style="width: 100%" id="reader"></div>


<div class="cd-fx-btm" id="cal-csendmodal">
  <button class="cd-send submit-a" on:click={()=> location.href = '/starbucks' + $queryStr}>
      <span class="cd-send-p">回上一頁</span>
  </button>
</div>
