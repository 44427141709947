<LibLoader url={storeConfigJS} on:loaded="{onLoaded}" />
<script>
  import { layoutOption, queryStr, apiConfig, mode, locationInfo } from '../store/inapp-store'
  import { openWebview } from '../store/openpoint'
  import { LibLoader } from '../components'
  import StoreItem from './StoreItem.svelte'
  import FilterGroup from './FilterGroup.svelte' 
  import { element } from 'svelte/internal';
  import { onMount } from 'svelte'

  const urlParams = new URLSearchParams(window.location.search)
  const rtnUrl = urlParams.get('rtn-url')
  const f = urlParams.get('f')

  let isStarbucksApp = location.href.indexOf('starbucks') > 0

  $layoutOption.navTitle = isStarbucksApp ? '' : '借還杯門市'
  $layoutOption.navBackUrl =  (rtnUrl === null || rtnUrl === undefined) ?`/home${$queryStr}` : rtnUrl
  $layoutOption.showCarousel = false
  $layoutOption.showBanner = false
  $layoutOption.showHome = true
  $layoutOption.showCancel = false
  $locationInfo = { latitude : '', longitude : '' }

  let storeConfigJS = `${$apiConfig.storageEndpoint}/script-container/store-config.js?d=${new Date().getTime()}` // 取得門市資訊JS所在位置
  
  let active = 'register'

  let filter = f === null ? 'all' : f
  let filteredStoreList = []
  let displayResult = false

  let provider = filter === 'via_machine' || filter === 'via_pos' ? 'seven' : filter === 'via_sbk' ? 'starbucks' : 'bu'

  onMount(() =>{
    // 開啟外部連結-星巴克循環杯門市查詢地圖
    if(provider === 'starbucks') {
      redirectStarbucksEmap()
    }
  })

  /**
   * 載入門市資訊後會觸發此事件
   */ 
  function onLoaded() {
    console.log('### storeConfig', storeConfig)

    filteredStoreList = filter === 'all' ? storeConfig.storeList :
      filter === 'via_pos' ? storeConfig.storeList.filter(el=> el.hasPOS === true) :
      filter === 'via_machine' ? storeConfig.storeList.filter(el=> el.hasMachine === true) :
      filter === 'via_pincode' ? storeConfig.storeList.filter(el=> el.hasPincode === true) : []

    displayResult = provider !== 'starbucks'
  }

  $:console.log('### filteredStoreList length=', filteredStoreList.length)

  /**
   * 開啟外部連結-星巴克循環杯門市查詢地圖
   */
  function redirectStarbucksEmap() {
    let sbkStoreMap = 'https://www.starbucks.com.tw/stores/storesearch.jspx?type=borrow_return';
    if ($mode === 'app') {
      openWebview(false, sbkStoreMap)
      location.href = $layoutOption.navBackUrl 
    } else {
      location.href = sbkStoreMap
    }
  }

</script>

{#if (displayResult)}

  {#if (isStarbucksApp)}

    <div class="main storesearch">
      <h1>統一超商櫃檯還杯門市</h1>
      <div class="SRCH-list-box">
          <div class="FL-box">
              <a href="#filter-btn">
                  <button class="flexbox-flex-Frn-Psc-Ac">
                      <span>{filter == 'all' ? '全部' : filter == 'via_pos' ? '提供借杯與櫃檯還杯門市' : '提供借杯與還杯機還杯門市'}</span>
                      <img src="img/icon-filter.svg" alt="">
                  </button>
              </a>
          </div>
          <div class="FL-content-box scrollbar" style="overflow: scroll;">
            {#each filteredStoreList as item (item)}
              <div class="lcontent">
                <h4>
                  {item.storeName} ({item.storeId})
                </h4>
                <p>
                    {item.address} 
                    <img src="img/icon-location-001.svg" alt="" on:click={()=> location.href = '/starbucks-emap?q=' + item.address + '&f=' + filter + '&p=' + item.hasPOS + '&m=' + item.hasMachine + '&n=' + item.storeName}>
                </p>
            </div>
            {/each}

          </div>
        </div>
        <button class="btn-back" on:click={ location.href = '/starbucks'+ $queryStr}>回上頁</button>
    </div>
    <FilterGroup {filter} {isStarbucksApp}></FilterGroup>

  {:else}

  <div>
    <FilterGroup {filter} {isStarbucksApp}></FilterGroup>

    {#if (filteredStoreList.length > 0)}
    <div class="content-wrap">
        <div class="show-box box-1">
          <div class="lease">
            {#each filteredStoreList as item (item)}
              <StoreItem {item} {filter}></StoreItem>
            {/each}
          </div>
        </div>
    </div>
    {:else}
    <div class="system-busy">
      <span class="system-busy-p pt-5">
        目前沒有提供本服務的門市哦
      </span>
    </div>
    {/if}
  </div>
  {/if}

{/if}
