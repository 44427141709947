<script>
    import { tempRedeemInfos, memberInfo, appSetting, checkFeature } from '../store/inapp-store' 
    
    export let item
    export let order
    export let mode = 'pos'
    export let title = ''

    let enableCashPay = checkFeature($appSetting.enableCashPay, $memberInfo.gid, $appSetting.guestList) 

    function openBarcode(item) {
        $tempRedeemInfos = order.cpmobiPayLog.filter(el=> el.paidStatus === 0)
        location.href = `/redeem-barcode?id=${item.id}`
    }

</script>

<div class="cr">
    <div style="display: flex;position: relative;">
    {#if (enableCashPay)}
        <div class="cr-s">
            <img src="/img/cup-img-{item.size}.jpg" class="cr-s" alt="">
            {#if (order.payType === 1 || order.payType === 2)}
            <div class="label pay" style=" height: unset; margin-bottom: unset;">已付款</div>
            {/if}
            {#if (order.payType === 3)}
            <div class="label unpay">未付款</div>
            {/if}
        </div>
    {/if}

        <div>
            {#if title !== ''}
            <p class="cr-p">{title}</p>
            {/if}

            {#if item.size === '10L'}
            <p class="cr-p">{item.name}</p>
            {:else}
            <p class="cr-p">{item.name} {item.count} 杯</p>
            {/if}
            <p class="cr-d">取杯期限<span class="cr-dat">{item.dueTimeStr}</span></p>
        </div>
    </div>
    <div style="display: flex ;margin:-1rem 0;">
        <div class="cr-clm-hr"></div>
        {#if mode === 'pos'}
        <btn class="cr-code" on:click={()=> openBarcode(item)}>     
            <img src="img/atom-button-glyphs-buttons-barcode-btn-barcode-o-n.svg" alt="">
            {#if (enableCashPay)}
                {#if (order.payType === 1 || order.payType === 2)}
                <span class="cr-chgcode">兌換條碼</span>
                {/if}
                {#if (order.payType === 3)}
                <span class="cr-chgcode">到店支付<br>兌換條碼</span>
                {/if}
            {:else}
                <span class="cr-chgcode">兌換條碼</span>
            {/if}
        </btn>
        {:else}
        <btn class="cr-code" on:click={()=> location.href = `/redeem-scan-pincode?id=${item.orderId}&c=${item.count}&s=${item.size}` }>     
            <img src="img/atom-button-glyphs-buttons-qrcode-btn-qrcode-o-t.svg" alt="">
            <span class="cr-chgcode">掃碼兌換</span>
        </btn>
        {/if}
    </div>
</div>