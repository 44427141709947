<script>
  import API from '../store/config';
  import { layoutOption, queryStr,apiConfig, appSetting, memberInfo, checkFeature } from '../store/inapp-store'
  import { LibLoader } from '../components'
  import StoreItem from './StoreItem.svelte' 


  let isStarbucksApp = location.href.indexOf('starbucks') > 0

  $layoutOption.navTitle = isStarbucksApp ? '' : '借還杯門市'
  $layoutOption.navBackUrl = `/home${$queryStr}`
  $layoutOption.showCarousel = false
  $layoutOption.showBanner = false
  $layoutOption.showHome = true
  $layoutOption.showCancel = false

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const q = urlParams.get('q')
  let adress = q === null ? '' : q
  if(adress !== '') {
    $layoutOption.navBackUrl = `/store${queryString}`
  }

  const f = urlParams.get('f')
  const p = urlParams.get('p')
  const m = urlParams.get('m')
  const n = urlParams.get('n')

  let returnType = getReturnType() 
  
  function getReturnType() {
    var rt = m === 'true' ? 'machine' : 'pos'
    if(f === 'via_machine') { rt = 'machine' }
    if(f === 'via_pos') { rt = 'pos' }
    return rt
  }

</script>
<style>
  .map-view {
    min-height: calc(100vh - 2.75rem);
    touch-action: manipulation;
  }

  .map-btn-view {
    min-height: calc(100vh - 5.75rem);
    touch-action: manipulation;
  }
</style>

{#if (adress === '')}

  <iframe class="map-view" title="電子地圖" src="https://emap.pcsc.com.tw/emap.aspx"></iframe>

{:else if isStarbucksApp}

  <div class="main storesearch">
    <h1>統一超商櫃檯還杯門市</h1>
    <div class="SRCH-list-box">
        <div class="FL-box">
            <a href="#filter-btn">
                <button class="flexbox-flex-Frn-Psc-Ac">
                    <span>{n}門市</span>
                </button>
            </a>
        </div>
        <div class="FL-content-box scrollbar" style="overflow: scroll;">
          <iframe title="Google地圖"
            src="https://map.google.com/maps?q={adress}&amp;z=17&amp;iwloc=B&amp;output=embed"
            width="100%" style="border:0;" allowfullscreen="" loading="lazy" height="100%"
            referrerpolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
    </div>
    <button class="btn-back" on:click={ location.href = '/starbucks-store?f=' + f }>回上頁</button>
  </div>

{:else}
  <div>
      <iframe title="Google地圖"
        class="map-btn-view" 
        src="https://map.google.com/maps?q={adress}&amp;z=17&amp;iwloc=B&amp;output=embed"
        width="100%" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade">
      </iframe>
  </div>

  <div class="pay-d-fx-btm">
    <btn class="pay-d-send submit-a w-100 active" on:click={()=> location.href = `/return-via-${returnType}?rtn-url=/store-emap${queryString}`}>
      <p class="pay-d-send-ps">立即還杯</p>
    </btn>
  </div>

{/if}