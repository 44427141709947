<script>
    import { createEventDispatcher } from 'svelte';
    import { memberInfo, appSetting, checkFeature } from '../store/inapp-store' 
    import { Modal } from '../components';

    const dispatch = createEventDispatcher();

    export let selectedPayType
    export let selectedRefundType
    let showRefundTypeSelector = false

    let enableCashPay = checkFeature($appSetting.enableCashPay, $memberInfo.gid, $appSetting.guestList) 
    let enableBUReturn = checkFeature($appSetting.enableBUReturn, $memberInfo.gid, $appSetting.guestList) 
    let enablePurseRefund = checkFeature($appSetting.enablePurseRefund, $memberInfo.gid, $appSetting.guestList) 
    let enableOnlinePay = !handlePausePeriod()
    selectedPayType = enableOnlinePay ? selectedPayType : 'offline'
    showRefundTypeSelector = enableOnlinePay ? showRefundTypeSelector : true 

    if(!enableOnlinePay) {
        let matchGID = $appSetting.guestList.find(el=> el === $memberInfo.gid) 
        enableOnlinePay = matchGID !== undefined
    }

    let modal = { title : $appSetting.onlinepayPauseConfig.messages[0], message : $appSetting.onlinepayPauseConfig.messages[1], type: 'alert' }
    let isOpen = !enableOnlinePay

    $:console.log('enableOnlinePay=', enableOnlinePay)
    $:console.log('selectedPayType=', selectedPayType)

    /**
     * 選擇結帳方式後觸發此方法
     * @param payType
     */
    function changePayType (payType) {
        if(!enableOnlinePay && payType === 'online') { 
            isOpen = true
            return false
         }

        selectedPayType = payType 
        showRefundTypeSelector = selectedPayType === 'offline'
        console.log('### selectedPayType', selectedPayType)  
        dispatch('updatePayType', selectedPayType);
    }

    /**
     * 選擇到店支付退款方式後觸發此方法
     * @param refundType
     */
     function changeRefundType  (refundType) {
        selectedRefundType = refundType 
        console.log('###### selectedRefundType', selectedRefundType)  
        dispatch('updateRefundType', selectedRefundType);
    }

    /**
     * 判斷目前是否處於金流服務暫停期間
     */
    function handlePausePeriod() {
        const { beginTime, endTime } = $appSetting.onlinepayPauseConfig;
        const inPause = new Date(beginTime) < new Date() && new Date(endTime) > new Date()
        return inPause
    }

    function onCloseModal() {
        isOpen = false 
        selectedPayType = 'offline'
        return false
    }
</script>

<div class="pay">
    <p class="pay-p">請選擇結帳方式</p>
</div>

<div class="payment">
    <div class="pay-wrap">
        <label for="a" class="pay-label" on:click={()=> changePayType('online')}>
            <input name="payment" type="radio" id="a" class="a-in" bind:group={selectedPayType} value="online">
            <span class="a-sn"><img src="img/atom-button-control-radio-button-btn-radio-t.svg" alt=""></span>
        </label>
        <img src="img/ic-pay-online.svg" alt="">
        <p>線上支付</p>
    </div>

{#if (enableCashPay)}
    <div class="pay-wrap">
        <label for="b" class="pay-label" on:click={()=> changePayType('offline')}>
            <input name="payment" type="radio" id="b" class="a-in" bind:group={selectedPayType} value="offline">
            <span class="a-sn"><img src="img/atom-button-control-radio-button-btn-radio-t.svg" alt=""></span>
        </label>
        <img src="img/ic-pay-store.svg" alt="">
        <p>到店支付 {enableBUReturn ? '（僅限統一超商）' : ''}</p>
    </div>
{/if}
</div>

{#if enableBUReturn && showRefundTypeSelector}
    {#if enablePurseRefund}
    <div class="payment">
        <div class="pay-wrap">
            <label for="a" class="pay-label" on:click={()=> changeRefundType('purse')}>
                <input name="refundType" type="radio" id="a" class="a-in" bind:group={selectedRefundType} value="purse">
                <span class="a-sn"><img src="img/atom-button-control-radio-button-btn-radio-t.svg" alt=""></span>
            </label>
            <p>&nbsp;同意使用零錢包接受退款</p>
        </div>
        <div class="pay-wrap pt-2">
            <label for="b" class="pay-label" on:click={()=> changeRefundType('pos')}>
                <input name="refundType" type="radio" id="b" class="a-in" bind:group={selectedRefundType} value="pos">
                <span class="a-sn"><img src="img/atom-button-control-radio-button-btn-radio-t.svg" alt=""></span>
            </label>
            <p>&nbsp;至門市櫃台退現金</p>
        </div>
    </div>
    {:else}
    <div class="srvmk text-center">
        <p class="srvmk-ctn" style="color: #fd4c01">
            還杯後至7-ELEVEn櫃台退款
        </p>
    </div>
    {/if}
{/if}

<Modal {modal} {isOpen} on:closeModal={onCloseModal}></Modal>