<script>
  import { memberInfo, layoutOption, queryStr, apiConfig, appVer, mode, skipInfo, appSetting } from '../store/inapp-store' 
  import { Modal, Loader, Warning, LibLoader, QRCode, StarbucksQRCode } from '../components';
 
  let validate = false

  $layoutOption.navTitle = ''
  $layoutOption.navBackUrl = ''
  $layoutOption.showCarousel = validate
  $layoutOption.showBanner = false
  $layoutOption.showHome = false
  $layoutOption.showCancel = false

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const action = urlParams.get('action')

</script>


<!-- <div class="p-5" align="center">
  <QRCode text="ff00b4c942264c77b5a6524f8624062f" qrbox=150/>
</div> -->

<div class="p-5 w-75" align="center">
  <StarbucksQRCode gid="GID05269681296804" count="2"/>
</div>



<div class="pay-d-fx-btm">
  <button class="pay-d-send submit-a" on:click={()=> location.href = '/starbucks' + $queryStr}>
      <p class="pay-d-send-p">回上一頁</p>
  </button>
  {#if action === 'light'}
  <button href="/redeem" class="pay-d-send submit-a" on:click={()=> location.href = '/starbucks-light'}>
      <p class="pay-d-send-ps">關閉打亮</p>
  </button>
  {:else}
  <button href="/redeem" class="pay-d-send submit-a" on:click={()=> location.href = '/starbucks-light?action=light'}>
    <p class="pay-d-send-ps">開啟打亮</p>
  </button>
  {/if}
</div>