<script>
  import {
    memberInfo,
    newOrderId,
    apiConfig,
    layoutOption,
    queryStr,
  } from '../store/inapp-store';
  import {
    screenLight,
    closeWebview,
    openWebview,
    getAuthV,
    getWalletStatus,
    onlinePayment,
    opOpenScanner,
    opOpenMemberGid,
    opQueryCameraStatus,
    opGetWebviewRange,
    opSetViewRang,
    setLocationPermission,
    getLocation,
    openByFeatureId
  } from '../store/openpoint';
  import { onMount } from 'svelte';

  $layoutOption.navTitle = 'OPAPP功能測試';
  $layoutOption.navBackUrl = `/home${$queryStr}`;
  $layoutOption.showCarousel = false;
  $layoutOption.showBanner = false;
  $layoutOption.showHome = false;
  $layoutOption.showCancel = false;
  $memberInfo.authV = '';

  let lightUp = false;
  let scannerType = 'Barcode';
  let regexContent = ['.*'];
  let showOpLogo = true;
  let title = '';
  let pageTypeSamples = [
    'point_home',
    'point_subpage',
    'point_search',
    'favorite',
    'reward',
    'presale',
    'brand',
    'reward_subpage',
    'member_badge',
    'presale_salebarcode',
    'point_olpaypage',
    'presale_subpage',
    'public_page_type1',
    'public_page_type2',
    'presale_page_type1',
  ];
  let pageType = 'point_search';
  let openUrl = 'https://emap.pcsc.com.tw'
  let openMode = 'ON'
  let featureId = 'IDP01B01P2S1'

  function toggleScreenLight() {
    lightUp = !lightUp;
    try {
      screenLight(lightUp);
    } catch (e) {
      alert(e.msg);
    }
  }

  function toggleopQueryCameraStatus() {
    try {
      opQueryCameraStatus();
    } catch (e) {
      alert(e.msg);
    }
  }

  function toggleopOpenScanner() {
    try {
      opOpenScanner(scannerType, regexContent);
    } catch (e) {
      alert(e.msg);
    }
  }

  function toggleopOpenMemberGid() {
    try {
      opOpenMemberGid();
    } catch (e) {
      alert(e.msg);
    }
  }

  function toggleopOPGetWebviewRange() {
    showOpLogo = !showOpLogo;
    try {
      opGetWebviewRange(showOpLogo);
    } catch (e) {
      alert(e.msg);
    }
  }

  function toggleopReload() {
    location.reload();
  }

  function toggleopOpSetViewRang() {
    try {
      opSetViewRang(pageType, title);
    } catch (e) {
      alert(e.msg);
    }
  }

  function toggleOpenWebview() {
    if(openUrl === '') { return false }
    try {
      var is_inapp = openMode === 'ON'
      openWebview(is_inapp, openUrl);
    } catch (e) {
      alert(e.msg);
    }
  }

  function toggleOpenFeature() {
    try {
      openByFeatureId(featureId, '');
    } catch (e) {
      alert(e.msg);
    }
  }

  $:console.log('scannerType=', scannerType)

</script>

<div>
  <div class="hb-sm-w-ms">
    <div class="hb-sm-w-ma">
      <div class="hb-sm-w">
        <span class="hb-sm-w-nm">1</span>
      </div>
      <span class="hb-w-mt-t" on:click={closeWebview}>關閉Webview</span>
    </div>

    <hr />

    <div class="hb-sm-w-ma">
      <div class="hb-sm-w">
        <span class="hb-sm-w-nm">2</span>
      </div>
      <span class="hb-w-mt-t" on:click={toggleScreenLight}>螢幕亮度調整</span>
    </div>

    <hr />

    <div class="hb-sm-w-ma">
      <div class="hb-sm-w">
        <span class="hb-sm-w-nm">3</span>
      </div>
      <span class="hb-w-mt-t">OP APP入口參數</span>
    </div>
    <div class="hb-w-mtts">
      <textarea class="hb-w-mtts-btn">{$queryStr}</textarea>
    </div>

    <hr />

    <div class="hb-sm-w-ma">
      <div class="hb-sm-w">
        <span class="hb-sm-w-nm">4</span>
      </div>
      <span class="hb-w-mt-t" on:click={() => (location.href = '/store-emap')}
        >7-11 電子地圖</span
      >
    </div>

    <hr />

    <div class="hb-sm-w-ma">
      <div class="hb-sm-w">
        <span class="hb-sm-w-nm">5</span>
      </div>
      <span class="hb-w-mt-t" on:click={toggleopQueryCameraStatus}
        >取得相機權限</span
      >
    </div>
    <div class="hb-w-mtts">
      <textarea id="isCamera" class="hb-w-mtts-btn" />
    </div>

    <hr />

    <div class="hb-sm-w-ma">
      <div class="hb-sm-w">
        <span class="hb-sm-w-nm">6</span>
      </div>
      <span class="hb-w-mt-t" on:click={toggleopOpenScanner}>開啟掃描器</span>
    </div>

    <div class="payment">
      <div class="pay-wrap" on:click={()=> scannerType = 'Barcode'}>
          <label for="a" class="pay-label">
              <input name="refundType" type="radio" id="a" class="a-in" bind:group={scannerType} value="Barcode">
              <span class="a-sn"><img src="img/atom-button-control-radio-button-btn-radio-t.svg" alt=""></span>
          </label>
          <p>&nbsp;一維條碼</p>
      </div>
      <div class="pay-wrap pt-2" on:click={()=> scannerType = 'QRCode'}>
          <label for="b" class="pay-label">
              <input name="refundType" type="radio" id="b" class="a-in" bind:group={scannerType}  value="QRCode">
              <span class="a-sn"><img src="img/atom-button-control-radio-button-btn-radio-t.svg" alt=""></span>
          </label>
          <p>&nbsp;二維碼</p>
      </div>
    </div>

    <div class="hb-sm-w-ma">
      <!-- <input
        id="scannerType"
        placeholder="ex:QRCode,Barcode"
        class="hb-w-mtts"
        on:change={(e) => {
          scannerType = e.target.value;
        }}
      /> -->
      <input
        id="regexContent"
        placeholder="正則逗點區分,ex:AAA,BBB"
        class="hb-w-mtts"
        on:change={(e) => {
          regexContent = e.target.value.split(',');
        }}
      />
    </div>
    <div class="hb-w-mtts">
      <textarea id="statusCode" class="hb-w-mtts-btn" />
    </div>
    <div class="hb-w-mtts">
      <textarea id="scanContentList" class="hb-w-mtts-btn" />
    </div>
    <hr />

    <div class="hb-sm-w-ma">
      <div class="hb-sm-w">
        <span class="hb-sm-w-nm">7</span>
      </div>
      <span class="hb-w-mt-t" on:click={toggleopOpenMemberGid}>開啟GID頁面</span
      >
    </div>

    <hr />

    <div class="hb-sm-w-ma">
      <div class="hb-sm-w">
        <span class="hb-sm-w-nm">8</span>
      </div>
      <span class="hb-w-mt-t" on:click={toggleopOPGetWebviewRange}
        >開關天區</span
      >
    </div>

    <hr />

    <div class="hb-sm-w-ma">
      <div class="hb-sm-w">
        <span class="hb-sm-w-nm">9</span>
      </div>
      <span class="hb-w-mt-t" on:click={toggleopReload}>Location.reload</span>
    </div>

    <hr />

    <div class="hb-sm-w-ma">
      <div class="hb-sm-w">
        <span class="hb-sm-w-nm">10</span>
      </div>
      <span class="hb-w-mt-t" on:click={toggleopOpSetViewRang}
        >設定webview顯示的範圍與</span
      >
    </div>
    <div class="hb-sm-w-ma">
      <select id="pageType" bind:value={pageType}>
        {#each pageTypeSamples as pageTypeSample}
          <option value={pageTypeSample}>
            {pageTypeSample}
          </option>
        {/each}
      </select>
      <input
        id="title"
        placeholder="沒Title則填空"
        class="hb-w-mtts"
        on:change={(e) => {
          title = e.target.value;
        }}
      />
    </div>

    <hr />

    <div class="hb-sm-w-ma">
      <div class="hb-sm-w">
        <span class="hb-sm-w-nm">11</span>
      </div>
      <span class="hb-w-mt-t" on:click={toggleOpenWebview}>另開Webview</span>
      <div class="hb-sm-w">
        <span class="hb-sm-w-nm" on:click={()=> openMode = openMode === 'ON'? 'OFF' : 'ON'}>{openMode}</span>
      </div>
    </div>
    <div class="hb-w-mtts">
      <textarea class="hb-w-mtts-btn">{openUrl}</textarea>
    </div>

    <hr />

    <div class="hb-sm-w-ma">
      <div class="hb-sm-w">
        <span class="hb-sm-w-nm">12</span>
      </div>
      <span class="hb-w-mt-t" on:click={setLocationPermission}>設定定位權限</span>
    </div>
   
    <hr />

    <div class="hb-sm-w-ma">
      <div class="hb-sm-w">
        <span class="hb-sm-w-nm">13</span>
      </div>
      <span class="hb-w-mt-t" on:click={getLocation}>取得經緯度座標</span>
    </div>
    <div class="hb-sm-w-ma">
      <div class="hb-w-mtts">
        <textarea id="permission" class="hb-w-mtts-btn" placeholder="定位權限狀態"/>
      </div>
      <div class="hb-w-mtts">
        <textarea id="latitude" class="hb-w-mtts-btn" placeholder="latitude緯度"/>
      </div>
      <div class="hb-w-mtts">
        <textarea id="longitude" class="hb-w-mtts-btn" placeholder="longitude經度"/>
      </div>
    </div>

    <hr />

    <div class="hb-sm-w-ma">
      <div class="hb-sm-w">
        <span class="hb-sm-w-nm">14</span>
      </div>
      <span class="hb-w-mt-t" on:click={toggleOpenFeature}>另開功能</span>
    </div>
    <div class="hb-w-mtts">
      <textarea class="hb-w-mtts-btn">{featureId}</textarea>
    </div>

  
  </div>

</div>