<script>
  import { onMount } from 'svelte';
  import { QRCode } from '.';

  export let gid;
  export let count;

  let text = count < 10 ? `B${gid}0${count}` : `B${gid}${count}`

</script>

<QRCode {text} qrbox=125/>