<script>
    import { appSetting, memberInfo, checkFeature } from '../store/inapp-store' 
    import { fade, slide } from 'svelte/transition';
    import { quintOut } from 'svelte/easing';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let order

    let enableBUReturn = checkFeature($appSetting.enableBUReturn, $memberInfo.gid, $appSetting.guestList) 

    let expand = false
    let arr = new Array();
    
    order.cpmobiPayLog.forEach(el => {
        arr.push({name : el.name, count : el.count, type: el.type })
    })

    function showBarcode () {
       let enableStoreReturn = checkFeature($appSetting.enableStoreReturn, $memberInfo.gid, $appSetting.guestList) 
        if(enableStoreReturn === true) {
            location.href = `/refund-barcode?id=${order.id}&rtn-url=/order?f=need_refund`
        } else {
            dispatch('clickBtn', order);
        }
    }
</script>

<div class="record-wrap pb-3 record-sg-wrap">
    <div class="record-qtt">
        <div class="record-flip">
            <p class="record-qtt-numb">訂單內容</p>
            <span class="qtt-d-arrow{expand ? '' : '-reset'} arrow-img" on:click={()=> expand = !expand}></span>
        </div>

        <p class="record-qtt-numb">訂單編號：<span>{order.id}</span></p>
        <p class="record-qtt-numb">租借時間： <span>{order.rentTimeStr}</span></p>
        <p class="record-qtt-numb">訂單狀態：<span><strong>{order.orderStatus}</strong></span></p>

    </div>

{#if expand}
    <div class="record-panel" style="display: block; text-align:left"  transition:slide="{{delay: 100, duration: 600, easing: quintOut }}">
        
        <div class="record-st-panel">
            <div class="st-dh-al">
                <div class="st-dh-p-cnt">支付方式：<span class="st-dh-t">{(order.payType === 3 ? '到店支付' : '線上支付')}</span></div>
            </div>
        </div>
        {#if (enableBUReturn && order.redeemStatus === 1)}
        <div class="record-st-panel">
            <div class="st-dh-al">
                <div class="st-dh-p-cnt">租借通路：<span class="st-dh-t">{order.businessUnitName}</span></div>
            </div>
        </div>
        {/if}
        {#if (order.refundStatus !== '')}
        <div class="record-st-panel">
            <div class="st-dh-al">
                <div class="st-dh-p-cnt">退款狀態：<span class="st-dh-t">{order.refundStatus}</span></div>
            </div>
        </div>
        {/if}

        {#if (order.orderStatus !== '已取消')}
        <div class="record-st-panel">
            <div class="st-dh-al">
                <div class="st-dh-p-cnt">歸還期限： <span class="st-dh-t">{order.expiredTimeStr}</span></div>
            </div>
        </div>
        <div class="dash-hr-wrap">
            <div class="dash-hr"></div>
        </div>
        
        {/if}

        <div class="rcd-n-t">
            <p class="rcd-n-tl"><strong>商品內容</strong></p>
        </div>
        <div class="record-panel-clm">
            {#each arr as item, i }
            {#if (item.type <= 1)}
            <span class="record-mdm">
                <span class="record-mdm-g">{item.name}</span>
                <span class="record-mdm-n">
                    {#if (order.orderStatusShort === '部分歸還並退款' || order.orderStatusShort === '部分歸還並扣款' || 
                       (order.orderStatusShort === '待退款' && order.redeemCount > order.returnCount) )}
                    <span class="record-mdm-c">{item.count} </span>杯(已還<span class="record-mdm-c">{order.returnCount} </span>杯，未還<span class="record-mdm-c">{(order.redeemCount - order.returnCount)} </span> 杯)
                    {:else}
                    <span class="record-mdm-c">{item.count} </span>杯
                    {/if}
                </span>
            </span>
            {/if}
            {/each}
        </div>
    </div>
{/if}

{#if (order.orderStatusShort === '待退款' && order.useWallet === false )}
    <div class="d-flex p-3 bg-white" >
        <button type="button" data-toggle="modal" data-target="#produceCode"  on:click={showBarcode} 
            class="w-100 text-white border-0 btn" 
            style="background-image: linear-gradient(to bottom, #ffaf07, #ff9e07 23%, #ff6100 75%, #eb3903 100%), linear-gradient(to bottom, #ffefbf, #ed7622 99%);box-shadow: inset 0 -1px 3px 0 #ffbc3c;border-radius: 12px;padding: 0.4rem 0;">產生條碼退款</button>
    </div>
{/if}

</div>