<div class="srvmk">
    <p class="srvmk-ctn" style="color: #fd4c01">
        於門市租借循環杯(含杯蓋)時，先請門市人員確認庫存，並應搭配咖啡/茶現調飲品盛裝，使用完畢時應將循環杯及循環杯蓋一併歸還。
    </p>
    <br/>
    <p class="srvmk-ctn">
        一、為響應政府減塑政策，統一超商將於指定門市提供循環杯租借服務(下稱本服務)，透過此APP (OPENPOINT APP)租借本服務，當下您須預先支付預付款項新台幣50元或等值項目(下稱該筆金額)，如您未於7個日曆天內至指定地點完成歸還，該筆金額恕不退還，並視為您購買該循環杯，統奕包裝股份有限公司將依法開立電子發票。 
    </p>
    <br/>
    <p class="srvmk-ctn">
        二、本服務若您於7日內歸還，該筆預付款項將於7-10個日曆天內退還至您支付該筆金額所使用之行動支付或電子支付帳戶(如您以行動支付或電子支付帳戶綁定信用卡交易者，將退還至您所綁定之信用卡帳單)。
    </p>
    <br/>
    <p class="srvmk-ctn">
        三、登記本服務後，請您於72小時內至門市櫃台刷「兌換條碼」進行取杯，若超過取杯期限將視為您同意取消本服務，該筆預付款項會自動退還至您原帳戶。
    </p>
    <br/>
    <p class="srvmk-ctn">
        本服務期限為7日(不含租借當日)，相關訊息採用OPENPOINT APP推播通知，請至手機>設定>通知 將OPENPOINT APP通知設定為允許通知，以免遺漏通知訊息，如您未設定為允許通知，將影響您權利，敬請注意。
    </p>
</div>