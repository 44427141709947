<script>
    import { fade, slide } from 'svelte/transition';
    import { quintOut } from 'svelte/easing';
    import JsBarcode from 'jsbarcode'
    import ActionGroup from './ActionGroup.svelte'
    import OrderItem from './OrderItem.svelte'
    import { layoutOption, apiConfig, memberInfo, queryStr } from '../store/inapp-store' 
    import { Modal, AlertTimer, Loader } from '../components'
    import { alertMsg } from '../store/debug-alert'
    
    $layoutOption.navBackUrl = `/home${$queryStr}`
    $layoutOption.showCarousel = false
    $layoutOption.showCancel = false
    $layoutOption.showHome = true

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const f = urlParams.get('f')
    const s = urlParams.get('s')
    
    let filter = f === null ? 'all' : f
    let orderby = s === null ? 'createtime' : s
    let orders = []
    let filteredOrders = []
    let filteredOrdersCopy = []
    let result 

    let modal = {  message :'產生退款條碼失敗，請稍後進行重試，或洽詢客服專線 0809-090-711', title : '訂單退款', type: 'alert' } 
    let isOpen = false

    let alertModal = { message :'取得借杯資訊失敗，請稍後進行重試，或洽詢客服專線 0809-090-711', title: '記錄查詢', timeout: 5 };
    let enabled = true

    let loader = { content : '' };
    let isBlock = false;
    let openBarcode = false
    let refundAmount = 0

    fetch(`${$apiConfig.apiUrl}/order?code=${$apiConfig.apiKey}&gid=${$memberInfo.gid}`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(d => {
                console.log(d)
                if(d.returnCode === '0000') {
                    orders = d.info.filter(el=> (el.payStatus > 0 || el.payType === 3))
                    selectFilter(filter)
                } 
                enabled = false
        })
        .catch(e => {
            console.log(e)
        });

    /**
     * 依條件篩選查詢結果並更新畫面顯示的交易紀錄
     * @param f 篩選條件
     */
    function selectFilter(f) {
        filter = f
        filteredOrders =  orderby.indexOf('-desc') > 0 ? orders.slice().reverse() : orders.slice()

        // 待取杯 - 已付款但尚未刷POS取杯
        if(filter === 'redeem') { 
            filteredOrders = filteredOrders.filter(el=> el.orderStatusShort === '待取杯')
        }
        // 已取消 - 自行取消訂單 或 逾三日未取杯，系統自動取消訂單退款
        if(filter === 'cancel') { 
            filteredOrders = filteredOrders.filter(el=> el.orderStatusShort === '已取消')
        }
        // 待退款 - 已歸還但尚未刷POS退款
        if(filter === 'need_refund') { 
            filteredOrders = filteredOrders.filter(el=> el.orderStatusShort === '待退款')
        }
        // 零錢包待退款 - 已歸還但零錢包尚未完成退款
        if(filter === 'need_refund_wallet') { 
            filteredOrders = filteredOrders.filter(el=> el.orderStatusShort === '零錢包待退款')
        }
        // 已歸還 - 刷POS取杯七天內全部歸還
        if(filter === 'return') { 
            filteredOrders = filteredOrders.filter(el=> el.orderStatusShort === '已歸還')
        }
        // 未歸還 - 刷POS取杯七天內全部未歸還
        if(filter === 'no_return') { 
            filteredOrders = filteredOrders.filter(el=> el.orderStatusShort === '未歸還')
        }
        // 部分歸還並退款 - 刷POS取杯七天內部分歸還
        if(filter === 'partial_return') { 
            filteredOrders = filteredOrders.filter(el=> el.orderStatusShort === '部分歸還並退款')
        }
        // 未歸還並扣款 - 刷POS取杯，超過七天全部未歸還
        if(filter === 'expire') { 
            filteredOrders = filteredOrders.filter(el=> el.orderStatusShort === '未歸還並扣款')
        }       
        // 部分歸還並扣款 - 刷POS取杯，超過七天部分未歸還
        if(filter === 'partial_expire') { 
            filteredOrders = filteredOrders.filter(el=> el.orderStatusShort === '部分歸還並扣款')
        }

        filteredOrdersCopy = filteredOrders
        
    }
    
    /**
     * 依排序方式更新畫面顯示的交易紀錄
     * （選擇排序方式時會觸發此方法）
     * @param newOrderby 重新選定的排序方式
     */
    function refreshOrders(newOrderby) {
        orderby = newOrderby
        selectFilter(filter)
    }

    $:console.log('### result', result)

    /**
     * 顯示退款用三段條碼
     */
    function showBarcode (order) {
        console.log('### showBarcode', order)
        isBlock = true

        fetch(`${$apiConfig.apiUrl}/refund/barcode?code=${$apiConfig.apiKey}&id=${order.id}`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(d => {
            console.log(d)
            isBlock = false
            if(d.returnCode === '0000') {
                if(d.info.barcode1 === '') {
                    isOpen = true
                } else {
                    refundAmount = d.info.amount
                    openBarcode = true
                    setTimeout(function(){
                        let options = { fontsize: 6, margin: 3, height: 40, width: 0.8, format: "CODE39" }

                        JsBarcode(`#code1`, d.info.barcode1 , options);
                        JsBarcode(`#code2`, d.info.barcode2 , options);
                        JsBarcode(`#code3`, d.info.barcode3 , options);
                    
                    },1000)
                }
            } else {
                isOpen = true
            }
        })
        .catch(e => {
            console.log(e)
        });
    }

    /**
     * 關閉退款條碼
     */
    function closeBarcode() {
        console.log('### closeBarcode')
        openBarcode = false
        setTimeout(function() {
            location.href = '/order?f=' + filter + '&s=' + orderby
        }, 5000)
    }

</script>

<div>
    <ActionGroup {orderby} {filter} on:filter={e => selectFilter(e.detail)} on:select={e => refreshOrders(e.detail)}></ActionGroup>

    <div class="record-st-wrap mb-3">
    {#each filteredOrdersCopy as order(order)}
        <OrderItem {order} on:clickBtn={e => showBarcode(e.detail)}></OrderItem>
    {/each}
    </div>

    <div class="pdd-cr"></div>
</div>

{#if (isOpen)}
<Modal {modal} {isOpen} on:closeModal={e => isOpen = false}></Modal>
{/if}

<AlertTimer {alertModal} {enabled} on:closeModal={e => enabled = false}></AlertTimer>

<Loader {loader} {isBlock}></Loader>

{#if (openBarcode == true)}
<div class="modal fade px-3 show" id="produceCode" tabindex="-1" style="display: block;" aria-modal="true" role="dialog"
        transition:fade="{{delay: 100, duration: 100 }}">
    <div class="modal-dialog modal-dialog-centered m-auto" style="max-width: 327px;" role="document">
        <div class="modal-content" style="border: 1px solid var(--orange);">
            <div class="modal-body p-0">
                <button style="float:left;" type="button" class="close" on:click={closeBarcode}>
                    <span></span><span></span>
                </button>
                <div class="d-flex flex-column py-4 pb-5 pr-5 pl-2" style="gap: 1.25rem;color: #313131;">
                    <div class="title">
                        <h5 class="m-0">門市退款條碼</h5>
                    </div>
                    <div class="d-flex flex-column" style="gap: 1rem;">
                        <div class="d-flex justify-content-between">
                            <span>退款金額：</span><span style="font-weight: 500;color: #fd4c01;"> $ {refundAmount} </span>
                        </div>
                    </div>
                    <div class="w-100 text-center">
                        <div class="pb-3">
                            <svg id="code1"></svg>
                        </div>
                        <div class="pb-3">
                            <svg id="code2"></svg>
                        </div>
                        <div class="pb-3">
                            <svg id="code3"></svg>
                        </div>
                    </div>

                    <div>
                        <div class="small">
                            說明文字：
                        </div>
                        <div class="small">
                            <ol class="text-left">
                                <li>本條碼為現金退款專用，請至櫃台進行退款作業。</li>
                                <li>溫馨提醒您，退款條碼產出後十分鐘內有效，逾時請重新操作，謝謝。</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-backdrop fade show"></div>
{/if}