<script>
    import { layoutOption, memberInfo, appSetting, apiConfig, tempRedeemInfos } from '../store/inapp-store' 
    import { Modal, Loader } from '../components';
    import ReturnItem from './ReturnItem.svelte'

    const urlParams = new URLSearchParams(window.location.search)
    const rtnUrl = urlParams.get('rtn-url')

    $layoutOption.navTitle = '還杯'
    $layoutOption.navBackUrl =  (rtnUrl === null || rtnUrl === undefined) ?`/return-cup` : rtnUrl
    $layoutOption.showCarousel = false
    $layoutOption.showCancel = false
    $layoutOption.showHome = true

    let mode = 'scanner'
    let loader = { content : '還杯數量確認中...' };
    let isBlock = false;
    let modal = {  message :'產生還杯條碼失敗，請稍後進行重試，或洽詢客服專線 0809-090-711', title : '還杯', type: 'alert' } 
    let isOpen = false

    let needReturnOrders = []
    let needReturnCount = 0
  
    fetch(`${$apiConfig.apiUrl}/order?code=${$apiConfig.apiKey}&gid=${$memberInfo.gid}`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(d => {
                console.log(d)
                if(d.returnCode === '0000') {
                    needReturnOrders = d.info
                        .filter(el=> el.orderStatusShort === '未歸還' || el.orderStatusShort === '部分歸還並退款'
                             ||  el.orderStatusShort === '待退款' || el.orderStatusShort === '零錢包待退款')
                        .filter(el=> el.redeemCount > el.returnCount)
                        .filter(el=> el.payStatus > 0)
                    
                    // 可還杯數量加總
                    var arr = needReturnOrders.map(el => el.redeemCount - el.returnCount)
                    needReturnCount = arr.reduce((a,b)=>a+b)
                } 
        })
        .catch(e => {
            console.log(e)
        });

</script>
<div>
    <div class="px-3 pt-3" style="padding-bottom:0.75rem;">
        <h6 class="font-weight-bolder">跨品牌通路歸還</h6>
        <ul class="small text-left bc-p">
            <li>目前並非所有跨品牌通路門市都有提供循環杯借杯與還杯服務，請先查詢循環杯門市。</li>
            {#if ($appSetting.storeReturnMode == 'A')}
            <li>歸還時不識別杯子尺寸，依訂單時間採先借先還的方式計算歸還數量，因此僅需確認歸還杯子數量是否正確即可。</li>
            {/if}
            {#if ($appSetting.storeReturnMode == 'B')}
            <li>會依照訂單來計算歸還數量但不識別杯子尺寸，因此僅需確認歸還杯子數量是否正確即可。</li>
            {/if}            
        </ul>
    </div>

    <div class="px-3" style="padding-bottom:0.75rem;">
        <h6 class="font-weight-bolder">未歸還紀錄：共 <span style="color: red;">{needReturnCount}</span> 杯</h6>
    </div>

    <div class="record-out-wrap">
        {#each needReturnOrders as order(order)}
            <ReturnItem {order} {mode} ></ReturnItem>
        {/each}
    </div>

    <div class="pdd-cr"></div>
</div>

<Loader {loader} {isBlock}></Loader>

<Modal {modal} {isOpen}></Modal>