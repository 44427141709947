<script>  
    import { onMount } from 'svelte'
    import { layoutOption, tempRedeemInfos, mode, appSetting, apiConfig, memberInfo, queryStr, checkFeature } from '../store/inapp-store' 
    import { screenLight } from '../store/openpoint' 
    import { Modal, Loader,RefundQRCode } from '../components';
    import JsBarcode from 'jsbarcode'

    const urlParams = new URLSearchParams(window.location.search)
    const orderId = urlParams.get('id')
    const count = urlParams.get('count')
    const rtnUrl = urlParams.get('rtn-url')

    let returnType = $appSetting.storeReturnMode == 'A' ? 0 : rtnUrl.indexOf('/order') >= 0 ?  0: 1 
    let returnCount = returnType === 0 ? 0 : count

    $layoutOption.navBackUrl =  (rtnUrl === null || rtnUrl === undefined) ?`/home${$queryStr}` : rtnUrl
    $layoutOption.navTitle = returnType === 0 ? '_退款條碼' : '_還杯條碼'
    $layoutOption.showCarousel = false
    $layoutOption.showCancel = false
    $layoutOption.showHome = false

    let enableBarcode = checkFeature($appSetting.enableBarcode, $memberInfo.gid, $appSetting.guestList) 
    let enableQRCode = checkFeature($appSetting.enableQRCode, $memberInfo.gid, $appSetting.guestList) 
    enableBarcode = enableQRCode == false

    let enablePurseRefund = checkFeature($appSetting.enablePurseRefund, $memberInfo.gid, $appSetting.guestList) 

    let displayQRCode = !enableBarcode &&  enableQRCode
    let btnText = '切換QRCode'
    let barcodeData = null

    $:console.log('### enableBarcode' , enableBarcode)
    $:console.log('### enableQRCode' , enableQRCode)

    let modal = { 
        title : '提醒訊息',
        message : '還杯確認訊息尚在處理，請於1分鐘後再試。(或可隔天早上10點後再至「紀錄查詢」頁進行退款)' ,
        type: 'alert',
        remark: 'barcode_error'
    }
    let isOpen = false
    let loader = { content : '還杯狀態確認中...' }
    let isBlock = true;
    let openBarcode = false
    let refundAmount = 0

    onMount(()=> {
        setTimeout(getRefundBarcode, 3000)
    })

    /**
     * 取得退款條碼
     */
    function getRefundBarcode() {

        console.log('### returnType', returnType)

        fetch(`${$apiConfig.apiUrl}/refund/barcode?code=${$apiConfig.apiKey}&id=${orderId}&count=${returnCount}&type=${returnType}`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(d => {
            console.log(d)
            if(d.returnCode === '0000' && d.info.barcode1 !== '') {
                refundAmount = d.info.amount
                returnCount = d.info.count

                $layoutOption.navTitle =  returnType === 0 ?  '_退款條碼' : refundAmount === 0 ? '_還杯條碼' : '_還杯併退款條碼'
                openBarcode = true

                barcodeData = d

                setTimeout(function(){renderBarCode(barcodeData)}, 300)
            } else {
                isBlock = false
                isOpen = true
            }
        })
        .catch(e => {
            console.log(e)
        })
    }
    /**
     * 產生三段條碼
     */
    function renderBarCode(d){
        if(enableBarcode) {
            let options = { fontsize: 6, margin: 3, height: 40, width: 0.8, format: "CODE39" }
        
            JsBarcode(`#code1`, d.info.barcode1 , options);
            JsBarcode(`#code2`, d.info.barcode2 , options);
            JsBarcode(`#code3`, d.info.barcode3 , options);
        }
       
        isBlock = false
    } 
    /**
     * 按下確認後的動作
    */
    function onCloseModal() {
        setTimeout(function() {        
            location.href = $layoutOption.navBackUrl
        }, 1000)
    }

        /**
     * 顯示三段條碼或QRCode
     */
     function toggleQRCode() {
        displayQRCode = !displayQRCode 

        if(displayQRCode) {
            btnText = '切換三段條碼'
        } else {
            setTimeout(function(){renderBarCode(barcodeData)}, 100)
            btnText = '切換QRCode'
        }
    }

    try{
        screenLight(true)
    }catch(e){
        console.log(e)
    }
</script>
<style>
    .warning {
        color:#FF001F;
        font-size: 1.38rem;
    }
</style>

{#if (openBarcode)}
<div>
    <div class="mb-2 bg-white d-flex flex-column text-center align-items-center justify-content-center">
        {#if returnType === 0 }
        <img class="mb-3" width="100" src="/img/logoo-05.svg" alt="">
        {:else}
            <p class="pt-3 warning"> 請於有提供循環杯服務之門市，<br> 請門市回收 {returnCount} 個循環杯(含杯蓋)。</p>
        {/if}

        {#if displayQRCode === true}
        <div class="py-3">
            <div class="p-3" align="center">
                <RefundQRCode barcode1={barcodeData.info.barcode1} barcode2={barcodeData.info.barcode2} barcode3={barcodeData.info.barcode3}></RefundQRCode>
            </div>
        </div>
        {:else}
        <div class="pb-3">
            <svg id="code1"></svg>
        </div>
        <div class="pb-3">
            <svg id="code2"></svg>
        </div>
        <div class="pb-3">
            <svg id="code3"></svg>
        </div>
        {/if}

        {#if (enableBarcode && enableQRCode)}
        <div class="d-flex p-4 bg-white w-75" on:click={toggleQRCode}>
            <button type="button" data-toggle="modal" data-target="#produceCode" 
                class="w-100 text-white border-0 btn" 
                style="background-image: linear-gradient(to bottom, #ffaf07, #ff9e07 23%, #ff6100 75%, #eb3903 100%), linear-gradient(to bottom, #ffefbf, #ed7622 99%);box-shadow: inset 0 -1px 3px 0 #ffbc3c;border-radius: 12px;">
                {btnText}
            </button>
        </div>
        {/if}
    </div>

    <div class="bg-white d-flex flex-column text-center align-items-center justify-content-center" style="padding: 1.5625rem 1rem;">
        <div class='d-flex align-items-center justify-content-between w-100'>
            {#if refundAmount > 0 }
            <span style="color: #313131;">退款金額</span>
            <div style="color: red;font-size: 1.25rem;font-weight: 500;">
                <span>$</span>
                <span>{refundAmount}</span>
            </div>
            {:else}
            <span></span>
                {#if enablePurseRefund }
                    <span style="color: #313131;">還杯後依原支付或選擇方式進行退款 </span>
                {:else}
                    <span style="color: #313131;">還杯後系統依原支付方式進行退款</span>
                {/if}
            <span></span>
            {/if}
        </div>
    </div>

{#if (returnType == 0)}
    <div class="p-3">
        <div class="small">
            說明文字：
        </div>
        <div class="small">
            <ol class="text-left">
                <li>本條碼為現金退款專用，請至櫃台進行退款作業。</li>
                <li>溫馨提醒您，退款條碼產出後十分鐘內有效，逾時請重新操作，謝謝。</li>
            </ol>
        </div>
    </div>
{:else}
    {#if ($appSetting.storeReturnMode === 'A')}
    <div class="pdd-cr"></div>

    <div class="bc-p-cnt py-0 m-0 position-fixed w-100" style="bottom: 4.625rem;left: 50%;transform: translateX(-50%);">
        <p class="bc-p w-100 mb-0 h-auto">如使用線上支付借杯，請至「紀錄查詢」確認退款狀態。</p>
    </div>
    {/if}

    {#if ($appSetting.storeReturnMode === 'B')}
    <div class="p-3">
        <div class="small">
            說明文字：
        </div>
        <div class="small">
            {#if enablePurseRefund }
            <ol class="text-left">
                <li>到店支付借杯選擇「現金退款」，還杯後請於櫃台退回押金。</li>
                <li>到店支付借杯選擇「零錢包退款」，還杯後請至「紀錄查詢」確認退款狀態。</li>
                <li>線上支付借杯，還杯後請至「紀錄查詢」確認退款狀態。</li>
                <li>溫醒提醒您，條碼產出後十分鐘內有效，逾時請重新操作，謝謝。</li>
            </ol>
            {:else}
            <ol class="text-left">
                <li>本條碼為現金退款專用，請至櫃台進行退款作業。</li>
                <li>溫馨提醒您，退款條碼產出後十分鐘內有效，逾時請重新操作，謝謝。</li>
                <li>如使用線上支付借杯，請至「紀錄查詢」確認退款狀態。</li>
            </ol>
            {/if}
        </div>
    </div>
    {/if}
{/if}
    
</div>

<div class="pay-d-fx-btm">
    {#if (returnType == 0)}
    <button class="btn-orange" on:click={onCloseModal}><span>繼續退款</span></button>
    {:else}
    <button class="btn-orange" on:click={onCloseModal}><span>回上頁</span></button>
    {/if}
</div>
{/if}

<Modal {modal} {isOpen} on:closeModal={onCloseModal}></Modal>
<Loader {loader} {isBlock}></Loader>