<script>
  import { mode, storeInfos, locationInfo, mapLoaded } from '../store/inapp-store'
  import { openWebview } from '../store/openpoint'
  import { slide } from 'svelte/transition'
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let searchLocationInfo = {}
  let touchStartY = 0;
  let showLocationInfo = true
  let storeDistanceInfos = []
  let nearStoreInfos = []

  $:{
    storeDistanceInfos = []

    // 計算各門市座標到定位點的距離
    $storeInfos.forEach(function(element) {
      var storeDistanceInfo = element
      storeDistanceInfo.distance = calculateDistance(element.lat, element.lon, $locationInfo.latitude, $locationInfo.longitude)
      storeDistanceInfos.push(element)
    });

    // 依距離排序
    storeDistanceInfos.sort((a, b) => a.distance - b.distance);

    // 取前五筆
    nearStoreInfos = storeDistanceInfos.slice(0, 5);

    showLocationInfo = nearStoreInfos.length > 0

    console.log('nearStoreInfos=', nearStoreInfos)
  }


  /**
   * 計算兩個經緯度點之間的距離（單位：公里）
   * @param lat1
   * @param lon1
   * @param lat2
   * @param lon2
   */
  function calculateDistance(lat1, lon1, lat2, lon2) {
      const earthRadiusKm = 6371;

      const dLat = degreesToRadians(lat2 - lat1);
      const dLon = degreesToRadians(lon2 - lon1);

      const lat1Rad = degreesToRadians(lat1);
      const lat2Rad = degreesToRadians(lat2);

      const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1Rad) * Math.cos(lat2Rad); 
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      const distance = earthRadiusKm * c;

      return distance;
  }

  /**
   * 將角度轉換為弧度
   * @param degrees
   */
  function degreesToRadians(degrees) {
      return degrees * Math.PI / 180;
  }

  function handleTouchStart(event) {
    const touches = event.touches;
    console.log('start:' + touches.length)
    touchStartY = touches[0].clientY;
  }

  function handleTouchEnd(event) {
    const touches = event.changedTouches;
    console.log('end:', touches.length)
    const touchEndY = touches[0].clientY;
    const deltaY = touchEndY - touchStartY;

    showLocationInfo = deltaY <= 0
  }

  function toggleLocationInfo() {
    showLocationInfo = !showLocationInfo
  }
  
  /**
   * 點選門市資訊欄導航按鈕後觸發
   */
   function direct(location) {
    var directUrl = `https://www.google.com.tw/maps/dir//${location.lat},${location.lon}/@${location.lat},${location.lon},16z`
    if ($mode === 'app') {
        openWebview(false, directUrl)
    } else {
        location.href = directUrl
    }
  }

  /**
   * 點擊查詢結果的門市後會觸發此事件
   */
  function changeLocation(location) {
      // console.log('location = ', location) 
      searchLocationInfo = location
      dispatch('changeLocation', location);
  }

</script>
<style>
  #near-location-info {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 96;
  }

  small {
    color: #7a7a7a;
  }

  #location-info-hide {
    position: fixed;
    bottom: -8px;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 96;
  }

  hr {
    margin: auto;
    border: 0;
    height: 2px;
    width: 100px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0));
  }

  .lease {
    border: 0;
    box-shadow: 0 0 0 transparent;
  }
</style>

{#if showLocationInfo && $mapLoaded}
  <div id="near-location-info" class="pb-0" transition:slide on:touchstart={handleTouchStart} on:touchend={handleTouchEnd}>
    <hr on:touchstart={handleTouchStart} on:touchend={handleTouchEnd}>
    <div class="show-box box-1 search-result pt-4">
        <div class="lease mb-0 p-0">
          {#each nearStoreInfos as item (item)}
            <div class="lcontent border-bottom">
              <div class="lc-label-ol">
                  <label for="lc-a">
                      <div class="lc-ol pt-1" style="width: 78vw">
                          <h4>
                            {item.storeName} ({item.storeId})
                            <small>{item.tel}</small>
                          </h4>
                          <p>
                            {item.address}  <small>(距離 {item.distance.toFixed(1)} km)</small>
                          </p>
                      </div>
                      <img class="i-img" src="/img/icon-directions.svg" style="width: 40px" alt="" on:click={()=> direct(item)}>
                  </label>
                </div>
            </div>
         {/each}
        </div>
    </div>
  </div>
{:else}
  <div id="location-info-hide" transition:slide on:touchstart={handleTouchStart} on:touchend={handleTouchEnd}>
    <hr on:click={toggleLocationInfo}>
  </div>
{/if}