<script>
    import { appSetting } from '../store/inapp-store' 
    import { slide } from 'svelte/transition';
    import { quintOut } from 'svelte/easing';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let order
    export let mode = 'pos'

    let expand = false
    let arr = new Array();
    let product = ''
    order.cpmobiPayLog.forEach(el => {
        arr.push({name : el.name, count : el.count, type: el.type })
        if(el.type <= 1 ){
            product = el.name
        }
    })

    let maxAmount = order.redeemCount - order.returnCount
    let minAmount = 1
    let editingCount = maxAmount

    function minusOne() {
        if(editingCount - 1 >= minAmount) { 
            editingCount -= 1 
        }
    }
    function plusOne() {
        if(editingCount + 1 <= maxAmount) {
            editingCount += 1   
        }
    }
</script>

<div class="record-wrap">
    <div class="record-qtt">
        <div class="record-flip">
            <p class="record-qtt-numb font-weight-bolder">訂單內容</p>
            <span class="qtt-d-arrow{expand ? '' : '-reset'} arrow-img" on:click={()=> expand = !expand}></span>
        </div>

        <p class="record-qtt-numb">訂單編號：<span>{order.id}</span></p>
        <p class="record-qtt-numb">支付方式： <span>{(order.payType === 3 ? '到店支付' : '線上支付')}</span></p>
        <p class="record-qtt-numb">商品內容：<span>{product} <span style="color:#FF001F;">{order.totalCount}</span> 杯</span></p>
        <p class="record-qtt-numb">未還杯數： <span><span>{order.redeemCount - order.returnCount}</span> 杯(已還 <span>{order.returnCount}</span> 杯) </span></p>
        <p class="record-qtt-numb" style="color:#FF001F;">歸還期限：<span>{order.expiredTimeStr}</span></p>

        {#if mode === 'pos'}  
        <div class="record-flip">
            <p class="record-qtt-numb font-weight-bolder">設定還杯數量</p>
            <div class="lease border-0 shadow-none p-0 m-0">
                <div class="lcontent">
                    <div class="nice-number">
                        <div class="nice-number-button qtyminus" on:click={minusOne} 
                            style={editingCount === 0 ? '' : 'border: solid 1px #fcd04c;background-image: linear-gradient(to bottom, #fda544 3%, #f06e37 99%),linear-gradient(to bottom, #1dbee4, #00a0fd);'}><span></span></div>
                        <input id="returnNum" class="px-3" style="max-width: 5rem;" type="number"
                            name="num1" onkeypress="isInputNumber(event)" value="{editingCount}" min="1" max="99"
                            disabled>
                        <div class="nice-number-button qtyplus" on:click={plusOne}><span></span></div>
                    </div>
                </div>
            </div>
        </div>

        {/if}
    </div>

    {#if expand}
    <div class="record-panel" style="display: block" transition:slide="{{delay: 100, duration: 600, easing: quintOut }}">
        <div class="record-panel-clm">
            {#each arr as item, i }
            {#if (item.type <= 1)}
            <span class="record-mdm">
                <span class="record-mdm-g">{item.name}</span>
                <span class="record-mdm-n">
                    <span class="record-mdm-c">{item.count} </span>杯
                </span>
            </span>
            {/if}
            {/each}

            <div class="dash-hr"></div>

            <span class="record-s-n">
                共<span class="record-s-c">{order.totalCount}</span>杯
            </span>

        </div>
    </div>
    {/if}

    {#if $appSetting.storeReturnMode == 'B' && mode === 'pos'}
    <div class="d-flex px-3 pb-3 bg-white">
        <button type="button" data-toggle="modal" data-target="#produceCode"
            class="w-100 text-white border-0 btn" 
            style="background-image: linear-gradient(to bottom, #ffaf07, #ff9e07 23%, #ff6100 75%, #eb3903 100%), linear-gradient(to bottom, #ffefbf, #ed7622 99%);box-shadow: inset 0 -1px 3px 0 #ffbc3c;border-radius: 12px;padding: 0.4rem 0;"
            on:click={()=> location.href = `/refund-barcode?id=${order.id}&count=${editingCount}&rtn-url=/return-via-pos`}>
            {#if (order.payType === 3 && order.useWallet === false)}
            產生還杯併退款條碼
            {:else}
            產生還杯條碼
            {/if}
        </button>
    </div>
    {/if}

    {#if mode === 'scanner'} 
    <div class="d-flex px-3 pb-3 bg-white">
        <button type="button" data-toggle="modal" data-target="#produceCode"
            class="w-100 text-white border-0 btn" 
            style="background-image: linear-gradient(to bottom, #ffaf07, #ff9e07 23%, #ff6100 75%, #eb3903 100%), linear-gradient(to bottom, #ffefbf, #ed7622 99%);box-shadow: inset 0 -1px 3px 0 #ffbc3c;border-radius: 12px;padding: 0.4rem 0;"
            on:click={()=> location.href = `/return-scan-cup?id=${order.id}&c=${maxAmount}`}>
            掃碼還杯
        </button>
    </div>
    {/if}
</div>