{#if (appConfigJS !== '')}
<LibLoader url={appConfigJS} on:loaded="{onLoaded}" />
{/if}
<script>
  import API from '../store/config';
  import { memberInfo, layoutOption, queryStr, apiConfig, appVer, mode, skipInfo, appSetting } from '../store/inapp-store' 
  import { screenLight } from '../store/openpoint' 
  import { Modal, Loader, Warning, LibLoader } from '../components';

  // 開發測試用網址: http://localhost:5000/home?v=1.0.0&ct=V0Nz2P3T%2FCuLsOO1ZZ%2BPwPTu87b3BLvqyskPqhL1mdM%3D&mode=dev
 
  let appConfigJS = '' //-- 取得系統開放設定JS所在位置
  let validate = false;
  let loader = { content: '服務確認中...' };
  let isBlock = true;
  let warning = { message: [], small: [] };
  let needRefundCount = 0;

  $layoutOption = {
    navTitle: '循環杯',
    navBackUrl: '',
    showCarousel: validate,
    showBanner: false,
    showHome: false,
    showCancel: false,
  };

  const queryString = window.location.search
  $queryStr = queryString

  const urlParams = new URLSearchParams(queryString)
  const cipherText = urlParams.get('ct')
  const appVersion = urlParams.get('v')
  $appVer = appVersion

  const appMode = urlParams.get('mode');
  $mode = appMode !== 'dev' && appMode !== 'demo' ? 'app' : appMode;
  $skipInfo = appMode === 'dev' ? false : $skipInfo;

  $:console.log('### $mode', $mode)

  if ($mode !== 'dev') {
    $apiConfig.storageEndpoint = ''
    fetchAPIConfig();
  }

  $:console.log('### $apiConfig', $apiConfig)

  if ($apiConfig.storageEndpoint) {
    appConfigJS = `${$apiConfig.storageEndpoint}/script-container/app-config.js?d=${new Date().getTime()}`;
  }

  $:console.log('### appConfigJS',appConfigJS)
  $:console.log('### API', API)

  function fetchAPIConfig() {
    fetch(`${API}/apiUrl`)
      .then(response => response.json())
      .then(d => {
        console.log(d);
        $apiConfig = d;
        appConfigJS = `${$apiConfig.storageEndpoint}/script-container/app-config.js?d=${new Date().getTime()}`;
      });
  }
  
  /**
   * 載入系統開放設定後會觸發此事件
   */ 
  function onLoaded() {
    if (!appConfig) return false;

    console.log('onLoaded', appConfig);
    $appSetting = appConfig;
    prepareMemberGID(); // 取得會員GID
    render();           // 渲染服務入口頁面
    queryOrder();       // 查詢訂單
  }

  /**
   * 渲染服務入口頁面
   */
  function render() {
    validate = $appSetting.isOpen && !$appSetting.isPause && $memberInfo.gid

    const isGuest = includedInList(appConfig.guestList)
    const isInBlock = includedInList(appConfig.blockList)

    console.log('is guest', isGuest)
    console.log('in block', isInBlock)
    
    if (!$memberInfo.gid) {
      setWarning(['取得會員資訊發生錯誤', '請稍候再試'])
      validate = false;
    } else if(isGuest) {
      validate = true;
    } else if (!appConfig.isOpen && !isGuest) {
      setWarning(['本服務尚未開放使用', '敬請期待'])
      validate = false;
    } else if (isInBlock) {
      setWarning(['本服務尚未開放使用', '敬請期待'])
      validate = false;
    } else if (appConfig.isOpen && !isGuest) {
      validate = !handlePausePeriod()
    }

    if (validate) {
      $layoutOption.showCarousel = true
    }

    isBlock = false
  }

  $:console.log('### validate', validate)

  /**
   * 判斷目前是否處於服務暫停期間
  */
  function handlePausePeriod() {
    const { beginTime, endTime } = appConfig.pauseInterval;
    const inPause = new Date(beginTime) < new Date() && new Date(endTime) > new Date()

    console.log('in pause=', inPause)

    if (inPause) {
      setWarning([`${beginTime} ~ ${endTime.substr(11, 5)}`, '系統維護，服務暫停，敬請見諒'])
    }

    return inPause
  }

  /**
   * 檢查會員GID是否存在於白名單/黑名單
  */
  function includedInList(list) {
    return list.includes($memberInfo.gid)
  }

  function setWarning(messages) {
    console.log('messages=', messages)
    warning = { message: messages, small: [] }
  }

  /**
   * 取得會員GID
   */
  function prepareMemberGID() {
    fetch(`${$apiConfig.apiUrl}/decrypt?code=${$apiConfig.apiKey}`, {
      method: 'POST',
      body:JSON.stringify({cipherText : cipherText, appVersion : appVersion })
    })
    .then(response => response.json())
    .then(d => { 
          // console.log(d) 
          if(d.gid === '') {
              //--如果無法解析入口參數，就引導至不提供服務畫面
              warning =  { message : ['取得會員資訊發生錯誤', '請稍候再試']  , small: [] }
          } 
          //--如果會員GID與先前登入的GID不同，就一併清除記憶的手機號碼，以便後續能夠重新取得GID所對應的手機號碼
          if($memberInfo.gid !== d.gid) {
            $memberInfo.gid = d.gid
            $memberInfo.foen = '' 

            render()
          }
        });
  }

  $:console.log('### gid', $memberInfo.gid)

  /**
   * 查詢訂單
   */
  function queryOrder() {
    fetch(`${$apiConfig.apiUrl}/order?code=${$apiConfig.apiKey}&gid=${$memberInfo.gid}`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(d => {
              console.log(d)
              if(d.returnCode === '0000') {
                  let orders = d.info.filter(el=> el.payStatus > 0).filter(el=> el.orderStatusShort === '待退款')
                  needRefundCount = orders.length
              } 
        })
        .catch(e => {
            console.log(e)
        });
  }

  $:console.log('### needRefundCount', needRefundCount)

  try{
    screenLight(false)
  }catch(e){
    console.log(e)
  }

</script>

{#if (validate)}
  <div class="content-wrap">
    <div class="service">
        <span class="srv-p">循環杯服務</span>
        <hr>
    </div>
    <div class="mid-4">
        <div class="mid-4-icon">
            <btn class="item"  onclick="location.href='/op-auth'">
                <img class="hm-icon" src="/img/atom-button-menu-sub-menu-cup-rent.svg"
                    alt="">
                <span>登記借杯</span>
            </btn>
        </div>
        <div class="mid-4-icon">
            <btn class="item" onclick="location.href='/redeem'">
                <img class="hm-icon" src="/img/atom-button-menu-sub-menu-cup-take.svg"
                    alt="">
                <span>兌換取杯</span>
            </btn>
        </div>
        <div class="mid-4-icon">
            <btn class="item" onclick="location.href='/return-cup'">
                <img class="hm-icon" src="/img/atom-button-menu-sub-menu-cup-return-copy.svg" alt="">
                <span>還杯</span>
            </btn>
        </div>
        <div class="mid-4-icon">
            <btn class="item" onclick="location.href='/order?f=all&s=createtime'">
                <img class="hm-icon" src="/img/atom-button-menu-sub-menu-cup-receipt.svg"
                    alt="">
                <span>紀錄查詢</span>
            </btn>
        </div>
    </div>

    {#if (needRefundCount > 0)}
    <a href="/order?f=need_refund&s=createtime" class="error-wrap">
      <div class="error">
          <span>門市待退款{needRefundCount}筆，按下後進行門市退款</span>
          <img src="/img/atom-button-glyphs-buttons-direction-small-right-btn-arrow-right-w-n.svg" alt="">
      </div>
     </a>
    {/if}
  
    <div class="bd-hr-wrap">
        <hr>
    </div>
  
    <btn class="inquiry" onclick="location.href='/store?opbrowser=1'">
        <img class="i-img" src="/img/btn-lovefood-location-a.svg" alt="">
        <span class="i-p">查詢循環杯門市</span>
    </btn>
  
    <div class="iq-sm">
        <p>
          目前並非全台統一超商門市皆有循環杯租借服務，可點選上方查詢目前循環杯借杯/還杯門市服務據點。
          <br>點選<a href="/info" class="iq-sm-s-c"><span>租借說明</span></a>了解租借方式
          <br>循環杯客服專線 0809-090-711
        </p>
    </div>
  </div>
{:else}
  <Warning {warning}></Warning>
{/if}

<Loader {loader} {isBlock}></Loader>

<input type="hidden" id="sakura" value="off">

