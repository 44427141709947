<script>
  import { layoutOption } from '../store/inapp-store' 
  import { Warning } from '../components';

  $layoutOption.showCarousel = false
  $layoutOption.showCancel = false

  let warning = { message : ['404 OPEN小將不見了', '請稍候再試']  , small: [] }

</script>

<Warning {warning}></Warning>