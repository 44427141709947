<script>
    import { layoutOption } from '../store/inapp-store' 
    import { Modal } from '../components';

    $layoutOption.navTitle = '取消兌換'
    $layoutOption.showCarousel = false
    $layoutOption.showCancel = false
    $layoutOption.showHome = false
    
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('code')
    
    let modal = { 
        title : code === '0000' ? '退款成功' : '退款失敗',
        message : code === '0000' ? `已成功提出退款申請` : `錯誤代碼：${code}，請洽客服專線 0809-090-711`,
        type: 'alert'
    }

    let isOpen = true

</script>

<Modal {modal} {isOpen} on:closeModal={e => location.href = '/redeem'}></Modal>
