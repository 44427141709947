<script>
    import { layoutOption, newOrderId, apiConfig, mode, memberInfo, queryStr } from '../store/inapp-store' 
    import { Modal, Loader } from '../components';
    import { onMount } from 'svelte';
    import { onlinePayment } from '../store/openpoint' 

    $layoutOption.showBanner = false
    $layoutOption.showCarousel = false
    
    let payResult = {
        status: '',
        data: ''
    } 

    let modal = { 
        title : '登記借杯',
        message : '交易取消',
        type: 'alert'
    }

    let isOpen = false
    let loader = { content : '線上支付準備中...' } 
    let isBlock = true

    onMount(() =>{
        if($mode === 'app') {
            try {
                startOnlinePayment()
            }catch(e) {
                modal.message  = e.msg
                isBlock = false
                isOpen = true
            }

            let timer
            if(document.getElementById('payStatus') !== null) {
                timer = setInterval(function(){
                    var payStatus = document.getElementById('payStatus').value;
                    console.log('payStatus', payStatus)
                    if(payStatus !== '') {
                        payResult.status = payStatus
                        clearInterval(timer)

                        // 線上支付回傳交易結果
                        // Y:交易授權成功(平台端需向聚合平台詢問結果) 
                        // N:交易授權錯誤(平台端需向聚合平台詢問結果) 
                        // C:交易取消 (使用者自行點選流程中畫面上左上的"X")
                        // T:交易授權逾時timeout)(平台端需向聚合平台詢問結果) 
                        // OF:向OPW取得預約訂單失敗
                        // M:APP系統維護
                        if(payResult.status === 'Y') { 
                            var payData = document.getElementById('payData').value;
                            payResult.data = payData
                            nextStep()
                            return false
                        } else {
                            if(payResult.status === 'C')  {
                                modal.message  = '交易取消'
                            } else {
                                modal.message  = '發生錯誤，請先查詢紀錄查詢，以確認是否訂單成立，或是稍後進行重試'
                            }
                            isBlock = false
                            isOpen = true
                        }
                    }
                },1000)
            }
        } else {
            loader.content = '模擬OP錢包交易成功情境...' 
            isBlock = true
            setTimeout(doMockOnlinePayment, 1000)
        } 
    })

    /**
     * 開啟線上支付流程
     */
    function startOnlinePayment() {
        // 取得線上支付所需參數
        fetch(`${$apiConfig.apiUrl}/payment/data?code=${$apiConfig.apiKey}&id=${$newOrderId}&gid=${$memberInfo.gid}`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(d => {
                console.log(d)                    
                loader.content = ''
                if(d.returnCode === '0000') {
                    // 呼叫JS線上支付介面
                    onlinePayment(d.info.source, d.info.data)
                } else {
                    isOpen = true
                }
            })
    }

    /**
     * 模擬線上支付交易成功
     */
    function doMockOnlinePayment() {
        fetch(`${$apiConfig.apiUrl}/mock/payment?code=${$apiConfig.apiKey}`, {
            method: 'POST',
            body:JSON.stringify({id : $newOrderId })
        })
        .then(response => response.json())
        .then(d => {
            console.log(d)
            nextStep()
        });
    }

    /**
     * 前往交易完成頁
     */
    function nextStep() {
        location.href = 'register-result'
    }

</script>

<input type="hidden" id="payStatus" >
<input type="hidden" id="payData" >

<Modal {modal} {isOpen} on:closeModal={e => location.href = `/home${$queryStr}` }></Modal>
<Loader {loader} {isBlock}></Loader>