<script>  
    import { onMount } from 'svelte'
    import { layoutOption, tempRedeemInfos, mode, apiConfig, memberInfo, appSetting, checkFeature } from '../store/inapp-store' 
    import { openByFeatureId } from '../store/openpoint'
    import { screenLight } from '../store/openpoint' 
    import { Modal, Loader, MMKQRCode } from '../components';
    import { Swiper, SwiperSlide } from 'swiper/svelte'
    import { Navigation, Pagination } from 'swiper'
    import 'swiper/css'
    import 'swiper/css/navigation'
    import 'swiper/css/pagination'
    import JsBarcode from 'jsbarcode'


    $layoutOption.navBackUrl = `/redeem-via-pos`
    $layoutOption.navTitle = ''
    $layoutOption.showCarousel = false
    $layoutOption.showCancel = false
    $layoutOption.showHome = false

    let enableVoucher = checkFeature($appSetting.enableVoucher, $memberInfo.gid, $appSetting.guestList) 
    let enablePaymentCode = checkFeature($appSetting.enablePaymentCode, $memberInfo.gid, $appSetting.guestList) 
    let enableBarcode = checkFeature($appSetting.enableBarcode, $memberInfo.gid, $appSetting.guestList) 
    let enableQRCode = checkFeature($appSetting.enableQRCode, $memberInfo.gid, $appSetting.guestList) 
    enableBarcode = enableQRCode == false

    let displayQRCode = !enableBarcode &&  enableQRCode
    let btnText = '切換QRCode'

    $:console.log('### enableVoucher' , enableVoucher)
    $:console.log('### enablePaymentCode' , enablePaymentCode)

    let modal = { 
        title : '兌換取杯',
        message : '產生兌換取杯條碼失敗，即將取消租借並且進行退款，歡迎再次使用本服務。' ,
        type: 'alert',
        remark: 'barcode_error'
    }
    let isOpen = false
    let loader = { content : '取消作業處理中...' };
    let isBlock = false;

    
    let clickCount = 0
    let invalidRedeemInfos = []

    $:console.log('tempRedeemInfos', $tempRedeemInfos)

    onMount(()=> {
        
        // 如果有空白條碼就出現提示訊息，並且於按下確認後取消兌換
        invalidRedeemInfos = $tempRedeemInfos.filter(el=> el.barcode1 === '')
        console.log('invalidRedeemInfos', invalidRedeemInfos)
        if(invalidRedeemInfos.length > 0) {
            isOpen = true
        }

        if(enableBarcode) {
            // 產生三段條碼
            renderBarcode()
        }

        // 打亮螢幕（僅在App內開啟頁面時生效）
        screenLight(true)
    })

    /**
     * 產生三段條碼
     */
    function renderBarcode() {
        let options = { fontsize: 10, margin: 3, height: 40, width: 0.75, format: "CODE39" }
        $tempRedeemInfos.forEach(el => {
            JsBarcode(`#code${el.id}b1`, el.barcode1 , options);
            JsBarcode(`#code${el.id}b2`, el.barcode2 , options);
            JsBarcode(`#code${el.id}b3`, el.barcode3 , options);
        }); 
    }

    /**
     * 模擬取杯功能
     * -- 限定只有在 dev 跟 demo 模式下，才可以使用模擬取杯功能
     */
    function mockRedeem() {
        if($mode !== 'dev' && $mode !== 'demo') { return false }
        console.log('click', clickCount)
        clickCount += 1
        if(clickCount === 10) {
            $tempRedeemInfos.forEach(el => {
                fetch(`${$apiConfig.apiUrl}/mock/redeem?code=${$apiConfig.apiKey}`, {
                    method: 'POST',
                    body:JSON.stringify({ id : el.id ,seq : 1})
                })
                .then(response => response.json())
                .then(d => { 
                        console.log(d) 
                        modal = { 
                            title : '兌換取杯',
                            message : '模擬兌換取杯成功',
                            type: 'alert',
                            remark: 'redeem_success'
                        }
                        isOpen = true
                    });
            });
        }
    }

    /**
     * 按下確認後的動作
    */
    function onCloseModal() {
        if(modal.remark === 'redeem_success') {
            location.href = $layoutOption.navBackUrl
        } 

        if(modal.remark === 'barcode_error') {
            isOpen = false
            isBlock =  true

            let param = {
                gid : $memberInfo.gid,
                redeemInfo : invalidRedeemInfos
            }
            // 呼叫取消兌換API
            fetch(`${$apiConfig.apiUrl}/cancel?code=${$apiConfig.apiKey}`, {
                method: 'POST',
                body:JSON.stringify(param)
            })
            .then(response => response.json())
            .then(d => {
                console.log(d)
                isBlock = false;
                location.href = $layoutOption.navBackUrl
            });
        }
    }

    /**
     * 顯示三段條碼或QRCode
     */
    function toggleQRCode() {
        displayQRCode = !displayQRCode 

        if(displayQRCode) {
            btnText = '切換三段條碼'
        } else {
            setTimeout(renderBarcode, 100)
            btnText = '切換QRCode'
        }
    }

    $:console.log('displayQRCode=', displayQRCode)

    /**
     * 開啟功能頁
     * featureId: 提貨券=IDP01B01P2S1, 付款碼=
     */
    function toggleOpenFeature(featureId) {
        try {
            // console.log('featureId=', featureId)
            openByFeatureId(featureId, '');
        } catch (e) {
            alert(e.msg);
        }
    }
</script>

<style>
.popcard-content-text {
    width: 100%;
    text-align: center;
    /* padding-bottom: 40px; */
}
.popcard-content-text .btn-white-two button {
    width: 100%;
    margin: 9px 0 18px 0;
    font-size: 1rem;
}

.popcard-addbt-content button {
    width: 144px;
    font-size: 1rem;
    margin-top: 0;
}

.popcard-content-text  button {
    color: #fd4c01;
    height: 36px;
    border-radius: 13px;
    border: solid 1px #cccccc;
    background-image: linear-gradient(to bottom, #f6f6f6, #e9e9e9);
}

</style>

<div>
    <Swiper 
        centeredSlides={true} 
        slidesPerView={1.2} 
        loop={false} 
        modules={[Navigation,Pagination]} 
        pagination={{
            el: ".swp-pag-nm",
            type: "fraction",
        }} 
        navigation={{
            nextEl: ".swp-btn-n",
            prevEl: ".swp-btn-b",
        }}
        class="swiper-container barcode-container" >

        {#each $tempRedeemInfos as item }
        <SwiperSlide class="barcode-swp-slider">
            <div class="swp-cnt">
                <btn on:click={()=> location.href = $layoutOption.navBackUrl }>
                    <img class="barcode-close" src="/img/atom-button-glyphs-buttons-close-btn-close-n.svg" alt="">
                </btn>

                {#if item.size === '10L'}
                <p class="barcode-t">{item.name}</p>
                {:else}
                <p class="barcode-t">{item.name} {item.count} 杯</p>
                {/if}

                {#if (item.amount > 0)}
                <h1 class="pt-1" style="color:#FF001F">僅限現金支付</h1>
                {/if}

                {#if displayQRCode === true}
                <div class="{enablePaymentCode ? 'py-0' : 'py-3'}">
                    <div class="{enablePaymentCode ? 'p-3' : 'p-5'}" align="center">
                        <MMKQRCode barcode1={item.barcode1} barcode2={item.barcode2} barcode3={item.barcode3}></MMKQRCode>
                    </div>
                </div>
                {:else}
                <div on:click={mockRedeem}>
                    <p><svg id="code{item.id}b1" height="68px"></svg></p>
                    <p><svg id="code{item.id}b2" height="68px"></svg></p>
                    <p><svg id="code{item.id}b3" height="68px"></svg></p>
                </div>
                {/if}

                {#if (enableBarcode && enableQRCode)}
                <div class="d-flex p-3 bg-white" on:click={toggleQRCode}>
                    <button type="button" data-toggle="modal" data-target="#produceCode" 
                        class="w-100 text-white border-0 btn" 
                        style="background-image: linear-gradient(to bottom, #ffaf07, #ff9e07 23%, #ff6100 75%, #eb3903 100%), linear-gradient(to bottom, #ffefbf, #ed7622 99%);box-shadow: inset 0 -1px 3px 0 #ffbc3c;border-radius: 12px;padding: 0.4rem 0;">
                        {btnText}
                    </button>
                </div>
                {/if}

                <div class="w-100 position-relative">
                    {#if (item.amount == 0)}
                    <div class="label pay w-100 m-2" style="max-width: 65px;top:0;left:unset;transform: translate(0);font-size: 1rem;">
                        已付款
                    </div>
                    {:else}
                    <span class="label unpay w-100 mx-4 position-relative" style="max-width: 65px;top:0;left:unset;transform: translate(0);font-size: 1rem;">
                        未付款
                    </span>
                    {/if}
                    
                    <img src="/img/barcode-cup-{item.size}.jpg" alt="" width="247">
                </div>
 
                {#if (item.amount > 0)}
                <div class="d-flex justify-content-center w-100 mb-1 align-items-center" style="gap: 1rem;font-weight: 500;">
                    <span>到店付款</span>
                    <span class="d-flex justify-content-center align-items-center" style="gap: 0.4rem;color: var(--orange);"> <img src="/img/atom-icon-currency-ic-ntd-large.svg" width="25px" alt=""> {item.amount} 元</span>
                </div>
                {/if}

                <p class="barcode-dh" style="color:#FF001F">兌換期限：{item.dueTimeStr}<br>超過取杯期限則自動進行{ item.amount === 0 ? '退款' : '取消' }</p>
            
            </div>     
        </SwiperSlide>
        {/each}
    </Swiper>

    <div class="bc-p-cnt">
        <div align="center" style="color:#FF001F;font-size:19px;width:288px">
            【本券只能兌換一次】
            <br>請門市人員先確認是否有循環杯再完成結帳作業 條碼一經刷讀兌換後，即無法使用，無法退/換貨。
        </div>
    </div>

    {#if (!enablePaymentCode && enableVoucher)}
        <div class="d-flex pb-3 px-5" >
            <button type="button" data-toggle="modal" data-target="#produceCode"
                class="w-100 text-white border-0 btn" 
                style="background-image: linear-gradient(to bottom, #ffaf07, #ff9e07 23%, #ff6100 75%, #eb3903 100%), linear-gradient(to bottom, #ffefbf, #ed7622 99%);box-shadow: inset 0 -1px 3px 0 #ffbc3c;border-radius: 12px;padding: 0.4rem 0;"
                on:click={()=> toggleOpenFeature('IDP01B01P2S1')}>
                提貨券
            </button>
        </div>
    {/if}

    {#if enablePaymentCode}
    <div class="popcard-content-text popcard-addbt-content">
        <button on:click={()=> toggleOpenFeature('OPWAAB00P0S0')} style="margin-right: 3px;">
            付款碼
        </button>
        <button on:click={()=> toggleOpenFeature('IDP01B01P2S1')} style="margin-left: 3px;">
            提貨券
        </button>
    </div>
{/if}
</div>

<div class="btn-swp-wrap">
    <div class="swiper-button-next swp-btn-n "></div>
    <div class="swiper-button-prev swp-btn-b"></div>
    <div class="swiper-pagination swp-pag-nm"></div>
</div>

<Modal {modal} {isOpen} on:closeModal={onCloseModal}></Modal>
<Loader {loader} {isBlock}></Loader>