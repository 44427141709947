<script>
  import { mode, mapLoaded } from '../store/inapp-store'
  import { openWebview } from '../store/openpoint'
  import { slide } from 'svelte/transition'
  import LocationNearStore from './LocationNearStore.svelte';

  export let searchLocationInfo = {}
  let touchStartY = 0;
  let showLocationInfo = true

  $:{
    searchLocationInfo = searchLocationInfo
    showLocationInfo = true
  }

  $:console.log('searchLocationInfo', searchLocationInfo)

  function handleTouchStart(event) {
    const touches = event.touches;
    console.log('start:' + touches.length)
    touchStartY = touches[0].clientY;
  }

  function handleTouchEnd(event) {
    const touches = event.changedTouches;
    console.log('end:', touches.length)
    const touchEndY = touches[0].clientY;
    const deltaY = touchEndY - touchStartY;

    showLocationInfo = deltaY <= 0
  }

  function toggleLocationInfo() {
    showLocationInfo = !showLocationInfo
  }

  /**
   * 點選門市資訊欄導航按鈕後觸發
   */
  function direct() {
    var directUrl = `https://www.google.com.tw/maps/dir//${searchLocationInfo.latitude},${searchLocationInfo.longitude}/@${searchLocationInfo.latitude},${searchLocationInfo.longitude},16z`
    if ($mode === 'app') {
        openWebview(false, directUrl)
    } else {
        location.href = directUrl
    }
  }

  /**
   * 點選門市資訊欄撥打電話按鈕後觸發
   */
  function call() {
    var number = searchLocationInfo.tel.replace('-','')
    location.href = `tel:${number}`
  }
</script>
<style>
  #location-info {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 96;
  }

  #location-info > h5 {
    padding-top: 20px;
  }

  small {
    color: #7a7a7a;
  }

  #location-info-hide {
    position: fixed;
    bottom: -8px;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 96;
  }

  .location-info-btn {
    margin-right: 10px;
    padding: 5px 10px;
    /* border: none; */
    background-color: #fff;
    color: #fd4c01;
    border-radius: 50px;
    cursor: pointer;
  }

  hr {
    margin: auto;
    border: 0;
    height: 2px;
    width: 100px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0));
  }

</style>

{#if searchLocationInfo.storeName !== undefined}
  
  {#if showLocationInfo}
    <div id="location-info" transition:slide on:touchstart={handleTouchStart} on:touchend={handleTouchEnd}>
      <hr>
      <h5>{searchLocationInfo.storeName}門市 ({searchLocationInfo.storeId})</h5>
      <p>
        提供借杯與{searchLocationInfo.hasMachine ? '還杯機' : searchLocationInfo.hasPOS ? '櫃檯' : searchLocationInfo.hasPincode ? '跨通路' : ''}還杯
        <br>
        <small>地址：{searchLocationInfo.address}</small>
        <br>
        <small>電話：{searchLocationInfo.tel}</small>
      </p> 
      <button class="location-info-btn" id="navigate-btn" on:click={direct}> 
        <img class="i-img" src="/img/icon-directions.svg" style="width: px" alt=""> 路線
      </button>
      <button class="location-info-btn" on:click={call}> 
        <img class="i-img" src="/img/icon-call.svg" style="width: px" alt=""> 致電
      </button>
    </div>

  {:else}
    <div id="location-info-hide" transition:slide on:touchstart={handleTouchStart} on:touchend={handleTouchEnd}>
      <hr on:click={toggleLocationInfo}>
    </div>
  {/if}

{/if}

{#if $mapLoaded && searchLocationInfo.storeName === undefined}
  <LocationNearStore {searchLocationInfo}></LocationNearStore>
{/if}