<script>
  import API from '../store/config';
  import { layoutOption, queryStr,apiConfig } from '../store/inapp-store'


  const queryString = window.location.search
  $queryStr = queryString

  const urlParams = new URLSearchParams(queryString)
  const gid = urlParams.get('gid')

  // 取得入口串接參數
  fetch(`${$apiConfig.apiUrl}/ct?code=${$apiConfig.apiKey}&gid=${gid}`, {
      method: 'GET'
    })
    .then(response => response.json())
    .then(d => { 
          // 重新開啟首頁
          location.href = '/home?v=5.46.0&ct=' + d.ct
      });
</script>