const errorMsg = 'FORBIDDEN'

export function getDevice() {
    let device;
    if (/Android/i.test(navigator.userAgent)) {
        device = 'Android';
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        device = 'iOS';
    } else if (/BlackBerry/i.test(navigator.userAgent)) {
        device = 'BlackBerry';
    } else if (/IEMobile/i.test(navigator.userAgent)) {
        device = 'Windows Phone';
    } else {
        device = 'Unknown';
    }

    return device;
}

export function  clearScanResult() {
    document.getElementById('statusCode').value = ''
    document.getElementById('scanContentList').value 
}

/**
 * 關閉Webview
 * 於webveiw中呼叫,APP收到後會關閉當前Webview。
 */
export function closeWebview() {
    var device = getDevice()
    try {
        if (device == 'iOS') {
            window.webkit.messageHandlers.OPCloseWebview.postMessage(null)
        }
        if (device == 'Android') {
            window.OPJsInterface.OPCloseWebview();
        }
    } catch (e) {
        throw { msg: errorMsg + ' (' + device + ')' + '\r\n' + e }
    }
}

/**
 * 另開Webview頁面
 * 於webveiw中呼叫, APP收到後可於APP或外部browser另開頁面。
 */
export function openWebview(is_inapp, url){
    var device = getDevice()
    var message = {
        inapp: is_inapp, // true: 開啟Webview; false: 開啟外部瀏覽器
        url: url         // 要開啟的網址
    };

    try {
        if (device == 'iOS') {
            window.webkit.messageHandlers.OpOpenWeb.postMessage(message)
        }
        if (device == 'Android') {
            window.OPJsInterface.OpOpenWeb(message.inapp, message.url);
        }
    } catch (e) {
        throw { msg: errorMsg + ' (' + device + ')' + '\r\n' + e }
    }
}

/**
 * 開啟APP指定功能頁面
 * 於webveiw中呼叫,通知APP轉跳到APP內頁面。
 */
export function openByFeatureId(feature_id, param){
    var device = getDevice()
    var message = {
        feature_id: feature_id, 
        param: param         // 對應EntryID
    };

    try {
        if (device == 'iOS') {
            window.webkit.messageHandlers.OpOpenByFeatureID.postMessage(message)
        }
        if (device == 'Android') {
            window.OPJsInterface.OpOpenByFeatureID(message.feature_id, message.param);
        }
    } catch (e) {
        throw { msg: errorMsg + ' (' + device + ')' + '\r\n' + e }
    }
}

/**
 * 螢幕亮度調整
 * 於webveiw中呼叫,通知APP進行螢幕亮度調整。
 * @param {*} light 
 */
export function screenLight(light) {
    var device = getDevice()
    var message = {
        screen_light: light, // true:螢幕開到最亮; false:螢幕回覆原本亮度
    };

    try {
        if (device == 'iOS') {
            window.webkit.messageHandlers.OpScreenLight.postMessage(message)
        }
        if (device == 'Android') {
            window.OPJsInterface.OpScreenLight(light);
        }
    } catch (e) {
        throw { msg: errorMsg + ' (' + device + ')' + '\r\n' + e }
    }
}

/**
 * 取得AuthV
 * 於webveiw中呼叫用以取得會員偕同授權的auth_v
 */
export function getAuthV() {
    var device = getDevice()
    const clientId = 'cycleCup'

    var message = {
        auth_client: clientId, // 來源端的OP認證雲client id
        get_new: 'Y'          // 是否重取新的AuthV (N:取原有的AuthV; Y:重取新的AuthV)
    };

    try {
        if (device == 'iOS') {
            window.webkit.messageHandlers.OPGetAuthv2.postMessage(message)
        }
        if (device == 'Android') {
            window.OPJsInterface.OPGetAuthv2(message.auth_client, message.get_new);
        }
    } catch (e) {
        throw { msg: errorMsg + ' (' + device + ')' + '\r\n' + e }
    }
}

/**
 * 取得錢包狀態
 * 於webveiw中呼叫用以取得是否有支付工具,APP取得錢包狀態後
 * 透過function OPGetWalletStatusResult將結果回傳置平台網頁。
 */
export function getWalletStatus() {
    var device = getDevice()

    try {
        if (device == 'iOS') {
            window.webkit.messageHandlers.OPGetWalletStatus.postMessage(null)
        }
        if (device == 'Android') {
            window.OPJsInterface.OPGetWalletStatus();
        }
    } catch (e) {
        throw { msg: errorMsg + ' (' + device + ')' + '\r\n' + e }
    }
}

/**
 * 線上支付
 * 於webveiw中呼叫,APP依觸發帶入的參數開始進行線上支付流程,APP授權完成後
 * 透過function OPOnlinePaymentResult將結果回傳置平台網頁。
 */
export function onlinePayment(source, data) {
    var device = getDevice()
    var message = {
        source: source,  //要進行線上支付的平台代號
        data: data       // 下方3~7欄位加密後的結果填入
    };

    try {
        if (device == 'iOS') {
            window.webkit.messageHandlers.OPOnlinePayment.postMessage(message)
        }
        if (device == 'Android') {
            window.OPJsInterface.OPOnlinePayment(message.source, message.data);
        }
    } catch (e) {
        throw { msg: errorMsg + ' (' + device + ')' + '\r\n' + e }
    }
}

/**
 * 觸發APP引導至設定定位權限
 */
export function setLocationPermission() {
    var device = getDevice()

    try {
        if (device == 'iOS') {
            window.webkit.messageHandlers.OPSetLocationPermission.postMessage(null)
        }
        if (device == 'Android') {
            window.OPJsInterface.OPSetLocationPermission();
        }
    } catch (e) {
        throw { msg: errorMsg + ' (' + device + ')' + '\r\n' + e }
    }
}

/**
 * 取得經緯度座標
 */
export function getLocation() {
    var device = getDevice()

    try {
        if (device == 'iOS') {
            window.webkit.messageHandlers.OPGetLocation.postMessage(null)
        }
        if (device == 'Android') {
            window.OPJsInterface.OPGetLocation();
        }
    } catch (e) {
        throw { msg: errorMsg + ' (' + device + ')' + '\r\n' + e }
    }
}

/**
 * 通知APP回饋相機權限「上次使用」功能
 * OP APP若遇「未知」的相機權限(首次使用相機相關功能)，需先Alert詢問使用者相機權限
 */
export function opQueryCameraStatus() {
    var device = getDevice()

    try {
        if (device == 'iOS') {
            window.webkit.messageHandlers.OpQueryCameraStatus.postMessage(null)
        }
        if (device == 'Android') {
            window.OPJsInterface.OpQueryCameraStatus();
        }
    } catch (e) {
        throw { msg: errorMsg + ' (' + device + ')' + '\r\n' + e }
    }
}

/**
 * 開啟掃描器
 * Web呼叫APP開啟裝置端公版掃描器功能。
 * QRCode每一次僅支援掃描 1個條碼、Barcode每一次僅支援掃描 1~3 段條碼。
 */
export function opOpenScanner(scannerType, codeRegexTemp) {
    clearScanResult() // 清除之前的掃描結果
    var device = getDevice()
    var codeRegexListJsonData = [];
    codeRegexTemp.forEach((e, index) => {
        codeRegexListJsonData.push({ "regex_order": `${index + 1}`, "regex_content": `${e}` });
    });
    var codeRegexList = { "code_regex_list": codeRegexListJsonData };

    var message = {
        scanner_type: scannerType,  // QRCode：二維條碼掃描器 Barcode：一維條碼掃描器
        code_regex_list: JSON.stringify(codeRegexList) // 正則式需帶入json格式的字串 
    };

    try {
        if (device == 'iOS') {
            window.webkit.messageHandlers.OPOpenScanner.postMessage(message)
        }
        if (device == 'Android') {
            window.OPJsInterface.OPOpenScanner(message.scanner_type, message.code_regex_list);
        }
    } catch (e) {
        throw { msg: errorMsg + ' (' + device + ')' + '\r\n' + e }
    }
}

/**
 * 隱藏Webview天區 (為了解決ios關閉公版掃描器後殘留天區問題)
 */
export function hideNavbar() {
    var device = getDevice()
    var message = {
        page_type: 'point_search',
        title:''
    };

    try {
        if (device == 'iOS') {
            window.webkit.messageHandlers.OpSetViewRang.postMessage(message)
        }
        if (device == 'Android') {
            window.OPJsInterface.OPGetWebviewRange('WEB01B05P0S0');
        }
    } catch (e) {
        throw { msg: errorMsg + ' (' + device + ')' + '\r\n' + e }
    }

}

/**
 * 呼叫APP開啟會員條碼頁。
 */
export function opOpenMemberGid() {
    var device = getDevice()
    try {
        if (device == 'iOS') {
            window.webkit.messageHandlers.OPOpenMemberGid.postMessage(null)
        }
        if (device == 'Android') {
            window.OPJsInterface.OPOpenMemberGid();
        }
    } catch (e) {
        throw { msg: errorMsg + ' (' + device + ')' + '\r\n' + e }
    }
}

/**
 * website呼叫OP APP調整Webview Style。
 * WEB01B06P0S0：Show back and OPLogo
 * WEB01B05P0S0：全版Webview(Navigation由Web控)
 * @param showOpLogo False不顯示OP天區
 */
export function opGetWebviewRange(showOpLogo) {
    var device = getDevice()
    var message = {
        public_webview_featureID: showOpLogo ? 'WEB01B06P0S0' : 'WEB01B05P0S0',
    };

    try {
        alert(JSON.stringify(message))
        if (device == 'iOS') {
            alert('OPGetWebviewRange')
            window.webkit.messageHandlers.OPGetWebviewRange.postMessage(message)
            alert('OpGetWebviewRange')
            window.webkit.messageHandlers.OpGetWebviewRange.postMessage(message)
        }
        if (device == 'Android') {
            window.OPJsInterface.OPGetWebviewRange(message.public_webview_featureID);
        }
    } catch (e) {
        throw { msg: errorMsg + ' (' + device + ')' + '\r\n' + e }
    }
}

/**
 * 可以設定webview顯示的範圍與navigation bar中間的文字(僅限於部分page_type)
 * @param showOpLogo False不顯示OP天區
 */
export function opSetViewRang(pageType,title) {
    var device = getDevice()
    var message = {
        page_type: pageType,
        title:title
    };

    try {
        alert(JSON.stringify(message))
        if (device == 'iOS') {
            alert('OpSetViewRang')
            window.webkit.messageHandlers.OpSetViewRang.postMessage(message)
            alert('OPSetViewRang')
            window.webkit.messageHandlers.OPSetViewRang.postMessage(message)
            alert('OpSetViewRange')
            window.webkit.messageHandlers.OpSetViewRange.postMessage(message)
            alert('OPSetViewRange')
            window.webkit.messageHandlers.OPSetViewRange.postMessage(message)
        }
        if (device == 'Android') {
            alert('OpSetViewRang')
            window.OPJsInterface.OpSetViewRang(message.page_type,message.title);
            alert('OPSetViewRang')
            window.OPJsInterface.OPSetViewRang(message.page_type,message.title);
            alert('OpSetViewRange')
            window.OPJsInterface.OpSetViewRange(message.page_type,message.title);
            alert('OPSetViewRange')
            window.OPJsInterface.OPSetViewRange(message.page_type,message.title);
        }
    } catch (e) {
        throw { msg: errorMsg + ' (' + device + ')' + '\r\n' + e }
    }
}