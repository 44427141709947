<script>
  import { queryStr } from '../store/inapp-store' 
  import { Modal } from '../components';
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  export let alertModal = { message :'', title: '', timeout: 3 };
  export let enabled = true

  let modal = { message :alertModal.message, title: alertModal.title , type: 'alert'};
  let isOpen = false;

  let timer
  let count = 0
  timer = setInterval(checkStatus, 1000)

  function checkStatus() {
    if(enabled === false) { return }
    if(count >= alertModal.timeout) {  isOpen = true }
    count ++ 
  }

  function close() {
    isOpen = false
    dispatch('closeModal');
  }
  
</script>

<Modal {modal} {isOpen} on:closeModal={close}></Modal>