<script>
import { locationInfo } from '../store/inapp-store'
import { onMount } from 'svelte'

export let zoomLevel = 17
export let showMapNavbar = false

let iframeWindow
let mapLocationInfo = { latitude: '', longitude: '' }

onMount(() => {
  const iframe = document.getElementById('my-map');
  iframeWindow = iframe.contentWindow;
  console.log('iframeWindow', iframeWindow)

  iframeWindow.addEventListener('resize', () => {
    console.log('Google 地圖縮放事件觸發');
  });

});

$:{
  // 取得新的座標點
  if($locationInfo.latitude !== '') {
    mapLocationInfo = getNorthCoordinates($locationInfo.latitude, $locationInfo.longitude, -28)
  }
  //console.log('##### mapLocationInfo', mapLocationInfo)
}

/**
 * 取得原始位置北方n公尺處的座標點
 * @param lat
 * @param lng
 * @param distanceInMeters
 */
 function getNorthCoordinates(latStr, lngStr, distanceInMeters) {
    const earthRadius = 6378137; // 地球半徑，單位：公尺

    // 將字串轉換為浮點數
    const lat = parseFloat(latStr);
    const lng = parseFloat(lngStr);

    if (isNaN(lat) || isNaN(lng)) {
        throw new Error('Invalid latitude or longitude');
    }

    // 計算新的緯度
    const newLat = lat + (distanceInMeters / earthRadius) * (180 / Math.PI);

    // 保持經度不變
    const newLng = lng;

    return { 
      latitude: newLat.toString(), 
      longitude: newLng.toString() 
    };
}

</script>

<style>
  .map-view {
    position: fixed;
    border:0;
    margin-top:-0.45rem;
    min-height: calc(100vh + 2.725rem);
    max-width: 420px;
    touch-action: manipulation;
    z-index: 0;
  }
  .map-view-full {
    position: fixed;
    border:0;
    margin-top: 0rem;
    min-height: calc(100vh + 2.725rem);
    max-width: 420px;
    touch-action: manipulation;
    z-index: 0;
  }    
</style>

<iframe
  id="my-map"
  class="{showMapNavbar ? 'map-view-full': 'map-view'}" 
  src="https://www.google.com/maps/d/u/1/embed?mid=1XGwAZT-dPT-J4liI9VPfaGoT0gXWVJE&ehbc=2E312F&amp;z={zoomLevel}&amp;ll={mapLocationInfo.latitude}%2C{mapLocationInfo.longitude}" 
  width="100%" 
  allowfullscreen="" 
  loading="lazy" 
  data-hasbody="false"
  data-macro-name="googlemaps-embed"
  scrolling="no"
  title="OPEN iECO循環杯門市服務據點">
</iframe>
