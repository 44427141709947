<script>
    import { onMount } from 'svelte';
    import { QRCode } from '../components';
  
    export let barcode1;
    export let barcode2;
    export let barcode3;
    export let qrbox = 125
    export let id = 'qrcode'
  
    let text = `{"Utility":[{"Ordinary":[{"Device":"POS","Code1":"${barcode1}","Code2":"${barcode2}","Code3":"${barcode3}"}]}]}`
  
  </script>
  
  <QRCode {text} qrbox={qrbox} id={id}/>