<script>
  import { layoutOption, queryStr, mode, appSetting, memberInfo, checkFeature} from '../store/inapp-store' 
  import { openByFeatureId } from '../store/openpoint' 
  import IECOStyle from './IECOStyle.svelte';
  import StarbucksStyle from './StarbucksStyle.svelte';
  import { Router, Link, Route } from 'svelte-routing';
  import { getContext } from 'svelte';
  import { ROUTER } from 'svelte-routing/src/contexts';
  
  const { activeRoute } = getContext(ROUTER);

  let isStarbucksApp = location.href.indexOf('starbucks') > 0
  let enableHomeBtn = isStarbucksApp ? false : true

  function getProps({ location, href, isPartiallyCurrent, isCurrent }) {
    const isActive = href === '/' ? isCurrent : isPartiallyCurrent || isCurrent;
    
    // The object returned here is spread on the anchor element's attributes
    if (isActive) {
      return { class: 'nav-link active' };
    }
    return { class: 'nav-link' };
  }

  function redirectHome() {
    location.href = `/home${$queryStr}`
  }

  function redirectBack() {
    if($layoutOption.navBackUrl === '') { 
      redirectApp()
    } 
    location.href = $layoutOption.navBackUrl 
  }

  function redirectApp() {
    try {
        openByFeatureId('HOMAAB00P0S0', '');
    } catch (e) {
        alert(e.msg);
    }
  }

  let count = 0
  function redircetTestPage() {
    count += 1
    if(count == 17) {
      location.href = '/op-test'
    }
  }

</script>

{#if isStarbucksApp}
 <StarbucksStyle></StarbucksStyle>
{:else}
  <IECOStyle></IECOStyle>
{/if}

{#if $layoutOption.navTitle !== '' && $layoutOption.navTitle.indexOf('_') < 0 }
<header class="home-head">
  <btn class="back-btn" on:click={redirectBack}>
    <img src="/img/atom-button-glyphs-buttons-direction-large-back-in-navi-btn-back-green@3x.png" style="height: 2.75rem" alt="" srcset="">
  </btn>
  <div class="title" on:click={redircetTestPage}>{$layoutOption.navTitle}</div>
  <div class="head-r-btn">
      <btn class="cancel-chg-btn" style="visibility: {$layoutOption.showCancel ? '' : 'hidden'};" on:click={()=> location.href = `/cancel?rtn-url=${location.href}`}>
        <img src="/img/atom-button-glyphs-buttons-barcode-btn-ticket-n-2.svg" alt="" srcset="">
      </btn>
      <btn href="" class="home-btn" style="visibility: {enableHomeBtn ? 'visible' : 'hidden'};" on:click={redirectApp}>
        <img src="/img/atom-button-glyphs-buttons-barcode-btn-ticket-n.svg" alt="" srcset="" > 
      </btn>
  </div>
</header>
{/if}

 {#if $layoutOption.navTitle.indexOf('_') >= 0}
<header class="home-head">
  <btn class="close-btn" on:click={redirectBack}>
    <img src="/img/basic-close.svg" alt="" srcset="">
  </btn>
  <div class="title">{$layoutOption.navTitle.split('_')[1]}</div>
</header>
{/if}