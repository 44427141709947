<script>
    import { onMount } from 'svelte';
    import RedeemSummary from './RedeemSummary.svelte';
    import FilterGroup from './FilterGroup.svelte';
    import { layoutOption, apiConfig, memberInfo, valid } from '../store/inapp-store' 
    import CancelSection from './CancelSection.svelte' 
    import { Modal, Loader } from '../components';

    const urlParams = new URLSearchParams(window.location.search)
    const rtnUrl = urlParams.get('rtn-url')

    $layoutOption.navTitle = '取消兌換'
    $layoutOption.navBackUrl = rtnUrl
    $layoutOption.showCarousel = false
    $layoutOption.showCancel = false
    $layoutOption.showHome = true
    $layoutOption.valid = false

    let filter = 'all'
    let orderby = 'expiredtime'
    let showRefresh = false
    
    let memberOrders = []
    let availableRedeemCount = 0;
    let memberRedeemInfos = []
    let displayRedeemInfos = []

    let totalCount = 0

    let modal = { 
        title : '取消兌換',
        message : `確認選取的${totalCount}筆借杯是否要取消兌換並進行退款`,
        type: 'confirm'
    }
    let isOpen = false

    let loader = { content : '退款作業處理中...' };
    let isBlock = false;

    let summaryTitle = '可取消杯數'

    $valid = false

    $:console.log('memberRedeemInfos', memberRedeemInfos);
       
    //取得OP會員循環杯訂單資訊
    fetch(`${$apiConfig.apiUrl}/order?code=${$apiConfig.apiKey}&gid=${$memberInfo.gid}&filter=unredeem`, {
                method: 'GET'
            })
            .then(response => response.json())
            .then(d => {
                    console.log(d)
                    memberOrders = d.info
                    memberOrders = memberOrders.filter(el=> el.payType !== 3)
                    memberOrders.forEach(element => {
                        memberRedeemInfos = memberRedeemInfos.concat(element.cpmobiPayLog)
                    })

                    memberRedeemInfos = memberRedeemInfos.filter(el=> el.paidStatus === 0)
                    displayRedeemInfos = memberRedeemInfos

                    sumRedeemCount() 
                })

    async function sumRedeemCount() {
        if(memberOrders.length == 0) { return false }
        var arr = memberOrders.map(el => el.totalCount - el.cancelCount - el.redeemCount)
        availableRedeemCount = arr.reduce((a,b)=>a+b)
    }

    async function updateItem(item) {
        console.log('item changed', item);
        const i = memberRedeemInfos.findIndex(t => t.id === item.id)
        memberRedeemInfos[i] = { ...memberRedeemInfos[i], ...item }
        totalCount = await sumCheckedCount()
        $valid = totalCount > 0
    }

    async function sumCheckedCount() {
        var arr = memberRedeemInfos.map(el => el.paidStatus);
        return arr.reduce((a,b)=>a+b) / 3;
    }

    function cancel() {
        modal.message =`確認選取的 ${totalCount} 筆借杯是否要取消兌換並進行退款`
        isOpen = true
    }

    function confirm() {
        if(!$valid) { return false }

        isOpen = false
        console.log('confirm.')
        isBlock = true;
        $valid = false

        let param = {
            gid : $memberInfo.gid,
            redeemInfo : memberRedeemInfos.filter(r => r.paidStatus == 3)
        }

        console.log('param' , param)
        
        // 呼叫取消兌換API
        fetch(`${$apiConfig.apiUrl}/cancel?code=${$apiConfig.apiKey}`, {
                method: 'POST',
                body:JSON.stringify(param)
            })
            .then(response => response.json())
            .then(d => {
                console.log(d)
                isBlock = false;
                location.href = `/cancel-result?code=${d.returnCode}`
            });
    }

    function refreshMemberRedeemInfos(newOrderby){
        displayRedeemInfos = newOrderby.indexOf('-desc') > 0 ? memberRedeemInfos.slice().reverse() : memberRedeemInfos
        displayRedeemInfos = displayRedeemInfos 
    }

    function closeModal() {
        isOpen = false
    }
    
    $:console.log('totalCount', totalCount)
    $:console.log('$valid', $valid)

</script>

<div>
    <FilterGroup {filter} {orderby} {showRefresh} on:select={e => refreshMemberRedeemInfos(e.detail)}></FilterGroup>
    <RedeemSummary {summaryTitle} {availableRedeemCount} {memberOrders} ></RedeemSummary>

    <div class="crd-out">
        {#each displayRedeemInfos as item (item)}
            <CancelSection {item} on:update={e => updateItem(e.detail)}></CancelSection>
        {/each}
    </div>
</div>

<div class="cd-fx-btm" id="cal-csendmodal" >
    <button class="cd-send submit-a {$valid ? 'active' : ''}" disabled={!$valid} on:click={cancel}>
        <span class="cd-send-p">取消兌換並退款</span>
    </button>
</div>

<Modal {modal} {isOpen} on:closeModal={closeModal}  on:clickConfirm={confirm}></Modal>

<Loader {loader} {isBlock}></Loader>
