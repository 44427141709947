<script>
    import { layoutOption, valid, memberInfo, apiConfig, newOrderId, queryStr } from '../store/inapp-store' 
    import RegisterItem from './RegisterItem.svelte' 
    import RegisterPaymentSelector from './RegisterPaymentSelector.svelte' 
    import RegisterInvoiceSelector from './RegisterInvoiceSelector.svelte';
    import RegisterTerm from './RegisterTerm.svelte';
    import { Modal } from '../components';

    $layoutOption.navBackUrl = `/home${$queryStr}`
    $layoutOption.showCarousel = true
    $layoutOption.showCancel = false
    
    $valid = false
    let products = [];
    let selectedSize = ''
    let totalCount = 0;
    let price = 0;
    let checkTerms = false
    let agreeTerm = false
    let agreeTermRefund = false
    let agreeTermError = false
    let agreeTermRefundError = false
    let selectedPayType = 'online'
    let selectedRefundType = 'pos'
    let isOpen = false
    let modal = { title : '登記借杯', message : '發生錯誤，請稍候進行重試', type: 'alert' }

    // 取得商品
    fetch(`${$apiConfig.apiUrl}/product?code=${$apiConfig.apiKey}`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(d => {
            console.log(d)
            if(d.returnCode === '0000') {
                products = d.info
            } else {
                isOpen = true
            }
        });

    /**
     * 根據RegisterItem所回傳資訊更新訂單商品明細
     * @param item 商品明細資訊
     */
    async function updateItem(item) {
        console.log('item changed', item);
        for (let i = 0; i < products.length; i++) {
            var element = products[i]
            if(element.size === item.size) {
                products[i] = { ...products[i], ...item }
                selectedSize = item.size
            } else {
                element.count = 0
                products[i] = { ...products[i], ...element }
            }
        }
        totalCount = sumItemCount()
        price = sumPrice()
        $valid = totalCount > 0 
    }

    /**
     * 根據RegisterPaymentSelector所回傳資訊更新訂單支付方式
     * @param payType
     */
    function updatePayType(payType) {
        console.log('payType changed', payType)
        selectedPayType = payType
    }

    /**
     * 根據RegisterPaymentSelector所回傳資訊更新現金支付退款方式
     * @param refundType
     */
    function updateRefundType(refundType) {
        console.log('refundType changed', refundType)
        selectedRefundType = refundType
    }

    /**
     * 取得杯數
     */
    function sumItemCount() {
        var arr = products.map(el => el.count * el.cup);
        return arr.reduce((a,b)=>a+b);
    }

    /**
     * 取得加總金額
     */
    function sumPrice() {
        let result = 0
        products.forEach(element => {
            result += (element.count * element.price)
        });
        return result
    }

    /**
     * 依據是否勾選同意條款呈現警示訊息
     */
    function toggleTerm() {
        if(checkTerms) {
            agreeTermError = !agreeTerm
            agreeTermRefundError = !agreeTermRefund
        }
    }

    /**
     * 建立訂單
    */
    function createOrder() {
        if($valid == false) { return false; }
        
        if(agreeTerm == false || agreeTermRefund == false) {
            checkTerms = true
            toggleTerm() 

            modal = { title : '確認事項',  message : '請勾選退貨同意條款與服務須知', type: 'alert'}
            isOpen = true

            // 將畫面捲動至勾選區塊
            const anchor = document.getElementById("terms-head")
            window.scrollTo({
                top: anchor.offsetTop,
                behavior: 'smooth'
            })

            return
        }

        $valid = false

        let source = selectedPayType === 'offline' ? 1 : 0
        let payType = selectedPayType === 'offline' ? 3 : 1
        let useWallet = selectedRefundType === 'purse'
        let param = {gid : $memberInfo.gid, tel : $memberInfo.foen, payType : payType, source : source, useWallet: useWallet, detail: products}

        fetch(`${$apiConfig.apiUrl}/order?code=${$apiConfig.apiKey}`, {
                method: 'POST',
                body:JSON.stringify(param)
            })
            .then(response => response.json())
            .then(d => {
                console.log(d)
                if(d.returnCode === '0000') {
                    $newOrderId = d.info.id
                    location.href = selectedPayType === 'online' ? '/op-check-wallet' : '/register-result'
                } else {
                    modal = { title : '登記借杯', message : '發生錯誤，請稍候進行重試', type: 'alert' }
                    isOpen = true
                    $valid = true
                }
            });
    }

    /**
     * 當用戶關閉popup時,觸發此方法
     */
    function closeModalCallback() {
        if(agreeTermError || agreeTermRefundError) { 
            isOpen = false
            return  
        }
        location.href = `/home${$queryStr}`
    }

    $:console.log('newOrderId=', $newOrderId)
</script>

{#if (products.length > 0)}
<div class="content-wrap">
    <div class="service">
        <p class="srv-p">請選擇租借杯子</p>
    </div>

    <div class="lease">
        {#each products as item (item)}
            <RegisterItem {item} {selectedSize} on:update={e => updateItem(e.detail)}></RegisterItem>
        {/each}
    </div>

    <RegisterPaymentSelector {selectedPayType} {selectedRefundType} 
        on:updatePayType={e => updatePayType(e.detail)} 
        on:updateRefundType={e => updateRefundType(e.detail)}>
    </RegisterPaymentSelector>
    <RegisterInvoiceSelector></RegisterInvoiceSelector>

    <div id="terms-head"></div>

    {#if agreeTermRefundError}
    <span id="sudo1" class="red-word">請勾選同意條款</span>
    {/if}

    <div class="rtn {agreeTermRefundError ? 'bg-color-red' : ''}" >
        <input type="checkbox" id="rtn-ipt" name="rtn-ipt" bind:checked={agreeTermRefund} on:change={toggleTerm}/>
        <label for="rtn-ipt"><span></span></label>
        <span class="rtn-p">我同意未歸還須開立發票時，由統奕包裝(股)公司代為處理「統一發票」及「銷售退回、進貨、退出或折讓證明單」，以加速退貨退款作業</span>
    </div>
    
    {#if agreeTermError}
    <span id="sudo1" class="red-word">請勾選同意條款</span>
    {/if}

    <div class="cfm {agreeTermError ? 'bg-color-red' : ''}">
        <input type="checkbox" id="cfm-ipt" name="cfm-ipt" bind:checked={agreeTerm} on:change={toggleTerm}/>
        <label for="cfm-ipt"><span></span></label>
        <span class="cfm-p">我了解服務須知並同意</span>
    </div>
    <RegisterTerm></RegisterTerm>
</div>

<div class="cal">
    <div class="cal-content">
        <p>合計杯數</p>
        <p>共 <span class="quantity">{totalCount}</span> 杯</p>
    </div>
    <div class="hr-lr-wrap">
        <hr>
    </div>
    <div class="cal-sum">
        <span class="cal-ct">小計：</span>
        <div class="cal-price-ol">
            <img class="cal-price-img" src="/img/atom-icon-currency-ic-ntd-large.svg" alt="">
            <p class="price">{price}</p>
            <span class="price-y">元</span>
        </div>
    </div>
    <btn id="cal-sendmodal" class="submit-btn {$valid ? 'active' : ''}"  disabled={!$valid} on:click={createOrder}>送出訂單</btn>
</div>
{/if}

<Modal {modal} {isOpen} on:closeModal={closeModalCallback}></Modal>