<script>
    import RedeemSummary from './RedeemSummary.svelte'
    import { layoutOption, apiConfig, appSetting, memberInfo, queryStr, checkFeature } from '../store/inapp-store' 
    import { AlertTimer } from '../components'

    $layoutOption.navTitle = '兌換取杯'
    $layoutOption.navBackUrl = `/home${$queryStr}`
    $layoutOption.showCarousel = false
    $layoutOption.showCancel = false
    $layoutOption.showHome = true

    let enableBUReturn = checkFeature($appSetting.enableBUReturn, $memberInfo.gid, $appSetting.guestList) 
    if(enableBUReturn === false) {
        location.href = '/redeem-via-pos'
    }

    let alertModal = { message :'取得兌換取杯資訊失敗，請稍後進行重試，或洽詢客服專線 0809-090-711', title: '兌換取杯', timeout: 5 };
    let enabled = true

    let memberOrders = []
    let availableRedeemCount = 0;
    let displayOrders = []

    let summaryTitle = '可兌換杯數'

    // 取得OP會員循環杯訂單資訊
    fetch(`${$apiConfig.apiUrl}/order?code=${$apiConfig.apiKey}&gid=${$memberInfo.gid}&filter=unredeem`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(d => {
                console.log(d)
                if(d.returnCode === '0000') {
                    memberOrders = d.info
                    displayOrders = memberOrders
                    console.log('memberOrders=', memberOrders)
                    sumRedeemCount() 
                } 
                enabled = false
        })
        .catch(e => {
            console.log(e)
        });

    function sumRedeemCount() {
        if(memberOrders.length == 0) { return false }
        var arr = memberOrders.map(el => el.totalCount - el.cancelCount - el.redeemCount )
        availableRedeemCount = arr.reduce((a,b)=>a+b)
        
        var arr2 = memberOrders.filter(el => el.payType !== 3 ).map(el => el.totalCount - el.cancelCount - el.redeemCount )
        let availableCancelCount = arr2.length > 0 ? arr2.reduce((a,b)=>a+b) : 0

        $layoutOption.showCancel = enableBUReturn === false && availableCancelCount > 0
    }

</script>

{#if enableBUReturn}
<div>
    <div>
        <div class="p-3">
            <div class="mb-2">
                <span style="font-size: 1.0625rem;">取杯方式</span>
            </div>
    
            <div class="card border-0" style="border-radius:0.75rem;">
                <div class="card-body d-flex align-items-center justify-content-between p-3">
                    <div class="d-flex align-items-center gap-3">
                        <img width="72" src="/img/Illustration_store.svg" alt="">
                        <div class="d-flex flex-column gap-1" style="min-width: 117px;">
                            <span style="font-size: 1.0625rem;">{enableBUReturn ? '統一超商' : ''}櫃台取杯</span>
                            <span>
                                <btn class="d-flex gap-0 align-items-center justify-content-start"  
                                    onclick="location.href='/store?f=via_pos&rtn-url=/redeem'">
                                    <img width="16" src="/img/Lovefood_map.svg" alt="">
                                    <span style="color: var(--orange);">查詢門市</span>
                                </btn>
                            </span>
                        </div>
                    </div>
                    <btn class="btn-green" onclick="location.href='/redeem-via-pos'">
                        <span>掃碼取杯</span>
                    </btn>
                </div>
            </div>
            <div class="card border-0 mt-3" style="border-radius:0.75rem;">
                <div class="card-body d-flex align-items-center justify-content-between p-3">
                    <div class="d-flex align-items-center gap-3">
                        <img width="72" src="/img/Illustration_store.svg" alt="">
                        <div class="d-flex flex-column gap-1" style="min-width: 117px;">
                            <span style="font-size: 1.0625rem;">跨品牌通路取杯</span>
                            <span>
                                <btn class="d-flex gap-0 align-items-center justify-content-start"  
                                    onclick="location.href='/store?f=via_pincode&rtn-url=/redeem'">
                                    <img width="16" src="/img/Lovefood_map.svg" alt="">
                                    <span style="color: var(--orange);">查詢門市</span>
                                </btn>
                            </span>
                        </div>
                    </div>
                    <btn class="btn-green" onclick="location.href='/redeem-via-scanner'">
                        <span>掃碼取杯</span>
                    </btn>
                </div>
            </div>
        </div>
    </div>

    <div class="px-3" style="padding-bottom:0.75rem;">
        <h6 class="font-weight-bolder">{summaryTitle}：共 <span style="color: red;">{availableRedeemCount}</span> 杯</h6>
    </div>

</div>

<AlertTimer {alertModal} {enabled} on:closeModal={e => enabled = false}></AlertTimer>
{/if}