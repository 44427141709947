<script>
    import { layoutOption, appSetting, memberInfo, checkFeature, mode } from '../store/inapp-store'
    import { openWebview } from '../store/openpoint'
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let filter 
    export let isStarbucksApp

    let enableOpenEmap = isStarbucksApp ? false: checkFeature($appSetting.enableOpenEmap, $memberInfo.gid, $appSetting.guestList) 
    let enableBUReturn = isStarbucksApp ? false: checkFeature($appSetting.enableBUReturn, $memberInfo.gid, $appSetting.guestList) 
    let enableOpenGoogleMyMap = isStarbucksApp ? false: checkFeature($appSetting.enableOpenGoogleMyMap, $memberInfo.gid, $appSetting.guestList) 

    let displayFilter = false
    let filterName = getFilterName()

    function toggleFilter(f) {
        filter = f
        displayFilter = false
        filterName = getFilterName()
        location.href = isStarbucksApp ? '/starbucks-store?f=' + filter  : '/store?f=' + filter 
    }

    function getFilterName() {
        if(filter === 'all') { return '全部' }
        if(filter === 'via_pos') { return '借杯與櫃檯還杯門市' }
        if(filter === 'via_machine') { return '借杯與還杯機還杯門市' }
        if(filter === 'via_pincode') { return '跨品牌通路借杯與還杯門市' }
        return ''
    }

    /**
     * 開啟外部連結-超商電子地圖
     */
    function redirectSevenEmap() {
        let sevenEMap = 'https://emap.pcsc.com.tw';
        if ($mode === 'app') {
            openWebview(false, sevenEMap)
        } else {
            location.href = sevenEMap
        }
    }

    /**
     * 開啟外部連結-Google我的地圖
     */
    function redirectMyMap() {
        let myMap = 'https://www.google.com/maps/d/u/3/edit?mid=1XGwAZT-dPT-J4liI9VPfaGoT0gXWVJE&usp=sharing';
        if ($mode === 'app') {
            openWebview(false, myMap)
        } else {
            location.href = myMap
        }
    }

</script>

<style>
    .cd-list-o{
        background-color: #fd4c01;
    }
</style>

{#if isStarbucksApp}

<div class="lightbox" id="filter-btn">
    <div class="POPUP-BG">
        <div class="flexbox-flex-Frn-Psc-Ac POPUP-TI">
            <span>全部</span>
            <img src="img/icon-filter.svg" alt="">
        </div>
        <div class="OPT-box">
            <button class="{(filter === 'all' ? 'OPT-box-btn-t': '')}" on:click={()=> toggleFilter('all')}>全部</button>
            <button class="{(filter === 'via_pos' ? 'OPT-box-btn-t': '')}" on:click={()=> toggleFilter('via_pos')}>提供借杯與櫃檯還杯門市</button>
            <button class="{(filter === 'via_machine' ? 'OPT-box-btn-t': '')}" on:click={()=> toggleFilter('via_machine')}>提供借杯與還杯機還杯門市</button>         
        </div>
    </div>
</div>

{:else}


<nav class="cd-navbar">
    {#if enableOpenEmap && filter !== 'via_pincode'  && enableOpenGoogleMyMap === false}
    <ul class="cd-nav">
        <li>
            <btn id="alabtn" class="ala-li cd-list" on:click={redirectSevenEmap} style="background-color: #ff6300;">
                <p class="ala-s cd-p" style="color: #ffffff;">超商電子地圖查詢</p>
            </btn>
        </li>
    </ul>
    {/if}

    {#if enableOpenGoogleMyMap}
    <ul class="cd-nav">
        <li>
            <btn id="alabtn" class="ala-li cd-list" on:click={()=> location.href = '/store-map?rtn-url=/store' } style="background-color: #ff6300;">
                <p class="ala-s cd-p" style="color: #ffffff;">超商電子地圖查詢</p>
            </btn>
        </li>
    </ul>
    {/if}

    <btn class="cd-rf" style="visibility:hidden">
        <img src="/img/atom-button-glyphs-buttons-refresh-btn-refresh-n.svg" alt="" style="display: none;">
    </btn>
    <ul class="cd-nav">
        <li>
            <btn id="alabtn" class="ala-li cd-list" on:click={() => displayFilter = true}>
                <p class="ala-p cd-p">{filterName}</p>
                <img class="ala-img cd-img"
                    src="/img/atom-button-glyphs-buttons-filter-btn-filter-t.svg" alt="" width="24"
                    height="24">
            </btn>
        </li>
    </ul>
</nav>

<div id="alamodal" class="alamodal" style="display: {displayFilter ? 'block' : 'none'}">
    <div class="alamodal-ctn">
        <span class="u-close">篩選：<img src="/img/atom-button-glyphs-buttons-filter-btn-filter-t.svg"
                alt=""></span>
        <div class="um-ctn-l">
            <button class="ala-s w-100 {(filter === 'all' ? 'active': '')}" on:click={()=> toggleFilter('all')}>全部</button>
            <button class="ala-s w-100 {(filter === 'via_pos' ? 'active': '')}" on:click={()=> toggleFilter('via_pos')}>借杯與櫃檯還杯門市</button>
            <button class="ala-s w-100 {(filter === 'via_machine' ? 'active': '')}" on:click={()=> toggleFilter('via_machine')}>借杯與還杯機還杯門市</button>

            {#if enableBUReturn}
            <button class="ala-s w-100 {(filter === 'via_pincode' ? 'active': '')}" on:click={()=> toggleFilter('via_pincode')}>跨品牌通路借杯與還杯門市</button>
            {/if}
        </div>
    </div>
</div>

{/if}
