<script>
import { onMount } from 'svelte'
import { createEventDispatcher } from 'svelte';
import LocationInfomation from './LocationInfomation.svelte';

const dispatch = createEventDispatcher();

export let touchable = true
export let zoomLevel = 17
export let searchLocationInfo = {}

let map = { minZoom:1, maxZoom: 21}

let touchStartX = 0;
let touchStartY = 0;
let initialTouchDistance = 0;
let tempZoomChange = 0

onMount(() => {
  if(touchable) {
    document.getElementById('alamodal-touch').addEventListener('touchstart', handleTouchStart, false);
    document.getElementById('alamodal-touch').addEventListener('touchmove', handleTouchMove, false);
    document.getElementById('alamodal-touch').addEventListener('touchend', handleTouchEnd, false);
  }
});

function handleTouchStart(event) {
  const touches = event.touches;
  console.log('start:' + touches.length)
  if (touches.length === 2) {
    initialTouchDistance = Math.hypot(touches[0].clientX - touches[1].clientX, touches[0].clientY - touches[1].clientY);
  } else {
    touchStartX = touches[0].clientX;
    touchStartY = touches[0].clientY;
  }
}

function handleTouchMove(event) {
  event.preventDefault();
  const touches = event.touches;
  console.log('move:' + touches.length)
  if (touches.length === 2) {
    const currentTouchDistance = Math.hypot(touches[0].clientX - touches[1].clientX, touches[0].clientY - touches[1].clientY);
    const deltaDistance = currentTouchDistance - initialTouchDistance;
    const zoomChange = deltaDistance / 100;
    tempZoomChange += zoomChange
    initialTouchDistance = currentTouchDistance;  
    if(tempZoomChange > 1) { zoomIn(); changeZoomLevel(); } else if(tempZoomChange < -1)  { zoomOut(); changeZoomLevel(); }
  }
}

function handleTouchEnd(event) {
  const touches = event.changedTouches;
  console.log('end:', touches.length)
  const touchEndX = touches[0].clientX;
  const touchEndY = touches[0].clientY;
  const deltaX = touchEndX - touchStartX;
  const deltaY = touchEndY - touchStartY;
  touchMove(deltaX, deltaY)
}

function zoomIn() {
  if (zoomLevel < map.maxZoom) {
    zoomLevel += 1
  }
  changeZoomLevel()
}

function zoomOut() {
  if (zoomLevel > map.minZoom) {
    zoomLevel -= 1  
  }
  changeZoomLevel()
}

function changeZoomLevel() {
  tempZoomChange = 0
  console.log('zoomLevel=', zoomLevel)
  dispatch('changeZoomLevel', zoomLevel);
}

function touchMove(x, y) {
  dispatch('touchMove', {x, y});
}

</script>

<style>
  #zoom-control {
      position: fixed;
      bottom: 4px;
      left: 15px;
      z-index: 1;
      background-color: white;
      padding: 5px;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      z-index: 96;
  }
  #zoom-in, #zoom-out {
      cursor: pointer;
      margin-bottom: 5px;
      display: block;
  }
</style>

{#if touchable}
<div id="zoom-control">
  <div id="zoom-in" on:click={zoomIn}>
    <img src="/img/img-add.png" class="img-location" alt="" width="24" height="24" >
  </div>
  <div id="zoom-out" on:click={zoomOut}>
    <img src="/img/img-remove.png" class="img-location" alt="" width="24" height="24" >
  </div>
</div>
{/if}

<LocationInfomation {searchLocationInfo}></LocationInfomation>