<script>
    import { layoutOption, apiConfig, newOrderId, queryStr } from '../store/inapp-store' 
    import { Modal, Loader } from '../components';

    $layoutOption.navBackUrl = `/home${$queryStr}`
    $layoutOption.showBanner = false
    $layoutOption.showCarousel = false
    $layoutOption.navTitle = '付款詳細資訊'

    let loader = { content : '訂單確認中...' } 
    let isBlock = true

    let info;
    
    fetch(`${$apiConfig.apiUrl}/confirm?code=${$apiConfig.apiKey}`, {
            method: 'POST',
            body:JSON.stringify({id : $newOrderId })
        })
        .then(response => response.json())
        .then(d => {
            if(d.returnCode === '0000') {
                console.log(d)
                if(d.info.payType === 3) {  location.href = '/redeem'  }
                info = d.info
                isBlock = false
            } 
        });

</script>

{#if (info != undefined && info.payType !== 3)}
<div>
    <div class="pay-detial">
        <div class="pay-d-ctn">
            <p class="d-numb">訂單編號：</p>
            <span class="d-numb-t">{info.id}</span>
        </div>
        <div class="pay-d-ctn">
            <p class="d-debit-dl">扣款時間：</p>
            <span class="d-debit-t">{info.payInfo.captureTimeStr}</span>
        </div>
        <div class="pay-d-ctn">
            <span class="d-rtn-dl">歸還期限：</span>
            <span class="d-rtn-dl-t">{info.expiredTimeStr}</span>
        </div>

        <div class="dash-hr-wrap">
            <div class="dash-hr"></div>
        </div>

        <div class="pay-d-ctn">
            <p class="d-d">扣款金額：</p>
            <span class="d-d-numb">$ {info.amount}</span>
        </div>
        <div class="pay-d-ctn">
            <p class="d-pd">點數折抵：</p>
            <span class="d-pd-t">- $ {info.payInfo.capturePoint}</span>
        </div>  
        <div class="pay-d-ctn">
            <p class="d-ta">合計金額：</p>
            <span class="d-ta-numb">$ {info.payInfo.captureAmount}</span>
        </div>
    </div>

    <div class="ptd">
        <p class="ptd-p">商品內容</p>
    </div>

    <div class="ptd-t">
    {#each info.detail as item (item)}
    {#if (item.count > 0)}
        <div class="ptd-t-ctn">
            <p class="ptd-mdm">{item.name}</p>
            <p class="ptd-mdm-qtt"><span class="ptd-mdm-qtt-c">{item.count} </span>杯</p>
        </div>
    {/if}
    {/each}
    </div>

    <div class="is">
        <p class="is-t">查詢借杯門市：</p>
        <p class="is-p">
            請至循環杯租借首頁 > 查詢循環杯門市 進行查詢，循環杯數量依門市實際庫存為主。
        </p>
    </div>
</div>

<div class="pay-d-fx-btm">
    <a href="/register" class="pay-d-send submit-a">
        <p class="pay-d-send-p">繼續租借</p>
    </a>
    <a href="/redeem" class="pay-d-send submit-a">
        <p class="pay-d-send-ps">立即取杯</p>
    </a>
</div>
{/if}

<Loader {loader} {isBlock}></Loader>