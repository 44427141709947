<script>
   import { createEventDispatcher } from 'svelte';

   const dispatch = createEventDispatcher();

   export let item

   let checked = item.paidStatus == 3 

   function toggleCheck() {
       checked = !checked
       item.paidStatus = checked ? 3 : 0
       dispatch('update', item);
   }

</script>

<div class="crt">
    <input type="checkbox" id="cr-ipt-{item.seq}-{item.id}" name="cr-ipt" on:click={toggleCheck} {checked}/>
    <label for="cr-ipt-{item.seq}-{item.id}"><span></span></label>
    <img src="/img/cup-img-{item.size}.jpg" class="cr-s" alt="">
    <div>
        <p class="cr-p">{item.name} {item.count} 杯</p>
        <p class="cr-d">取杯期限<span class="cr-dat">{item.dueTimeStr}</span></p>
    </div>
</div>