<script>
    import { layoutOption, memberInfo, apiConfig, tempSplitInfos } from '../store/inapp-store' 
    
    $layoutOption.showBanner = false
    $layoutOption.showCarousel = false

    let needReturnCount = 0
    let minAmount = 0
    let maxAmount = 0
    let editingCount = 0

    let showStars = false

    function minusOne() {
        if(editingCount - 1 >= minAmount) { 
            editingCount -= 1 
        }
    }

    function plusOne() {
        if(editingCount + 1 <= maxAmount) {
            editingCount += 1   
        }
    }

    fetch(`${$apiConfig.apiUrl}/starbucks/order?code=${$apiConfig.apiKey}&mid=${$memberInfo.mid}`, {
        method: 'GET'
    })
    .then(response => response.json())
    .then(d => {
            console.log(d)
            if(d.returnCode === '0000') {                                        
                // 可還杯數量加總
                var arr = d.info.map(el => el.count)
                needReturnCount = d.info.length === 0 ? 0 : arr.reduce((a,b)=>a+b)
                maxAmount = needReturnCount
            } 
    })
    .catch(e => {
        console.log(e)
    });

    function checkCupCount() {
        if(editingCount === 0) { return false }
        $tempSplitInfos = []
        showStars = true

        // 取得還杯訂單拆單結果
        fetch(`${$apiConfig.apiUrl}/starbucks/split?code=${$apiConfig.apiKey}&mid=${$memberInfo.mid}&returnCount=${editingCount}`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(d => {
            console.log(d)
            if(d.returnCode === '0000' && d.info.length > 0) {
                var arr = new Array()
                d.info.forEach(order => {
                    arr.push(order) 
                })
                $tempSplitInfos = arr
                location.href = '/starbucks-barcode?action=light'
            } else {
                location.href = '/starbucks-return'
            }
        })
        .catch(e => {
            console.log(e)
        });
    }

</script>


<div class="main return">
{#if showStars}
    <img src="img/6-2.gif" alt="">
    <img src="img/7-2.gif" alt="">
{:else}
    <div class="quantity-box">
        <h2>未歸還紀錄：共 {needReturnCount} 杯</h2>
        <div class="text-A-C">
            <div class="flexbox-flex-Frn-Psc-Ac cho-T-img-box">
                <table>
                    <tr>
                        <td>
                            選擇歸還杯數：
                        </td>
                        <td>
                            <form  class="flexbox-flex-Frn-Pss-Ac cho-box">
                                <input type="button" class="bnt-m" on:click={minusOne} >
                                <input type="text" value="{editingCount}" class="text-A-C" style="width: 44px; text-align: center; font-size: 1.2rem;">
                                <input type="button" class="bnt-p" on:click={plusOne} >
                            </form>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td class="td-remark">
                            每次歸還上限{maxAmount}杯
                        </td>
                    </tr>
                </table>
            </div>
            <button on:click={checkCupCount}>確認還杯數量</button>
        </div>
    </div>
    <ul>
        說明
        <li>目前並非所有門市都有提供循環杯還杯服務，請先查詢循環杯還杯門市</li>
        <li>歸還時會計還杯數量，但不識別還杯尺寸</li>
    </ul>
    <img src="img/6-1.gif" alt="">
    <h3>環保永續，為地球盡一份心力！</h3>
{/if}
 </div>
