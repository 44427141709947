<LibLoader url={storeConfigJS} on:loaded="{onLoaded}" />
<script>
import { apiConfig, storeInfos } from '../store/inapp-store'
import { LibLoader } from '../components'
import { createEventDispatcher } from 'svelte';
import LocationSearchItem from './LocationSearchItem.svelte';

export let touchable = true

const dispatch = createEventDispatcher();

let storeConfigJS = `${$apiConfig.storageEndpoint}/script-container/store-config.js?d=${new Date().getTime()}` // 取得門市資訊JS所在位置

let showResult = false
let storeList = []
let filteredStoreList = []
let keyword = ''
let searchLocationInfo = {}

$:{
    keyword = keyword
    // console.log('keyword=', keyword)
    if(keyword.trim() === '') {
        closeSearchPanel()
    } else {
        search()
    }
}

/**
 * 關鍵字查詢門市
 */
function search() {
    if(keyword.trim() === '') { return false }
    searchLocationInfo = {}
    showResult = keyword.trim() !== ''
    filteredStoreList = storeList.filter(el=> el.address.includes(keyword) || 
        el.storeName.includes(keyword) || el.storeId.includes(keyword))
}

/**
 * 載入門市資訊後會觸發此事件
 */
function onLoaded() {
    // console.log('### storeConfig', storeConfig)
    storeList = storeConfig.storeList ;
    $storeInfos = storeList
}

/**
 * 點擊查詢結果的門市後會觸發此事件
 */
function onChangeLocation(location) {
    console.log('location = ', location) 
    closeSearchPanel()
    searchLocationInfo = location
    dispatch('changeLocation', location);
}

/**
 * 關閉查詢界面
 */
function closeSearchPanel() {
    showResult = false
    keyword = ''
    filteredStoreList = []
}
</script>

<style>
.search-wrap {
    position: fixed;
    width: 100%;
    height: 3.75rem;
    max-width: 420px;
    z-index: 98;
    background-color: #f5f5f5;
}

.ala-li> input {
    width: 80vw;
    height: 100%;
    text-align: center;
    border: 0;
    outline: none;
    /* box-shadow: 0px 2px 1px 0 rgba(152, 152, 152, 0.2); */
}

.cd-list {
    max-width: 342px;
}

.search-result {
    padding-top: 0;
    width: 100%;
}

.lease{
    height: 64vh;
    overflow: scroll;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

<div class="search-wrap">
    <nav class="cd-navbar">
        <ul class="cd-nav" width="100%">
        <li>
            <div id="alabtn" class="ala-li cd-list">
                <input type="text" name="search-input" class="search-input center" bind:value="{keyword}" placeholder="門市名稱、店號、地址">
                <img class="ala-img cd-img" src="/img/icon-close.svg" alt="" width="24" height="24" 
                     style="display: {keyword.trim() === '' ? 'none': 'block'}"
                    on:click={closeSearchPanel}>
                <img class="ala-img cd-img" src="/img/icon-search.svg" alt="" width="24" height="24" 
                    on:click={search}>
            </div>
        </li>
        </ul>
    </nav>
</div>

<div id="alamodal" class="alamodal" style="display: {touchable || showResult ? 'block' : 'none'}; opacity: {showResult ? '1' : '0'}; {showResult ? 'z-index: 97' : ''}" on:click={closeSearchPanel}> 
    <div class="alamodal-ctn">
        <span class="u-close"><img src="/img/icon-search.svg" alt=""></span>
        {#if filteredStoreList.length > 0}
        <div class="chg-court-p">
            <p>符合條件的查詢結果共有 {filteredStoreList.length} 筆</p>
        </div>
        <div class="show-box box-1 search-result">
            <div class="lease">
                {#each filteredStoreList as item (item)}
                    <LocationSearchItem {item} {keyword} on:changeLocation={e => onChangeLocation(e.detail)}></LocationSearchItem>
                {/each}
            </div>
        </div>
        {:else}
        <div class="chg-court-p">
            <p>查無門市資訊</p>
        </div>
        {/if}
    </div>

    {#if touchable}
    <div class="alamodal-ctn" id="alamodal-touch" style="height: 75vh; display: {showResult ? 'none' : 'block'}"></div>
    {/if}
</div>
