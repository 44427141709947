<script>
    import RedeemItem from './RedeemItem.svelte'

    export let order
    export let mode = 'pos'
    export let title = ''

    let filteredRedeemInfo = order.cpmobiPayLog.filter(el=> el.paidStatus === 0)
    
</script>

{#if mode === 'pos' || order.payType !== 3 }

    {#if filteredRedeemInfo.length > 0 }
    <div class="cr-out-out">
        <div class="cr-out">
        {#each filteredRedeemInfo as item (item)}
            <RedeemItem {item} {order} {mode} {title}></RedeemItem>
        {/each}
        </div>
    </div>
    {/if}

{/if}