<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  
  export let loader = { content : '' };
  export let isBlock = false;
  
</script>

<div id="myCSendModal" class="csend-modal" style="display:{isBlock ? 'block': 'none'}">
  <div class="box">
      <div class="cs-loading-ctn">
          <img src="/img/2023_open_loading.gif" alt="" width="140" height="122.2">
          <span class="cs-loading-p">{loader.content}</span>
      </div>
  </div>
</div>