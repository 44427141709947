<script>
  import { createEventDispatcher } from 'svelte';
  
  const dispatch = createEventDispatcher();
  
  export let modal = { message :'', title: '', type: 'alert', isOpen: false}
  export let isOpen = false

  let messages = modal.message.split('<br>')

  $:{
    if(modal.isOpen !== undefined) {
      isOpen = modal.isOpen
    }
  }

  function close() {
    isOpen = false
    dispatch('closeModal');
  }

  function confirm() {
    isOpen = false
    dispatch('clickConfirm');
  }
</script>
<style>
  .text-warn {
    color: #FF001F;
  }
</style>

{#if (modal.type === 'alert')}
<div id="mySendModal" class="send-modal" style="display:{isOpen ? 'block': 'none'}">
  <div class="box">
      <div class="send-modal-ctn-wrap" style="height: { 12.125 + (messages.length - 1) * 2 }rem;">
          <div class="send-modal-ctn" on:click={close}>
              <span class="close"><img src="/img/atom-button-glyphs-buttons-close-btn-close-w-n.svg"
                      alt=""></span>
              <span class="send-modal-ctn-header">{modal.title}</span>
          </div>
          <div class="send-modal-ctn-nr me-2">
              <span class="send-modal-ctn-nr-p pt-3 px-3">
                {#if messages.length === 1}
                  {modal.message}
                {:else}
                  <ul class="text-left">
                    {#each messages as msg(msg)}
                        {#if msg.indexOf('WARN:') >= 0}
                          <li class="text-warn">{msg.replace('WARN:', '')}</li>
                        {:else}
                          <li>{msg}</li>
                        {/if}
                    {/each}
                  </ul>
                {/if}
              </span>
          </div>
          <div class="send-modal-ctn-cfm" on:click={close}>
              <span class="send-modal-ctn-cfm-btn">確認</span>
          </div>
      </div>
  </div>
</div>
{/if}

{#if (modal.type === 'confirm')}
  <div id="myCSendModal" class="csend-modal" style="display:{isOpen ? 'block': 'none'}">
    <div class="box">
        <div class="csend-modal-ctn-wrap">
            <div class="csend-modal-ctn">
                <span class="cs-close"  on:click={close}>
                    <img src="/img/atom-button-glyphs-buttons-close-btn-close-w-n.svg" alt="">
                </span>
                <span class="csend-modal-ctn-header">{modal.title}</span>
            </div>
            <div class="csend-modal-ctn-nr">
                <span class="csend-modal-ctn-nr-p">
                  {modal.message}
                </span>
            </div>
            <div class="csend-modal-ctn-cfm">
                <span class="csend-modal-ctn-cfm-btn" on:click={close}>取消</span>
                <div class="csd-hr"></div>
                <span class="csend-modal-ctn-cfm-btn" on:click={confirm}>確定</span>
            </div>
        </div>
    </div>
  </div>
{/if}
