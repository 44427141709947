<script>
import { mode, mapLoaded } from '../store/inapp-store'
import { openWebview } from '../store/openpoint'
import { createEventDispatcher } from 'svelte';

const dispatch = createEventDispatcher();

export let item
export let keyword

item.latitude = item.lat 
item.longitude = item.lon

function changeLocation() {
    $mapLoaded = false
    dispatch('changeLocation', item)
}

function direct() {
    var directUrl = `https://www.google.com.tw/maps/dir//${item.latitude},${item.longitude}/@${item.latitude},${item.longitude},16z`
    if ($mode === 'app') {
      openWebview(false, directUrl)
    } else {
      location.href = directUrl
    }
}

function highlightText(text, keyword) {
    const parts = [];
    let currentIndex = 0;

    try {
        while (currentIndex < text.length) {
            const index = text.indexOf(keyword, currentIndex);
            if (index === -1) {
            parts.push({ text: text.slice(currentIndex) });
            break;
            }
            parts.push({ text: text.slice(currentIndex, index), highlighted: false });
            parts.push({ text: text.slice(index, index + keyword.length), highlighted: true });
            currentIndex = index + keyword.length;
        }
    } catch (e) {
        //console.log(e.msg)
    }

    return parts;
}
</script>
<style>
    .highlighted {
      color: #fd4c01;
    }
</style>
<div class="lcontent">
    <div class="lc-label-ol">
        <label for="lc-a">
            <div class="lc-ol" style="width: 70vw" on:click={changeLocation}>
                <h4>
                    {#each highlightText(item.storeName, keyword) as { text, highlighted }}
                        {#if highlighted}
                            <span class="highlighted">{text}</span>
                        {:else}
                            <span>{text}</span>
                        {/if}
                    {/each}
                    (
                    {#each highlightText(item.storeId, keyword) as { text, highlighted }}
                        {#if highlighted}
                            <span class="highlighted">{text}</span>
                        {:else}
                            <span>{text}</span>
                        {/if}
                    {/each}
                    )
                </h4>
                <p>
                    {#each highlightText(item.address, keyword) as { text, highlighted }}
                        {#if highlighted}
                            <span class="highlighted">{text}</span>
                        {:else}
                            <span>{text}</span>
                        {/if}
                    {/each}
                </p>
            </div>
            <img class="i-img" src="/img/icon-directions.svg" style="width: 40px" alt="" on:click={direct}>
        </label>
    </div>
</div>