
<script>
    import { layoutOption, memberInfo, mode, apiConfig, appSetting, checkFeature, queryStr } from '../store/inapp-store' 
    import { QRCode } from '../components';
    import { screenLight } from '../store/openpoint' 
    import { Modal } from '../components';

    let enableStoreReturn = checkFeature($appSetting.enableStoreReturn, $memberInfo.gid, $appSetting.guestList) 

    const urlParams = new URLSearchParams(window.location.search)
    const rtnUrl = urlParams.get('rtn-url')

    $layoutOption.navTitle = '還杯'
    $layoutOption.navBackUrl =  enableStoreReturn ? ((rtnUrl === null || rtnUrl === undefined) ?`/return-cup` : rtnUrl) : `/home${$queryStr}`
    $layoutOption.showCarousel = false
    $layoutOption.showCancel = false
    $layoutOption.showHome = true

    let text = ''
    let timeoutSeconds = 0 
    let isOpenQrcode = false
    let lightUp = false
    let displayQrcode = false

    let modal = { 
        title : '還杯',
        message : '模擬還杯成功',
        type: 'alert'
    }
    let isOpen = false

    function getIdentity() {
        // 取得OP會員還杯識別碼
        fetch(`${$apiConfig.apiUrl}/returnId?code=${$apiConfig.apiKey}&gid=${$memberInfo.gid}`, {
                method: 'GET'
            })
            .then(response => response.json())
            .then(d => {
                    console.log(d)
                    if(d.returnCode === '0000') {
                        displayQrcode = d.info.identity !== ''
                        if(displayQrcode) {
                            text = d.info.identity
                            timeoutSeconds = 120
                        }
                    }
                })
    }

    function openQRCodeModal() {
        if(displayQrcode) {
            isOpenQrcode = true
            lightUp = true
            screenLight(lightUp)
        }
    }

    function closeQRCodeModal() {
        try{
            isOpenQrcode = false
            lightUp = false
            screenLight(lightUp)
        }catch(e){
            console.log(e)
        }
    }

    setInterval(function() {
        if(timeoutSeconds == 0) { getIdentity() }
        timeoutSeconds -= 1
    }, 1000)

    let count = 0

    /**
     * 模擬還杯功能
     * -- 限定只有在 dev 跟 demo 模式下，才可以使用模擬還杯功能
     */
     function mockReturn() {
        if($mode !== 'dev' && $mode !== 'demo') { return false }

        console.log('count', count)
        count += 1
        if(count === 10) {
            fetch(`${$apiConfig.apiUrl}/mock/return?code=${$apiConfig.apiKey}`, {
                method: 'POST',
                body:JSON.stringify({ gid : $memberInfo.gid })
            })
            .then(response => response.json())
            .then(d => { 
                    console.log(d)
                    if(d.returnCode === '0000') {
                        modal.message = modal.message + ' ('+ d.info.orderId +')'
                        isOpen = true
                    }
                })
        }
    }
</script>

<div>
    <div class="ms-bz-tl">
        <span class="ms-bz-tl-wz">請至設有還杯機台的門市，依照下列步驟還杯</span>
    </div>

    <div class="hb-sm-w-ms">
        <div class="hb-sm-w-ma">
            <div class="hb-sm-w">
                <span class="hb-sm-w-nm">1</span>
            </div>
            <span class="hb-w-mt-t">
                還杯前請先將杯內清空，並至<b style="color:#FF001F">OPEN iECO還杯機</b>前，對準感應器掃描還杯識別碼 QR Code 進行還杯
            </span>
        </div>

        <div class="hb-w-mtts" on:click={openQRCodeModal}>
            <span id="hbmttsmodal" class="hb-w-mtts-btn">
                {#if displayQrcode}
                    <small style="color:#FF001F">請點擊加強亮度</small>

                    <QRCode {text} qrbox=144/>

                    還杯識別碼
                    {#if timeoutSeconds > 0}
                        <small class="text-secondary">此識別碼將於 {timeoutSeconds} 秒鐘後失效</small>
                    {/if}
                {:else}
                    目前沒有需要還杯的訂單
                    <small class="text-secondary">當有需要還杯訂單時才會顯示還杯識別碼</small>
                {/if}
            </span>
        </div>

        <hr>

        <div class="hb-sm-w-ma">
            <div class="hb-sm-w">
                <span class="hb-sm-w-nm">2</span>
            </div>
            <span class="hb-w-mt-t">掃描要歸還循環杯上的條碼</span>
        </div>
        <div class="hb-w-mt" on:click={mockReturn}>
            <img class="barcode-webp" src="/img/group-4@3x.png" alt="">
        </div>

        {#if enableStoreReturn == false}
        <hr>

        <div class="hb-sm-w-ma">
            <span class="hb-w-mt-t">找尋還杯機門市：</span>
        </div>

        <div class="hb-w-mt">
            <a href="/store" class="hb-w-mt-btn">
                <img src="/img/btn-lovefood-location-a.svg" alt="">
                循環杯門市查詢</a>
        </div>
        {/if}
    </div>
</div>

<div id="hbmttsModal" class="hb-mtts-modal" style="display:{isOpenQrcode ? 'block': 'none'}">
    <div class="box" on:click={closeQRCodeModal}>
        <div class="hb-mtts-modal-ctn-wrap">
            <div class="hb-mtts-modal-ctn">
                <span class="hb-mtts-modal-ctn-header"></span>
                <span class="hb-mtts-modal-ctn">循環杯 QR code</span>
            </div>
            <div class="hb-mtts-modal-ctn-nr py-3">
                <span class="hb-mtts-modal-ctn-nr-p">
                    {#if displayQrcode}
                    <span>
                        <QRCode {text} qrbox=144 id={"qrcode-popup"}/>
                    </span>
                    {/if}
                    <span class="hb-mtts-modal-ctn-nr-p">
                        請於還杯機掃描此 QR code
                    </span>
            </div>
        </div>
    </div>
</div>

<Modal {modal} {isOpen} on:closeModal={e => location.href = `/return-cup` }></Modal>