<script>
    import { fade, slide } from 'svelte/transition';
    import { quintOut } from 'svelte/easing';

    export let memberOrders = []
    export let summaryTitle
    export let mode = 'pos'

    let availableRedeemCount = 0
    let expand = false
    let arr = new Array();

    $:{
        availableRedeemCount = 0
        memberOrders.forEach(element => {
            if(mode === 'scanner' && element.payType === 3) { return false }

            element.cpmobiPayLog.filter(el=> el.paidStatus === 0).forEach(el => {      
                var count = el.size === "10L" ? 10 :  el.count

                // 合併統計可兌換杯數
                const index = arr.findIndex(x => x.name === el.name)
                if(index < 0) {
                    arr.push({name : el.name, count : count})
                } else {
                    arr[index].count = arr[index].count + count
                }
                
                availableRedeemCount += count
            })   
        })
    }
</script>

<div class="chg-qtt">
    <div class="chg-flip" on:click={()=> expand = !expand}>
        <span class="chg-qtt-numb">{summaryTitle}</span>
        <div class="chg-qtt-wl">
        {#if !expand}
            <span class="qtt-t-numb" transition:fade>共 
                <span class="qtt-t-numb-c">{availableRedeemCount}</span> 杯
            </span>
        {/if}
            <span class="qtt-d-arrow{expand ? '' : '-reset'} arrow-img">
                <img src="/img/atom-button-dropdown-grey-btn-arrowdowng-n.svg" alt="">
            </span>
        </div>
    </div>
</div>

{#if expand}
<div class="chg-panel" style="display: block;" transition:slide="{{delay: 100, duration: 300, easing: quintOut }}">
    <div class="chg-panel-clm">
        
        {#each arr as item, i }
        <span class="chg-mdm">{item.name}
            <span class="chg-mdm-n">
                <span class="chg-mdm-c">{item.count} </span> 杯
            </span>
        </span>
        {/each}

        <div class="dash-hr"></div>

        <span class="chg-s-n">
            共<span class="chg-s-c">{availableRedeemCount}</span>杯
        </span>

    </div>
</div>
{/if}
   
