<script>
  import { layoutOption, queryStr, locationInfo, mapLoaded } from '../store/inapp-store'
  import LocationSearch from './LocationSearch.svelte';
  import LocationResetButton from './LocationResetButton.svelte';
  import MyMapControl from './MyMapControl.svelte';
  import MyMapView from './MyMapView.svelte';

  const urlParams = new URLSearchParams(window.location.search)
  const rtnUrl = urlParams.get('rtn-url')
  const mode = urlParams.get('m')

  $layoutOption.navTitle = '借還杯門市'
  $layoutOption.navBackUrl =  (rtnUrl === null || rtnUrl === undefined) ?`/home${$queryStr}` : rtnUrl
  $layoutOption.showCarousel = false
  $layoutOption.showBanner = false
  $layoutOption.showHome = true
  $layoutOption.showCancel = false
  $mapLoaded = false
  
  let touchable = false //--使用自訂觸控功能
  let zoomLevel = 17
  let showMapNavbar = mode === 'native'
  let searchLocationInfo = {}

  /**
   * 點擊查詢結果的門市後會觸發此事件
   */
  function onChangeLocation(location) {
    $locationInfo.latitude = location.latitude
    $locationInfo.longitude = location.longitude
    zoomLevel = 19
    searchLocationInfo = location
  }

  /**
   * 點擊重新查詢定位後會觸發此事件
   * @param location
   */
  function onResetLocation(location) {
    $mapLoaded = false
    zoomLevel = 17
    searchLocationInfo = {}
  }
  /**
   * 點選地圖自訂縮放控件後會觸發此事件
   * @param level
   */
  function onChangeZoomLevel(level) {
    zoomLevel = level
  }

  /**
   * 觸摸手勢在地圖上滑動後會觸發此事件
   * @param delta
   */
  function onTouchMove(delta) {
    let powZoomLevel =  Math.pow(2, zoomLevel) 
    $locationInfo.latitude = (parseFloat($locationInfo.latitude) + ((delta.y / 1)/ powZoomLevel))
    $locationInfo.longitude =  parseFloat($locationInfo.longitude) - ((delta.x) / powZoomLevel)
    searchLocationInfo = {}
  }

  $:console.log('$mapLoaded=', $mapLoaded)

</script>

<style>
  .my-map-wrap{
    min-height:calc(100vh + 3.125rem);
  }
</style>

<div class="my-map-wrap" style="visibility:visible;">
  <MyMapView {zoomLevel} {showMapNavbar}></MyMapView>
</div>

<LocationResetButton on:resetLocation={e => onResetLocation(e.detail)}></LocationResetButton>

{#if showMapNavbar === false}
  <LocationSearch {touchable} on:changeLocation={e => onChangeLocation(e.detail)}></LocationSearch>
  <MyMapControl {zoomLevel} {searchLocationInfo} {touchable} on:changeZoomLevel={e => onChangeZoomLevel(e.detail)} on:touchMove={e => onTouchMove(e.detail)} ></MyMapControl>
{/if}

<input type="hidden" class="w-100" id="permission">
<input type="hidden" class="w-100" id="latitude">
<input type="hidden" class="w-100" id="longitude">
