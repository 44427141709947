<script>
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let orderby
    export let title = ''

    let displayFilter = false

    let current = orderby

    $:console.log(orderby)

    function toggleExpiredTime() {
        current = 'expiredtime'
        if(orderby.indexOf('createtime') > 0 ) { 
            orderby = 'expiredtime'
        } else if(orderby.indexOf('-desc') > 0) {
            orderby = 'expiredtime'
        } else {
            orderby = 'expiredtime-desc'
        }

        dispatch('select', orderby);
    }

    function toggleCreateTime() {
        current = 'createtime'
        if(orderby.indexOf('expiredtime') > 0 ) { 
            orderby = 'createtime'
        } else if(orderby.indexOf('-desc') > 0) {
            orderby = 'createtime'
        } else {
            orderby = 'createtime-desc'
        }

        dispatch('select', orderby);
    }

</script>

 <nav class="cd-navbar">
    <div class="mb-2">
        <span style="font-size: 1.0625rem;">{title}</span>
    </div>
    <btn class="cd-rf" onclick="location.href='/redeem'" style="visibility:hidden">
        <!-- <img src="/img/atom-button-glyphs-buttons-refresh-btn-refresh-n.svg" alt=""> -->
    </btn>

    <ul class="cd-nav">
        <li>
            <div class="exd-li cd-list" on:click={toggleExpiredTime}>
                <p class="exd-p cd-p {current == 'createtime' ? 'exd-p-reset' : ''}">到期日</p>
                <div class="exd-img cd-img {current == 'createtime' ? 'exd-img-reset' : ''} {orderby == 'expiredtime-desc' ? 'active' : ''}"></div>
            </div>
        </li>
        <li>
            <div class="doa-li cd-list" on:click={toggleCreateTime}>
                <p class="doa-p cd-p {current == 'createtime' ? 'doa-p-reset' : ''}">取得日</p>
                <div class="doa-img cd-img {current == 'createtime' ? 'doa-img-reset' : ''} {orderby == 'createtime-desc' ? 'active' : ''}"></div>
            </div>
        </li>
    </ul>
</nav>

<div id="uumodal" class="uumodal" style="display: {displayFilter ? 'block' : 'none'}">
    <div class="uumodal-ctn">
        <span class="u-close" on:click={() => displayFilter = false}>篩選：<img src="/img/atom-button-glyphs-buttons-filter-btn-filter-t.svg" alt=""></span>
        <div class="um-ctn-l">
            <btn class="uu-s uu-all">全部</btn>
            <btn class="uu-s uu-nd">一般訂單</btn>
            <btn class="uu-s uu-dd">待取杯訂單</btn>
            <btn class="uu-s uu-wghd">未歸還並扣款訂單</btn>
        </div>
    </div>
</div>