<svelte:head>
    <link rel="stylesheet" href="/starbucks-style.css" />
</svelte:head>

<style>
    :global(.wrap-starbucks){
        color: #fff;
        background: #1d3c34;
        width: 100vw;
    }
</style>