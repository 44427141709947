<script>
    import { createEventDispatcher } from 'svelte';
    import { memberInfo, appSetting, checkFeature, keepFilter } from '../store/inapp-store' 

    const dispatch = createEventDispatcher();

    export let filter 
    export let orderby

    let enableCashPay = checkFeature($appSetting.enableCashPay, $memberInfo.gid, $appSetting.guestList) 
    let enablePurseRefund = checkFeature($appSetting.enablePurseRefund, $memberInfo.gid, $appSetting.guestList) 

    let displayFilter = false
    let filterName = getFilterName()

    function toggleFilter(f) {
        filter = f
        displayFilter = false
        filterName = getFilterName()
        location.href = '/order?f=' + filter + '&s=' + orderby
    }

    function getFilterName() {
        if(filter === 'all') { return '全部' }
        if(filter === 'redeem') { return '待取杯' }
        if(filter === 'cancel') { return '已取消' }
        if(filter === 'need_refund') { return '待退款' }
        if(filter === 'need_refund_wallet') { return '零錢包待退款' }

        if(filter === 'return') { return '已歸還' }
        if(filter === 'no_return') { return '未歸還' }
        if(filter === 'partial_return') { return '部分歸還並退款' }

        if(filter === 'expire') { return '未歸還並扣款' }
        if(filter === 'partial_expire') { return '部分未歸還並扣款' }

        return '一般'
    }

    function toggleCreateTime() {
        if(orderby.indexOf('-desc') > 0) {
            orderby = 'createtime'
        } else {
            orderby = 'createtime-desc'
        }

        location.href = '/order?f=' + filter + '&s=' + orderby
    }
    
    $:console.log(filter)

    function toggleNeedRefund() {
        if(filter === 'need_refund') {
            toggleFilter($keepFilter)
        } else {
            $keepFilter = filter
            toggleFilter('need_refund')
        }
    }
</script>

<nav class="cd-navbar">
    <btn on:click={()=> location.href='/order?f=' + filter} class="cd-rf" style="visibility:hidden">
        <img src="/img/atom-button-glyphs-buttons-refresh-btn-refresh-n.svg" alt="">
    </btn>
    <ul class="cd-nav">
        <li style="{(enableCashPay ? '' :'visibility:hidden')}">
            <button on:click={toggleNeedRefund}
                class="btn wtf-btn cd-list justify-content-center" 
                style="{ filter === 'need_refund' ? 'background-color: rgb(255, 99, 0); border: 1px solid rgba(255, 255, 255, 0.41);' : ''}">
                <p class="cd-p" style="{filter === 'need_refund' ? 'color: white;': 'color: rgb(177, 177, 177);'}">待退款</p>
            </button>
        </li>
        <li>
            <div class="doa-li cd-list" on:click={toggleCreateTime}>
                <p class="doa-p-reset cd-p">取得日</p>
                <div class="doa-img-reset cd-img {orderby == 'createtime-desc' ? 'active' : ''}"></div>
            </div>
        </li>
        <li>
            <a id="alabtn" class="ala-li cd-list" on:click={() => displayFilter = true}>
                <p class="ala-p cd-p">{filterName}</p>
                <img class="ala-img cd-img" src="/img/atom-button-glyphs-buttons-filter-btn-filter-t.svg"
                    alt="" width="24" height="24">
            </a>
        </li>
    </ul>
</nav>
<div class="sfy py-5">
    <ul class="small text-left bc-p">
        <li>保留半年內的交易紀錄</li>
        <li>歸還時不識別杯子尺寸，依訂單時間採先借先還的方式計算歸還數量，因此僅需確認歸還杯子數量是否正確即可。</li>
    </ul>
</div>
<div id="alamodal" class="alamodal" style="display: {displayFilter ? 'block' : 'none'}">
    <div class="alamodal-ctn">
        <span class="u-close">篩選：<img src="/img/atom-button-glyphs-buttons-filter-btn-filter-t.svg"
                alt=""></span>
        <div class="um-ctn-l">
            <btn class="ala-s {(filter === 'all' ? 'active': '')}" on:click={()=> toggleFilter('all')}>全部</btn>
            <btn class="ala-s {(filter === 'need_refund' ? 'active': '')}" on:click={()=> toggleFilter('need_refund')} 
                style="{(enableCashPay ? '' :'display:none')}">待退款</btn>
            <btn class="ala-s {(filter === 'need_refund_wallet' ? 'active': '')}" on:click={()=> toggleFilter('need_refund_wallet')} 
                style="{(enablePurseRefund ? '' :'display:none')}">零錢包待退款</btn>
            <btn class="ala-s {(filter === 'redeem' ? 'active': '')}" on:click={()=> toggleFilter('redeem')}>待取杯</btn>
            <btn class="ala-s {(filter === 'cancel' ? 'active': '')}" on:click={()=> toggleFilter('cancel')}>已取消</btn>
            <btn class="ala-s {(filter === 'return' ? 'active': '')}" on:click={()=> toggleFilter('return')}>已歸還</btn>
            <btn class="ala-s {(filter === 'no_return' ? 'active': '')}" on:click={()=> toggleFilter('no_return')}>未歸還</btn>
            <btn class="ala-s {(filter === 'partial_return' ? 'active': '')}" on:click={()=> toggleFilter('partial_return')}>部分歸還並退款</btn>
            <btn class="ala-s {(filter === 'expire' ? 'active': '')}" on:click={()=> toggleFilter('expire')}>未歸還並扣款</btn>
            <btn class="ala-s {(filter === 'partial_expire' ? 'active': '')}" on:click={()=> toggleFilter('partial_expire')}>部分未歸還並扣款</btn>
        </div>
    </div>
</div>