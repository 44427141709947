<script>
    import { layoutOption, mode, memberInfo, apiConfig, queryStr } from '../store/inapp-store' 
    import { getAuthV } from '../store/openpoint' 
    import { Modal, Loader, Warning } from '../components';
    import { onMount } from 'svelte';

    $layoutOption.showBanner = false
    $layoutOption.showCarousel = false

    let loader = { content : '會員資訊確認中...' };
    let isBlock = true;

    let modal = { 
        title : '循環杯',
        message : '取得會員資訊發生錯誤',
        type: 'alert'
    }
    
    let isOpen = false

    $:console.log('mode' , $mode)

    let timer1
    let count = 0

    onMount(() =>{
        if($memberInfo.foen !== undefined && $memberInfo.foen !== '') {
            
            location.href = '/register-info'

        } else  if($mode === 'app') {
            try {
                getAuthV()   // 向APP發起請求auth_v授權流程
            }catch(e) {
                showDialog(e.msg)
            }

            timer1 = setInterval(checkAuthV, 1000)
            
        } else {
            setTimeout(setMockMemberPhone, 1000)
        } 
    })
    
    /**
     * 檢查是否收到APP回傳的AuthV
    */
    function checkAuthV() {
        if(count > 15) { return showDialog(`取得會員資訊發生逾時`)  }

        // 取得AuthV
        var value = document.getElementById('authV').value;
        console.log('value', value)
        if(value !== '') {
            $memberInfo.authV = value
            clearInterval(timer1)
            getMemberPhone()
        }

        count = count + 1
    }

    $:console.log('member phone' , $memberInfo.foen)

    /**
     * 取得會員手機號碼
     */
    function getMemberPhone() {
        fetch(`${$apiConfig.apiUrl}/member/phone?code=${$apiConfig.apiKey}`, {
            method: 'POST',
            body:JSON.stringify({ gid : $memberInfo.gid, authv : $memberInfo.authV})
        })
        .then(response => response.json())
        .then(d => { 
                console.log(d) 
                if(d.returnCode === '0000') {
                    $memberInfo.foen = d.info.phone
                    location.href = '/register-info'
                } else {
                    isBlock = false
                    isOpen = true
                }
            });
    }

    /**
     * 取得會員手機號碼（不透過AuthV）
     */
    function setMockMemberPhone() {
        $memberInfo.foen = '0910002003'
        location.href = '/register-info'
    }
    
    function showDialog(msg) {
        modal.message  = msg
        isBlock = false
        isOpen = true 
    }
</script>

<input type="hidden" id="authV" bind:value={$memberInfo.authV}>

{#if ($memberInfo.authV !== '')}
<div class="hb-w-mtts">
    <textarea class="hb-w-mtts-btn" style="display: none;">{$memberInfo.authV}</textarea>
</div>
{/if}

<Loader {loader} {isBlock}></Loader>

<Modal {modal} {isOpen} on:closeModal={e => location.href = '/home'+ $queryStr }></Modal>
