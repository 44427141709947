<script>
    import { layoutOption, apiConfig, buInfo, queryStr } from '../store/inapp-store' 
    import { Modal, Loader } from '../components';

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const id = urlParams.get('id')
    const count = parseInt(urlParams.get('c'))

    $layoutOption.navBackUrl = `/return-via-scanner`
    $layoutOption.showBanner = false
    $layoutOption.showCarousel = false
    $layoutOption.navTitle = '歸還完成頁'

    let modal = { title : '還杯失敗',  message : '', type: 'alert'}
    let isOpen = false
    let loader = { content : '還杯作業處理中...' } 
    let isBlock = true
    let info

    let param = {orderId: id, count: count, key: $buInfo.pincode}
    console.log('param=', param)

    fetch(`${$apiConfig.apiUrl}/bu/return?code=${$apiConfig.apiKey}`, {
            method: 'POST',
            body:JSON.stringify(param)
        })
        .then(response => response.json())
        .then(d => {
            console.log(d)
            if(d.returnCode === '0000'){
                info = d.info
                isBlock = false
            } else {
                modal.message = `錯誤代碼：${d.returnCode}，請洽客服專線 0809-090-711`
                isOpen = true
            }
        })
    
</script>

<style>
    .ptd-mdm-qtt-c {
        color: #fd4c01;
    }
    .pay-detial, .ptd-t {
        height: auto;
    }
</style>

{#if info !== undefined}
<div>
    <div class="pay-detial">
        <div class="pay-d-ctn">
            <p class="d-numb">訂單編號：</p>
            <span class="d-numb-t">{info.id}</span>
        </div>
        <div class="pay-d-ctn">
            <p class="d-numb">租借時間：</p>
            <span class="d-numb-t">{info.rentTimeStr}</span>
        </div>
        <div class="pay-d-ctn">
            <p class="d-numb">訂單狀態：</p>
            <span class="d-numb-t">{info.orderStatus}</span>
        </div>
        <div class="pay-d-ctn">
            <p class="d-numb">歸還期限：</p>
            <span class="d-numb-t">{info.expiredTimeStr}</span>
        </div> 
        <!-- <div class="pay-d-ctn">
            <p class="d-numb">租借通路：</p>
            <span class="d-numb-t">{info.businessUnitName}</span>
        </div>     -->
        <div class="pay-d-ctn">
            <p class="d-numb">支付方式：</p>
            <span class="d-numb-t">{info.payType === 3 ? '現金支付' :'線上支付'}</span>
        </div> 

        <div class="pay-d-ctn">
            <p class="d-debit-dl">歸還門市：</p>
            <span class="d-debit-t">{$buInfo.store.storeName}</span>
        </div>

        <div class="pay-d-ctn">
            <p class="d-debit-dl">歸還時間：</p>
            <span class="d-debit-t">
                {info.lastReturnTimeStr}
            </span>

            {#each info.returnInfo as item, i (item)}
            {#if ((item.seq - 1) === i)}
                <span class="d-debit-t" style="color: blue;">
                    此次訂單共歸還 <span class="ptd-mdm-qtt-c svelte-vdepxe">{item.count}</span> 杯完成，
                    {#if info.needReturnCount > 0 }
                    剩下餘 <span class="ptd-mdm-qtt-c" >{info.needReturnCount}</span> 杯未歸還
                    {:else}
                        已全數歸還
                    {/if}
                </span>
            {/if}
            {/each}
        </div>
    </div>

    <div class="ptd">
        <p class="ptd-p">商品內容</p>
    </div>
    
    <div class="ptd-t">
    {#each info.detail as item (item)}
    {#if (item.count > 0)}
        <div class="ptd-t-ctn">
            <p class="ptd-mdm">{item.name}</p>
            <p class="ptd-mdm-qtt"><span class="ptd-mdm-qtt-c">{item.count} </span>杯</p>
        </div>
    {/if}
    {/each} 
    </div>

    <div class="is">
        <p class="is-t">查詢借杯門市：</p>
        <p class="is-p">
            請至循環杯租借首頁 > 查詢循環杯門市 進行查詢，循環杯數量依門市實際庫存為主。
        </p>
    </div>
</div>

<div class="pay-d-fx-btm">
    <a href="{$layoutOption.navBackUrl}" class="pay-d-send submit-a">
        <p class="pay-d-send-p">繼續歸還</p>
    </a>
    <a href="/home{$queryStr}" class="pay-d-send submit-a">
        <p class="pay-d-send-ps">回首頁</p>
    </a>
</div>
{/if}

<Loader {loader} {isBlock}></Loader>

<Modal {modal} {isOpen} on:closeModal={e => location.href = `/home${$queryStr}` }></Modal>
