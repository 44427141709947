<script>  
    import { onMount } from 'svelte'
    import { layoutOption, tempRedeemInfos, mode, apiConfig, memberInfo} from '../store/inapp-store' 
    import { screenLight } from '../store/openpoint' 
    import { Modal, Loader } from '../components';
    import { Swiper, SwiperSlide } from 'swiper/svelte'
    import { Navigation, Pagination } from 'swiper'
    import 'swiper/css'
    import 'swiper/css/navigation'
    import 'swiper/css/pagination'
    import JsBarcode from 'jsbarcode'

    $layoutOption.navBackUrl = `/return-via-pos`
    $layoutOption.navTitle = ''
    $layoutOption.showCarousel = false
    $layoutOption.showCancel = false
    $layoutOption.showHome = false

    let loader = { content : '還杯數量確認中...' };
    let isBlock = true
    let modal = { title : '還杯', message : '模擬還杯成功', type: 'alert'}
    let isOpen = false
    let clickCount = 0
    let includeOfflineOrder = false
    let includeOnlineOrder = false

    $:console.log('tempRedeemInfos', $tempRedeemInfos)

    onMount(()=> {
        let param = []
        $tempRedeemInfos.forEach(order => {
            if(order.cpmobiPayLog === undefined) { return false }
            var item = { orderId : order.id, barcodeKeys :[] }
            order.cpmobiPayLog.forEach(el => {
                item.barcodeKeys.push(el.id)
            })
            param.push(item)
        })

        // 確認條碼
        fetch(`${$apiConfig.apiUrl}/confirm/barcode?code=${$apiConfig.apiKey}`, {
            method: 'POST',
            body:JSON.stringify(param)
        })
        .then(response => response.json())
        .then(d => {
            console.log(d)
            if(d.returnCode === '0000') {
                
                // 更新訂單條碼資訊
                $tempRedeemInfos = d.info

                isBlock = false

                setTimeout(function(){
                    // 產生三段條碼
                    let options = { fontsize: 10, margin: 3, height: 40, width: 0.75, format: "CODE39" }
                    var barcodeCount = 0
                    $tempRedeemInfos.forEach(order => {
                        order.cpmobiPayLog.forEach(el => {
                            JsBarcode(`#code${el.id}b1`, el.barcode1 , options)
                            JsBarcode(`#code${el.id}b2`, el.barcode2 , options)
                            JsBarcode(`#code${el.id}b3`, el.barcode3 , options)

                            barcodeCount = barcodeCount + 1
                        })
                    })

                    console.log('barcodeCount', barcodeCount)

                    // 確認條碼後，假如無可提供刷讀的條碼（表示已核銷或已過期），就直接返回門市還杯首頁
                    if(barcodeCount === 0) {
                        location.href = '/return-via-pos'
                    }

                }, 500)
            } 
        })

        // 是否顯示線上支付/到店支付說明文案
        includeOfflineOrder = $tempRedeemInfos.filter(el=> el.payType === 3).length > 0
        includeOnlineOrder = $tempRedeemInfos.filter(el=> el.payType !== 3).length > 0
    })

    /**
     * 模擬取杯功能
     * -- 限定只有在 dev 跟 demo 模式下，才可以使用模擬還杯功能
     */
    function mockRedeem(key) {
        if($mode !== 'dev' && $mode !== 'demo') { return false }
        console.log('click', clickCount)
        clickCount += 1
        if(clickCount === 10) {
            fetch(`${$apiConfig.apiUrl}/mock/redeem?code=${$apiConfig.apiKey}`, {
                method: 'POST',
                body:JSON.stringify({ id : key ,seq : 1})
            })
            .then(response => response.json())
            .then(d => { 
                console.log(d) 
                isOpen = true
                clickCount = 0
            })
        }
    }

    try{
        screenLight(true)
    }catch(e){
        console.log(e)
    }
</script>

<div class="mb-5">
    {#if (isBlock === false)}
     <Swiper 
        centeredSlides={true} 
        slidesPerView={1.2} 
        loop={false} 
        modules={[Navigation,Pagination]} 
        pagination={{
            el: ".swp-pag-nm",
            type: "fraction",
        }} 
        navigation={{
            nextEl: ".swp-btn-n",
            prevEl: ".swp-btn-b",
        }}
        class="swiper-container barcode-container" >

        {#each $tempRedeemInfos as order }
        {#each order.cpmobiPayLog as item }

        <SwiperSlide class="barcode-swp-slider">
            <div class="swp-cnt">
                
                <btn on:click={()=> location.href = $layoutOption.navBackUrl }>
                    <img class="barcode-close" src="/img/atom-button-glyphs-buttons-close-btn-close-n.svg" alt="">
                </btn>
                <h4 class="pt-4" style="color:#FF001F">請於有還杯標籤的門市</h4>
                <h1 class="pt-1" style="color:#FF001F">請門市回收杯子</h1>
                <p class="barcode-t pt-1">{item.name}還杯條碼</p>
                <div class="mb-4" on:click={()=> mockRedeem(item.id)}>
                    <p class="pt-2"><svg id="code{item.id}b1" height="68px"></svg></p>
                    <p class="pt-2"><svg id="code{item.id}b2" height="68px"></svg></p>
                    <p class="pt-2"><svg id="code{item.id}b3" height="68px" ></svg></p>    
                </div>
                <h6 class="text-left font-weight-normal mb-0">
                    <span>訂單編號：</span><span>{item.orderId}</span>
                </h6>
                <h6 class="text-left font-weight-normal mb-0">
                    <span>支付方式：</span><span>{order.payType === 3 ? '到店支付' : '線上支付'}</span>
                </h6>
                <h6 class="text-left font-weight-normal mb-0">
                    <span>商品內容：</span>
                    <span>{item.name.split('1')[0].split('2')[0].split('3')[0]} 
                        <span style="color: var(--orange);">{order.redeemCount}</span> 杯
                    </span>
                </h6>
                <h6 class="text-left font-weight-normal mb-4">
                    <span>未還杯數：</span><span> {order.redeemCount - order.returnCount} 杯(已還 {order.returnCount} 杯)</span>
                </h6>

                {#if (order.payType === 3)}
                <div class="px-5" style="bottom: 0;left: 0;width: 100%;">
                    <button class="creatCode w-100 py-2 border-top" style="background: unset;border:0 ;"
                        on:click={()=> location.href = `/refund-barcode?id=${order.id}&rtn-url=/return-barcode`}>
                        <span style="color: var(--orange);font-size: 1.0625rem;font-weight: 500;">產生退款條碼</span>
                    </button>
                </div>
                {:else}
                <h6 class="text-left font-weight-normal mb-0">
                    <span>本次歸還杯數： </span><span>{item.count} 杯</span>
                </h6>
                <h6 class="text-left font-weight-normal mb-3 d-flex justify-content-start align-items-center" style="gap: 0.3125rem;">
                    <span>線上退款金額： </span>
                    <img src="/img/atom-icon-currency-ic-ntd-large.svg" alt="">
                    <span class="h5 mb-0 font-weight-bolder" style="color: var(--orange);">{item.count * order.deposit / order.totalCount}</span>
                    <span style="color: var(--orange);">元</span>
                </h6>
                {/if}
            </div>   
        </SwiperSlide>
        {/each}
        {/each}
    </Swiper>
    {/if}

    <div class="bc-p-cnt mx-auto mb-5">
        <ol class="small text-left bc-p">
            {#if (includeOfflineOrder)}
            <li>刷讀條碼與歸還杯子後，採到店支付部分可點擊【產生退款條碼】按鈕後，先進行退款。</li>
            {/if}
            <li>可於「紀錄查詢」確認是否還杯與退款完成。</li>
            {#if includeOnlineOrder}
            <li>刷讀條碼與歸還杯子後，採線上支付的預付款項將於7-10個日曆天內退還至您支付該筆金額原支付的帳戶。</li>
            {/if}
        </ol>
    </div>
</div>

<div class="btn-swp-wrap">
    <div class="swiper-button-prev swp-btn-b" style="color:transparent"></div>
    <div class="swiper-pagination swp-pag-nm"></div>
    <div class="swiper-button-next swp-btn-n " style="color:transparent"></div>
</div>

<Modal {modal} {isOpen}></Modal>
<Loader {loader} {isBlock}></Loader>