<script>
   import { createEventDispatcher } from 'svelte';

   const dispatch = createEventDispatcher();
   
   export let item
   export let filter

   function redirectMap () {
       location.href = '/store-emap?q=' + item.address + '&f=' + filter + '&p=' + item.hasPOS + '&m=' + item.hasMachine
   }
</script>

<div class="lcontent">
    <div class="lc-label-ol">
        <label for="lc-a">
            <div class="lc-ol" style="width: 70vw">
                <h4>{item.storeName} ({item.storeId})</h4>
                <p>{item.address}</p>
            </div>
            <img class="i-img" src="/img/btn-lovefood-location-a.svg" style="width: 40px" alt="" on:click={redirectMap}>
        </label>
    </div>
</div>