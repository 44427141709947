<script>
   import { createEventDispatcher } from 'svelte';

   const dispatch = createEventDispatcher();
   export let selectedSize
   export let item
   let editingItem = { ...item };
   let editingCount = editingItem.count

   const minAmount = 1
   const maxAmount = item.cup === 1 ? 3 : 1
   function minusOne() {
        if(editingCount - 1 >= minAmount) { 
            editingCount -= 1 
            updateItem ()
        }
   }

   function plusOne() {
        if(editingCount + 1 <= maxAmount) {
            editingCount += 1   
            updateItem ()
        }
   }

   function updateItem () {
       if(editingCount <= minAmount) { editingCount = minAmount }
       if(editingCount >= maxAmount) { editingCount = maxAmount }
       editingItem.count = editingCount 
       item = { ...item, ...editingItem } 
       dispatch('update', item);
   }

   function changeSize() {
     selectedSize = item.size
     console.log('## changeSize', item.size)
     editingCount = 1
     updateItem () 
   }

</script>

<style>
    input[name="num1"] {
      vertical-align: middle;
      -moz-appearance: textfield;
      margin: 0;
      text-align: center;
    } 
</style>

<div class="lcontent">
    <div class="lc-label-ol" on:click={changeSize}>
        <label for="lc-a">
            <div class="lc-label">
                <input type="radio" id="lc-a" name="drone" bind:group={selectedSize} value="{item.size}" class="a-in">
                <span class="a-sn">
                    <img src="/img/atom-button-control-radio-button-btn-radio-t.svg" alt="">
                </span>
            </div>

            <div class="lc-ol">
                <h4>{item.name}</h4>
                <p>{item.description}</p>
            </div>
        </label>
    </div>
    <div class="nice-number {editingCount > 0 ? '' : 'num-opacity'}">
        <div class="nice-number">
            <div class="nice-number-button qtyminus" id="btn-minus-{item.size}" on:click={minusOne} disabled={editingCount === 0}>
                <span></span>
            </div>

            <input type="number" name="num1" bind:value="{editingCount}" style="max-width: 28px">
            
            <div class="nice-number-button qtyplus" id="btn-add-{item.size}" on:click={plusOne} disabled={editingCount === 0}>
                <span></span>
            </div>
        </div>
    </div>
</div>