<script>  
    import { onMount } from 'svelte'
    import { apiConfig, memberInfo, tempSplitInfos } from '../store/inapp-store' 
    import { RefundQRCode } from '../components';
  
    export let item
    export let i

    let barcode = { barcode1: '', barcode2: '', barcode3: '' }
    let showQRCode = false
    
    onMount(()=> {
        // [2023-11-15] 依條碼頁面排序，稍微延長條碼的取得時間，以避免搶號造成Id重覆導致條碼產生失敗。
        setTimeout(getbarcode, i * 1000)
    })

    function getbarcode() {
        // 取得還杯條碼
        fetch(`${$apiConfig.apiUrl}/starbucks/barcode?code=${$apiConfig.apiKey}&mid=${$memberInfo.mid}&returnCount=${item.returnCount}&id=${item.orderId}`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(d => {
            // console.log(d)
            if(d.returnCode === '0000' && d.info.length > 0) {
                barcode = d.info[0].cpmobiPayLog[0]
                showQRCode = true
            } 
        })
        .catch(e => {
            console.log(e)
        });
    }
</script>

<div class="barcode-box" style="margin: 0px 108px 30px;border-radius: 16px;padding: 12px;" >
    <img src="img/barcode-hidden.jpg" alt="" style="display:{showQRCode ? 'none' : 'block'}">
    
    {#if showQRCode}
    <RefundQRCode barcode1={barcode.barcode1} barcode2={barcode.barcode2} barcode3={barcode.barcode3} qrbox={195} id={barcode.barcode2}></RefundQRCode>
    {/if}
</div>
