<script>  
    import { onMount } from 'svelte'
    import { layoutOption, tempRedeemInfos, mode, apiConfig, memberInfo, tempSplitInfos} from '../store/inapp-store' 
    import { Swiper, SwiperSlide } from 'swiper/svelte'
    import { Navigation, Pagination } from 'swiper'
    import ReturnBarcodeItem from './ReturnBarcodeItem.svelte';
    import 'swiper/css'
    import 'swiper/css/navigation'
    import 'swiper/css/pagination'

    $layoutOption.navBackUrl = ''
    $layoutOption.navTitle = ''
    $layoutOption.showBanner = false
    $layoutOption.showCarousel = false

    $:console.log('tempSplitInfos', $tempSplitInfos)
</script>

<div>
    <Swiper 
        centeredSlides={true} 
        loop={false} 
        modules={[Navigation,Pagination]} 
        pagination={{
            el: ".swp-pag-nm",
            type: "fraction",
        }} 
        navigation={{
            nextEl: ".swp-btn-n",
            prevEl: ".swp-btn-b",
        }}
        class="swiper-container" >

        {#each $tempSplitInfos as item, i (i)}
            <SwiperSlide class="barcode-swp-slider w-100">
                <div class="main barcode" style="margin-bottom: 50px;">
                    <h1>請門市回收 {item.returnCount} 個杯子 </h1>
                    <ReturnBarcodeItem {item} {i}></ReturnBarcodeItem>
                    <ul>
                        說明
                        <li>全部還杯條碼掃碼完成後，即可關閉頁面</li>
                        <li>還杯後系統依原支付方式進行退款</li>
                    </ul>
                    <img src="img/7-1.gif" alt="">
                    <h3>環保永續，為地球盡一份心力！</h3>
                </div>
            </SwiperSlide>
        {/each}
    </Swiper>
</div>

<div class="fixed-footer-wrap btn-swp-wrap">
    <div class="swiper-button-prev swp-btn-b" style="color:transparent"></div>
    <div class="swiper-button-next swp-btn-n" style="color:transparent"></div>
    <div class="swiper-pagination swp-pag-nm"></div>
</div> 