import { writable } from 'svelte/store';
import { localStore } from './localStore'

// 後端API相關參數 
const apiOpt = {
    apiUrl: 'https://recyclecup-function-uat.azurewebsites.net/api',
    apiKey: 'X/1sT1aU5j6URttULpUpQm9JEBzUaHJVK2BPDpLhgv0rMdgnCNapvQ==',
    storageEndpoint: 'https://recyclecupteststorage.blob.core.windows.net'
}
const apiConfig = localStore('api-configuration-3', apiOpt)

// 前端串接入口相關
const queryStr = localStore('query-string', '')
const appVer = localStore('app-version', 'NULL')
const mode = localStore('app-mode', '')

// 頁面佈局相關
const layoutOpt = {
    navTitle : '循環杯',
    navBackUrl: '',
    showHome: false, 
    showCancel: false,
    showCarousel: true,
    showBanner: false,
}
const layoutOption = localStore('layout-options', layoutOpt)
const skipInfo = localStore('can-skip-infomation', false)

// 會員資訊
const mInfo = {
    gid: '',
    authV: '',
    tel: '',
    foen: '',
    mid:''
 }
const memberInfo = localStore('op-member-info-mid-a', mInfo)

// 表單相關
const valid = localStore('form-valid', false)
const newOrderId =  localStore('nu-order-id', 'NULL')

// 兌換取杯相關
const tempRedeemInfos = localStore('tmp-redeem-infos', [])
const tempSplitInfos = localStore('tmp-split-infos', [])
const buInfo = localStore('bu-qrcode', {})

// 交易紀錄相關
const keepFilter = localStore('keep-filter', 'all')

// 門市地圖定位資訊
const locationInfo = localStore('location-info', {})
const storeInfos = localStore('location-store-list', {})
const mapLoaded = localStore('map-loaded', {})

// 環境設定
const appSetting = localStore('app-setting', {})

/**
 * 取得日期字串
 * @param {*} d 
 * @returns 
 */
export function getDateStr(d) {
    return d.split('T')[0].replaceAll('-','/')
}

/**
 * 取得時間字串
 * @param {*} d 
 * @returns 
 */
export function getTimeStr(d) {
    return d.split('T')[1].split('.')[0]
}

/**
 * 檢查是否開放特定功能
 */
export function checkFeature(enabled, gid, list) {
    if (enabled === true) { return true }

    let guestLis = list
    let matchGID = guestLis.find(el=> el === gid) 
    console.log('matchGID' , matchGID)

    return matchGID !== undefined
}

export { 
    apiConfig,
    queryStr, 
    appVer, 
    mode,
    layoutOption, 
    skipInfo, 
    memberInfo,
    valid, 
    newOrderId, 
    tempRedeemInfos,
    tempSplitInfos,
    buInfo,
    keepFilter,
    locationInfo,
    storeInfos,
    mapLoaded,
    appSetting,
};