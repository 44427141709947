<script>
    import { onMount } from 'svelte'
    import { layoutOption, memberInfo, appSetting, apiConfig, tempRedeemInfos } from '../store/inapp-store' 
    import { screenLight } from '../store/openpoint' 
    import { Modal, Loader, StarbucksQRCode } from '../components';
    import ReturnItem from './ReturnItem.svelte'

    const urlParams = new URLSearchParams(window.location.search)
    const rtnUrl = urlParams.get('rtn-url')

    $layoutOption.navTitle = '還杯'
    $layoutOption.navBackUrl =  (rtnUrl === null || rtnUrl === undefined) ?`/return-cup` : rtnUrl
    $layoutOption.showCarousel = false
    $layoutOption.showCancel = false
    $layoutOption.showHome = true

    let needReturnOrders = []
    let needReturnCount = 0
    let minAmount = 0
    let maxAmount = 0
    let editingCount = 0
    let isOpenQrcode = false

    function minusOne() {
        if(editingCount - 1 >= minAmount) { 
            editingCount -= 1 
        }
    }
    function plusOne() {
        if(editingCount + 1 <= maxAmount) {
            editingCount += 1   
        }
    }

    fetch(`${$apiConfig.apiUrl}/order?code=${$apiConfig.apiKey}&gid=${$memberInfo.gid}`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(d => {
                console.log(d)
                if(d.returnCode === '0000') {
                    needReturnOrders = d.info
                        .filter(el=> el.orderStatusShort === '未歸還' || el.orderStatusShort === '部分歸還並退款' 
                            ||  el.orderStatusShort === '待退款'|| el.orderStatusShort === '零錢包待退款')
                        .filter(el=> el.redeemCount > el.returnCount)
                        .filter(el=> el.payStatus > 0)

                    // 可還杯數量加總
                    var arr = needReturnOrders.map(el => el.redeemCount - el.returnCount)
                    needReturnCount = arr.length === 0 ? 0 : arr.reduce((a,b)=>a+b)
                    maxAmount = needReturnCount
                    
                } 
        })
        .catch(e => {
            console.log(e)
        });

    // 增加呼叫查詢和全豐訂單，以重置和全豐系統的訂單逾期時間。
    onMount(()=> {
        fetch(`${$apiConfig.apiUrl}/starbucks/order?code=${$apiConfig.apiKey}&mid=${$memberInfo.gid}`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(d => {
                console.log(d)
                if(d.returnCode === '0000') {                                        
                    // TODO: 什麼也不做
                } 
        })
        .catch(e => {
            console.log(e)
        });
    })



    function checkCupCount() {
        if( editingCount === 0 ) { return false }

        // 產生還杯QRCode
        editingCount = editingCount
        isOpenQrcode = true
        screenLight(true)
    }

    function closeQRCodeModal() {
        try{
            screenLight(false)
        }catch(e){
            console.log(e)
        }        

        location.href = '/return-cup'
    }
</script>

<div>
    <div class="px-3 pt-3" style="padding-bottom:0.75rem;">
        <h6 class="font-weight-bolder">星巴克門市櫃台歸還</h6>
        <ul class="small text-left bc-p">
            <li>目前並非所有星巴克門市都有提供循環杯借杯與還杯服務，請先查詢循環杯門市。</li>
            <li>歸還時不識別杯子尺寸，依訂單時間採先借先還的方式計算歸還數量，因此僅需確認歸還杯子數量是否正確即可。</li>       
        </ul>
    </div>

    <div class="px-3" style="padding-bottom:1.5rem;">
        <div class="card border-0" style="border-radius: 0.75rem;">
            <div class="card-body">
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <div class="d-flex flex-column">
                        <span>本次還杯數量</span>
                        <span class="small" style="color: #7a7a7a;">每次歸還上限 <span style="color:#FF001F;">{maxAmount}</span>
                            杯</span>
                    </div>
                    <div class="lease border-0 shadow-none p-0 m-0">
                        <div class="lcontent">
                            <div class="nice-number">
                                <div class="nice-number-button qtyminus" on:click={minusOne} 
                                    style={editingCount === 0 ? '' : 'border: solid 1px #fcd04c;background-image: linear-gradient(to bottom, #fda544 3%, #f06e37 99%),linear-gradient(to bottom, #1dbee4, #00a0fd);'}><span></span></div>
                                <input id="returnNum" class="px-3" style="min-width: 5rem;" type="number"
                                    name="num1" onkeypress="isInputNumber(event)" value="{editingCount}" min="1" max="99"
                                    disabled>
                                <div class="nice-number-button qtyplus" on:click={plusOne}><span></span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <span>
                    <btn class="btn down-bar border text-center w-100 py-1 font-weight-normal"
                        style="border-color: #aaaaaa;border-radius: 0.5rem;color: var(--orange);background-image: linear-gradient(to bottom, #f6f6f6 2%, #e9e9e9);"
                        on:click={()=> editingCount = maxAmount}>
                        全部歸還
                    </btn>
                </span>
            </div>
        </div>
    </div>

    <div class="px-3" style="padding-bottom:0.75rem;">
        <h6 class="font-weight-bolder">未歸還紀錄：共 <span style="color: red;">{needReturnCount}</span> 杯</h6>
    </div>

    <div class="pdd-cr"></div>
</div>

<button class="btn-orange {editingCount === 0 ? 'disabled' : ''}" on:click={checkCupCount}><span>確認還杯數量</span></button>

{#if isOpenQrcode}
<div id="hbmttsModal" class="hb-mtts-modal" style="display:{isOpenQrcode ? 'block': 'none'}">
    <div class="box" on:click={closeQRCodeModal}>
        <div class="hb-mtts-modal-ctn-wrap">
            <div class="hb-mtts-modal-ctn">
                <span class="hb-mtts-modal-ctn-header"></span>
                <span class="hb-mtts-modal-ctn">循環杯 QR code</span>
            </div>
            <div class="hb-mtts-modal-ctn-nr">
                <span class="hb-mtts-modal-ctn-nr-p">
                    <h5 class="pt-1" style="color:#FF001F">門市人員請確認回收 {editingCount} 杯數量是否正確 </h5>
                    <span>
                        <StarbucksQRCode gid={$memberInfo.gid} count={editingCount}/>
                    </span>
                    <span class="hb-mtts-modal-ctn-nr-p pb-3">
                        請於星巴克櫃台掃描此QRCode
                    </span>
            </div>
        </div>
    </div>
</div>
{/if}