<script>
  import { appVer, mode, locationInfo, mapLoaded } from '../store/inapp-store'
  import { setLocationPermission, getLocation } from '../store/openpoint';
  import { Modal, Loader } from '../components'
  import { onMount } from 'svelte'
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  let timer
  let checkInterval = 500   // 查詢定位時間間隔(毫秒)
  let checkTimeout = 15000  // 查詢定位逾時(毫秒)
  let checkDelay = 0

  let isOpen = false
  let modal = { title : '借還杯門市', message : '請更新至最新版本已取得定位資料，將以預設位置顯示', type: 'alert' }

  let loader = { content : '定位查詢中...' };
  let isBlock = true;

  let defaultLatitude = '25.079807'
  let defaultLongitude = '121.5699643'
  let feed = 1

  let allowGetMyLocation = true
  let supportGetLocation = false

  let buttonType = 'float'

  onMount(() =>{
    if(checkVersion($appVer)) {
      supportGetLocation = true
      resetLocation(defaultLatitude, defaultLongitude)
      getMyLocation()
    } else {
      isOpen = true
    }    
  })

  /**
   * 監聽Google地圖iframe的onload事件
   */
  function listenMapLoaded() {
    // 監聽Google地圖iframe的onload事件
    var iframe = document.getElementById('my-map');
    if (iframe.attachEvent){
      iframe.attachEvent("onload", iframeLoaded);
    } else {
      iframe.onload = iframeLoaded;
    }
  }

  /**
   * 當iframe載入完畢後觸發的函數
   */
  function iframeLoaded() {
    // 等待地圖載入完成後，才關閉loading頁
    isBlock = false 
    $mapLoaded = true
  }

  /**
   * 檢核版本號 (>=5.46.0)
   * @param version
   */
   function checkVersion(version) {
    const [major, minor, patch] = version.split('.').map(Number);
    if (major > 5) { return true }
    if (major === 5 && minor >= 46) { return true }
    if (major === 5 && minor === 46 && patch > 0) { return true }

    isBlock = false
    return false;
  }

  /**
   * 重新取得座標點位置 
   */
   function getMyLocation() {
    isBlock = true
    if($mode !== 'app') { setInterval(mockLocationResult, 100) }
    if(timer !== undefined) { clearInterval(timer) }
    timer = setInterval(checkLocationResult, checkInterval)
    getLocation()
  }

  /**
   * 清除畫面的座標點位置
   */
  function resetLocation(lat, lon) {
    console.log('resetLocation lat= ', lat) 
    console.log('resetLocation lon= ', lon) 
    $locationInfo.latitude = lat === '' ? defaultLatitude : lat
    $locationInfo.longitude = (lon === '' ? defaultLongitude : lon) + feed // 透過自動微調url實現強制Reload地圖效果
    feed ++

    dispatch('resetLocation', $locationInfo)
  }

  $:console.log('$locationInfo', $locationInfo)

  /**
   * 檢核取得定位座標結果(定時)
   */
  function checkLocationResult() {
    // 取得定位座標結果逾時檢查：
    // 若超過指定時間內APP未回傳座標點，就跳出提示訊息
    checkDelay += checkInterval
    if(checkDelay > checkTimeout) { 
      checkDelay = 0
      clearInterval(timer)
      isBlock = false
      modal = { title : '借還杯門市', message : '無法取得定位，請稍候再試', type: 'alert', isOpen: true } 
      return false
    }

    let permission = document.getElementById('permission').value 
    console.log('#### permission=',permission)
    if(permission === '') { return false }

    allowGetMyLocation = permission == 'true'
    if(!allowGetMyLocation) {
      modal = { title : '借還杯門市', message : '請確認目前定位權限狀態並開啟定位', type: 'alert', isOpen: true }
      isBlock = false;
    }

    let lat = document.getElementById('latitude').value
    let lon = document.getElementById('longitude').value

    // 註：因座標回傳時間略晚於取得定位權限時間，故加上此判斷條件，
    // 以免在還未紀錄座標前就先清除timer
    if((lat !== '' && lon !== '') || !allowGetMyLocation) {
      resetLocation(lat, lon)
      console.log('timer=', timer)
      clearInterval(timer)
      listenMapLoaded()
    }
  }

  function mockLocationResult() {
    document.getElementById('permission').value = 'true'
    document.getElementById('latitude').value = defaultLatitude
    document.getElementById('longitude').value = defaultLongitude
  }

  function onCloseModal() {
    if(supportGetLocation) {
      setLocationPermission()
    } else {
      resetLocation(defaultLatitude, defaultLongitude)
    }
  }
</script>

<style>
.btn-wrap {
    position: fixed;
    right: 0px;
    width: 20%;
    height: 100px;
    max-width: 420px;
    bottom: 56px;
    z-index: 96;
    cursor:pointer;
}

.my-location {
    position: absolute;
    text-align: center;
    bottom: 24px;
    right: 24px;
    background-color: #fff;
    background-size: 24px;
    background-position: center;
    background-repeat:no-repeat;
    width: 50px;
    height: 50px;
    border-radius: 28px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 0;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

{#if buttonType == 'float'}
<div class="btn-wrap">
    <button class="my-location center" on:click={getMyLocation} 
          style="display: block;background-image: url('../img/img-location-{allowGetMyLocation ? 'searching' : 'disabled'}.png');">
    </button>  
</div>
{:else}
    {#if allowGetMyLocation}
    <button class="btn-orange" on:click={getMyLocation} style="display: block;">
        <span>重新整理</span>
    </button>
    {:else}
    <button class="btn-orange disabled" on:click={getMyLocation} style="display: block;"><span>請開啟定位</span></button>
    {/if}
{/if}

<Modal {modal} {isOpen} on:closeModal={onCloseModal}></Modal>
<Loader {loader} {isBlock}></Loader>