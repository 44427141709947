{#if (appConfigJS !== '')}
<LibLoader url={appConfigJS} on:loaded="{onLoaded}" />
{/if}
<script>
  import API from '../store/config';
  import { memberInfo, layoutOption, queryStr, apiConfig, appVer, mode, skipInfo, appSetting } from '../store/inapp-store' 
  import { Modal, Loader, Warning, LibLoader } from '../components';
 
  let appConfigJS = '' //-- 取得系統開放設定JS所在位置

  let validate = false

  $layoutOption.navTitle = ''
  $layoutOption.navBackUrl = ''
  $layoutOption.showCarousel = validate
  $layoutOption.showBanner = false
  $layoutOption.showHome = false
  $layoutOption.showCancel = false

  let loader = { content : '服務確認中...' };
  let isBlock = true;

  let warning = { message : []  , small: [] }

  
  const queryString = window.location.search
  $queryStr = queryString

  const urlParams = new URLSearchParams(queryString)
  const cipherText = urlParams.get('ct')
  const appVersion = urlParams.get('v')
  $appVer = appVersion

  const appMode = urlParams.get('mode')
  if(appMode !== 'dev' && appMode !== 'demo') {
    $mode = 'app'
  } else {
    $mode = appMode
    $skipInfo = false
  }

  if($mode === 'dev') {
    $apiConfig.storageEndpoint = 'https://recyclecupteststorage.blob.core.windows.net'
  } else if($apiConfig.storageEndpoint === ''){
      fetch(`${API}/apiUrl`, {
        method: 'GET',
      })
      .then(response => response.json())
      .then(d => {
        // console.log(d)
        $apiConfig = d
        appConfigJS = `${$apiConfig.storageEndpoint}/script-container/app-config.js?d=${new Date().getTime()}`
        location.reload()
      });
  }

  $:console.log('### appConfigJS',appConfigJS)

  if($apiConfig.storageEndpoint !== '') {
    appConfigJS = `${$apiConfig.storageEndpoint}/script-container/app-config.js?d=${new Date().getTime()}`
  }

  // 取得會員GID
  prepareMemberMID()

  /**
   * 取得會員GID
   */
  function prepareMemberMID() {

    fetch(`${$apiConfig.apiUrl}/decrypt?code=${$apiConfig.apiKey}`, {
      method: 'POST',
      body:JSON.stringify({cipherText : cipherText, appVersion : appVersion })
    })
    .then(response => response.json())
    .then(d => { 
          console.log(d) 
          if(d.mid === '') {
              // 如果無法解析入口參數，就引導至不提供服務畫面
              warning =  { message : ['取得會員資訊發生錯誤', '請稍候再試']  , small: [] }
          } 

          $memberInfo.mid = d.mid

          onLoaded()
      });
  }

  $:console.log('### mid', $memberInfo.mid)

  /**
   * 載入系統開放設定後會觸發此事件
   */ 
   function onLoaded() {
    console.log(appConfig)
    isBlock = false
    validate = $memberInfo.mid !== ''
  }
</script>

{#if (validate)}
  <div>
    <div class="px-3 pt-3" style="padding-bottom:0.75rem;">
      <h6 class="font-weight-bolder" style="color: red;">MID: {$memberInfo.mid}</h6>
      <h6 class="font-weight-bolder" style="color: red;">APP版號: {$appVer}</h6>
    </div>
  </div>
{:else}
  <Warning {warning}></Warning>
{/if}

<Loader {loader} {isBlock}></Loader> 

<div class="pay-d-fx-btm">
  <button class="pay-d-send submit-a" on:click={()=> location.href = '/starbucks-light?action=light'}>
      <p class="pay-d-send-p">調高手機亮度</p>
  </button>
  <button href="/redeem" class="pay-d-send submit-a" on:click={()=> location.href = '/starbucks-camera?action=openCamera'}>
      <p class="pay-d-send-ps">開啟相機鏡頭</p>
  </button>
</div>