<script>
  import { skipInfo,layoutOption, queryStr } from '../store/inapp-store' 
  import { Information } from '../home';

  console.log('### skipInfo', $skipInfo )

  $layoutOption.navTitle = '登記借杯'
  $layoutOption.navBackUrl = `/home${$queryStr}`
  $layoutOption.showHome = true
  $layoutOption.showCarousel = false
  $layoutOption.showBanner = false
  
  $skipInfo =  $skipInfo == 'false' ? false : $skipInfo

  let checkSkipInfo = false
  
  if($skipInfo == true) {
    forward()
  }

  function forward() {
    if(checkSkipInfo == true) { 
        $skipInfo = true 
    }

    location.href = 'register'
  }

  $:console.log('checkSkipInfo',checkSkipInfo)
</script>

{#if ($skipInfo == false)}
<Information>
  <div class="ipt-checkbox">
    <input type="checkbox" id="il-ipt" name="il-ipt"  bind:checked={checkSkipInfo}/>
    <label for="il-ipt"><span></span></label>
    <span class="il-ipt-p">下次不要再顯示</span>
  </div>
</Information>
<div class="pdd-il"></div>
<div class="il-fx-btm">
  <button class="il-send submit-a active" on:click={forward}>下一步</button>
</div>
{/if}