<script>
    import { layoutOption, memberInfo, appSetting, apiConfig, checkFeature, queryStr } from '../store/inapp-store' 

    $layoutOption.navTitle = '還杯'
    $layoutOption.navBackUrl = `/home${$queryStr}`
    $layoutOption.showCarousel = false

    let needReturnCount = -1

    let enableStoreReturn = checkFeature($appSetting.enableStoreReturn, $memberInfo.gid, $appSetting.guestList) 
    if(enableStoreReturn === false) {
        location.href = '/return-via-machine'
    }

    let enableStarbucksPOSReturn = checkFeature($appSetting.enableStarbucksPOSReturn, $memberInfo.gid, $appSetting.guestList) 
    let enableBUReturn = checkFeature($appSetting.enableBUReturn, $memberInfo.gid, $appSetting.guestList) 

    fetch(`${$apiConfig.apiUrl}/order?code=${$apiConfig.apiKey}&gid=${$memberInfo.gid}`, {
        method: 'GET'
    })
    .then(response => response.json())
    .then(d => {
            console.log(d)
            if(d.returnCode === '0000') {
                var needReturnOrders = d.info
                    .filter(el=> el.orderStatusShort === '未歸還' || el.orderStatusShort === '部分歸還並退款' 
                        ||  el.orderStatusShort === '待退款' || el.orderStatusShort === '零錢包待退款')
                    .filter(el=> el.redeemCount > el.returnCount)
                    .filter(el=> el.payStatus > 0)
                
                // 可還杯數量加總
                var arr = needReturnOrders.map(el => el.redeemCount - el.returnCount)
                needReturnCount = arr.reduce((a,b)=>a+b)
            } 
    })
    .catch(e => {
        console.log(e)
    });
</script>

{#if enableStoreReturn}
<div>
    <div class="p-3">
        <div class="mb-2">
            <span style="font-size: 1.0625rem;">還杯方式</span>
        </div>

        <div class="card border-0 mb-3" style="border-radius:0.75rem;">
            <div class="card-body d-flex align-items-center justify-content-between p-3 gap-3">
                <div class="d-flex align-items-center gap-3">
                    <img width="72" src="/img/Illustration_machine.svg" alt="">
                    <div class="d-flex flex-column gap-1" style="min-width: 117px;">
                        <span style="font-size: 1.0625rem;">還杯機還杯</span>
                        <span>
                            <btn class="d-flex gap-0 align-items-center justify-content-start"  
                                onclick="location.href='/store?f=via_machine&rtn-url=/return-cup'">
                                <img width="16" src="/img/Lovefood_map.svg" alt="">
                                <span style="color: var(--orange);">查詢門市</span>
                            </btn>
                        </span>
                    </div>
                </div>
                <btn class="btn-green" onclick="location.href='/return-via-machine'">
                    <span>掃碼還杯</span>
                </btn>
            </div>
        </div>

        <div class="card border-0" style="border-radius:0.75rem;">
            <div class="card-body d-flex align-items-center justify-content-between p-3">
                <div class="d-flex align-items-center gap-3">
                    <img width="72" src="/img/Illustration_store.svg" alt="">
                    <div class="d-flex flex-column gap-1" style="min-width: 117px;">
                        <span style="font-size: 1.0625rem;">{enableBUReturn ? '統一超商' : ''}櫃台還杯</span>
                        <span>
                            <btn class="d-flex gap-0 align-items-center justify-content-start"  
                                onclick="location.href='/store?f=via_pos&rtn-url=/return-cup'">
                                <img width="16" src="/img/Lovefood_map.svg" alt="">
                                <span style="color: var(--orange);">查詢門市</span>
                            </btn>
                        </span>
                    </div>
                </div>
                <btn class="btn-green" onclick="location.href='/return-via-pos'">
                    <span>掃碼還杯</span>
                </btn>
            </div>
        </div>

        {#if (enableStarbucksPOSReturn)}
        <div class="card border-0 mt-3" style="border-radius:0.75rem;">
            <div class="card-body d-flex align-items-center justify-content-between p-3">
                <div class="d-flex align-items-center gap-3">
                    <img width="72" src="/img/Illustration_store.svg" alt="">
                    <div class="d-flex flex-column gap-1" style="min-width: 117px;">
                        <span style="font-size: 1.0625rem;">星巴克櫃台還杯</span>
                        <span>
                            <btn class="d-flex gap-0 align-items-center justify-content-start"  
                                onclick="location.href='/store?f=via_sbk&rtn-url=/return-cup'">
                                <img width="16" src="/img/Lovefood_map.svg" alt="">
                                <span style="color: var(--orange);">查詢門市</span>
                            </btn>
                        </span>
                    </div>
                </div>
                <btn class="btn-green" onclick="location.href='/return-via-sbk-pos'">
                    <span>掃碼還杯</span>
                </btn>
            </div>
        </div>
        {/if}

        {#if (enableBUReturn)}
        <div class="card border-0 mt-3" style="border-radius:0.75rem;">
            <div class="card-body d-flex align-items-center justify-content-between p-3">
                <div class="d-flex align-items-center gap-3">
                    <img width="72" src="/img/Illustration_store.svg" alt="">
                    <div class="d-flex flex-column gap-1" style="min-width: 117px;">
                        <span style="font-size: 1.0625rem;">跨品牌通路還杯</span>
                        <span>
                            <btn class="d-flex gap-0 align-items-center justify-content-start"  
                                onclick="location.href='/store?f=via_pincode&rtn-url=/return-cup'">
                                <img width="16" src="/img/Lovefood_map.svg" alt="">
                                <span style="color: var(--orange);">查詢門市</span>
                            </btn>
                        </span>
                    </div>
                </div>
                <btn class="btn-green" onclick="location.href='/return-via-scanner'">
                    <span>掃碼還杯</span>
                </btn>
            </div>
        </div>
        {/if}
    </div>
    
    {#if ($appSetting.storeReturnMode == 'B' && needReturnCount > 0)}
    <div class="px-3" style="padding-bottom:0.75rem;">
        <h6 class="font-weight-bolder">未歸還紀錄：共 <span style="color: red;">{needReturnCount}</span> 杯</h6>
    </div>
    {/if}
</div>
{/if}