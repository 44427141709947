<script>
  export let warning = { message : [], small: [] }
</script>

<div>
  <div class="system-busy">
      <span class="system-busy-p pt-5">
        {#each warning.message as message(message)}
          {message} <br>
        {/each} 
      </span>
      <div class="date-time">
          {#each warning.small as message(message)}
            <div>{message} </div>
          {/each} 
      </div>
  </div>
</div>