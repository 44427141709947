<script>
    import { layoutOption } from '../store/inapp-store' 
</script>

{#if ($layoutOption.showBanner == true)}
<div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
        <div class="carousel-item active">
        <img class="d-block w-100" src="https://fakeimg.pl/800x400?auto=yes&bg=777&fg=555&text=Fake Banner" alt="First slide">
        </div>
    </div>
</div>
{/if}